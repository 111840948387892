import { SelectInput } from 'core/components';
import {
  OptionType,
  SelectInputProps,
} from 'core/components/SelectInput/SelectInput';
import { GetThinPracticesQuery } from 'generated/legacy/graphql';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import api from 'state/api';

type ThinPractice = GetThinPracticesQuery['practices'][0];
type PracticeSelectProps = SelectInputProps & {
  setSelectedPracticeId: (id?: number) => void;
};

export const PracticeSelect = ({
  setSelectedPracticeId,
  placeholder,
  name,
  defaultSelection,
}: PracticeSelectProps) => {
  const { useLazyGetFilteredPracticesQuery } = api;
  const [getPractices] = useLazyGetFilteredPracticesQuery();

  const [displayedPractices, setDisplayedPractices] = useState<ThinPractice[]>(
    []
  );
  const [practices, setPractices] = useState<ThinPractice[]>([]);
  const [searchInputText, setSearchInputText] = useState<string>('');
  const [selection, setSelection] = useState<OptionType | null>(
    defaultSelection || null
  );
  const [showResults, setShowResults] = useState<boolean>(false);
  const setSearchTextDebounced = useCallback(
    debounce(async (name) => {
      const doSearch = name.length >= 3;
      if (doSearch) {
        setPractices(await getPractices({ name }).unwrap());
      } else {
        setDisplayedPractices([]);
      }
      setShowResults(doSearch);
    }, 300),
    []
  );

  useEffect(() => {
    setDisplayedPractices(showResults ? practices : []);
  }, [practices, showResults]);

  const searchComponents = useMemo(() => {
    return !displayedPractices.length
      ? {
          NoOptionsMessage: () => null,
          Menu: () => null,
        }
      : {};
  }, [displayedPractices]);

  return (
    <SelectInput
      isClearable
      escapeClearsValue
      placeholder={placeholder}
      components={searchComponents}
      name={name}
      testId="practice-search-input"
      onInputChange={(e: string) => {
        setSearchInputText(e);
        setSearchTextDebounced(e);
      }}
      inputValue={searchInputText}
      onChange={(value) => {
        const option = value as OptionType;
        if (option && option.value) {
          setSelectedPracticeId(parseInt(option.value));
          setSelection(option);
        }
        if (!option) {
          setSelectedPracticeId(undefined);
          setSelection(null);
        }
      }}
      value={selection}
      options={displayedPractices.map((practice: ThinPractice) => ({
        value: practice.id,
        label: practice.name,
      }))}
    />
  );
};
