import { DeleteOutline } from '@material-ui/icons';
import { TextInput } from 'core/components';
import styled from 'styled-components/macro';

export const TotalContainer = styled.div`
  width: 100%;
  display: flex;
  min-height: 100px;
  padding: 24px;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.black20};
  @media ${({ theme }) => theme.mediaQueries.mobile} {
    gap: 0.25rem;
  }
`;

export const DiscountDescription = styled.div`
  color: ${({ theme }) => theme.colors.black70};
  max-width: 70%;
`;

export const DiscountAmount = styled.div`
  color: ${({ theme }) => theme.colors.blue50};
`;

export const PriceInfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Total = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-right: 15px;
`;

export const TotalPrice = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const TaxInfo = styled.div`
  color: ${({ theme }) => theme.colors.black60};
  display: flex;
  flex-direction: row;
`;

export const LineItemContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const ItemContainer = styled.div`
  margin-right: 15px;
`;

export const BulletedLineItem = styled.li`
  list-style-type: none;
  padding-left: 0px; /* Adjust as needed */
  position: relative;
  margin-right: 0.5rem;

  &:before {
    content: '\\2022'; /* Bullet point */
    color: #000; /* Bullet color */
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1rem;
    position: absolute;
    left: 7px;
  }
`;

export const PopoverMessage = styled.div`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.black90};
  padding: 0.5rem;
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

export const ZoomDisclaimer = styled.div`
  width: 100%;
  margin-top: 4px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.black70};
`;

export const StyledDeleteOutline = styled(DeleteOutline)`
  cursor: pointer;
  color: red;
  margin-bottom: 0.3rem;
  padding-bottom: 0.2rem;
`;

export const QuantityControls = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  float: right;
  text-align: right;
`;

export const StyledTextInput = styled(TextInput)`
  width: 4.5rem;
  margin-bottom: 0.3rem;
  & input {
    height: 1.5rem;
  }
`;

export const NameAndQuantityContainer = styled.div`
  padding-right: 1rem;
  width: 100%;
`;

export const PriceContainer = styled.div`
  width: 5rem;
  text-align: right;
`;
