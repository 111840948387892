import React, { useContext, createContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUserInfo } from 'pages/Patient/patientSlice';
import { useImmer } from 'use-immer';
import * as Sentry from '@sentry/react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { datadogRum } from '@datadog/browser-rum';
import {
  getAuthGroups,
  getImpersonatorId,
  isAuthenticated,
  removeAuthGroups,
  removeTokens,
  setAuthGroups,
  setLDKey,
  setTokens,
} from 'utils/auth';
import * as propTypes from 'utils/propTypes';
import {
  useMeLazyQuery,
  MeQuery,
  DoctorSpecialty,
} from 'generated/legacy/graphql';
import {
  GetCurrentPracticeLoyaltyTierDocument,
  GetCurrentPracticeLoyaltyTierQuery,
  GetCurrentPracticeLoyaltyTierQueryVariables,
  LoyaltyProgramTier,
  GetPracticeLoyaltyQuotesQuery,
  GetPracticeLoyaltyQuotesQueryVariables,
  GetPracticeLoyaltyQuotesDocument,
  GetProductVariantsQuery,
} from 'generated/core/graphql';
import { useGQLQuery } from 'hooks/useGQL';
import api from 'state/api';

type AuthState = {
  authGroups: string[] | null;
  impersonatorId: string | null;
  isLoggedIn: boolean;
  userInfo: MeQuery['me'];
  isProDoctor: boolean;
  currentPracticeLoyaltyTier: LoyaltyProgramTier | null;
  loyaltyQuotesResult:
    | GetPracticeLoyaltyQuotesQuery['getPracticeLoyaltyQuotes']
    | null;
};

type ContextProps = {
  checkHasAccess: (accessGroups: string[]) => boolean;
  handleLogIn: (
    accessToken: string,
    refreshToken: string,
    authGroups: string[],
    email: string
  ) => void;
  handleLogOut: () => void;
  products: GetProductVariantsQuery['productVariants'] | undefined;
} & AuthState;

/* DEPRECATED: use useAuthContext instead */
export const AuthContext = createContext({} as ContextProps);
export const AuthConsumer = AuthContext.Consumer;
export const useAuthContext = () => useContext(AuthContext);

const AuthProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const [state, setState] = useImmer<AuthState>({
    authGroups: getAuthGroups(),
    impersonatorId: getImpersonatorId(),
    isLoggedIn: isAuthenticated(),
    userInfo: null,
    isProDoctor: false,
    currentPracticeLoyaltyTier: null,
    loyaltyQuotesResult: null,
  });
  const [getLoyaltyProgramTier, { data: getLoyaltyTierResult }] = useGQLQuery<
    GetCurrentPracticeLoyaltyTierQuery,
    GetCurrentPracticeLoyaltyTierQueryVariables
  >(GetCurrentPracticeLoyaltyTierDocument);
  const [getLoyaltyQuotes, { data: getLoyaltyQuotesResult }] = useGQLQuery<
    GetPracticeLoyaltyQuotesQuery,
    GetPracticeLoyaltyQuotesQueryVariables
  >(GetPracticeLoyaltyQuotesDocument);
  const [getMeData, { data: meData }] = useMeLazyQuery();

  const [getProductVariants, { data: productVariantsData }] =
    api.useLazyGetProductVariantsQuery({});
  const ldClient = useLDClient();

  const setTrackingUser = (meData: MeQuery | undefined) => {
    datadogRum.setUser({
      id: meData?.me?.id,
      email: meData?.me?.email || '',
      doctorName: meData?.me?.doctor?.fullName || '',
    });
  };

  useEffect(() => {
    if (state.isLoggedIn) {
      getMeData();
      getProductVariants({});
    }
  }, [state.isLoggedIn]);

  useEffect(() => {
    const practiceId = state.userInfo?.doctor?.practices[0]?.id;
    if (state.isLoggedIn && practiceId) {
      getLoyaltyProgramTier({ practiceId: practiceId });
      getLoyaltyQuotes({ practiceId: practiceId });
    }
  }, [state.isLoggedIn, state.userInfo?.doctor?.practices[0]?.id]);

  useEffect(() => {
    if (getLoyaltyTierResult) {
      setState((draft) => {
        draft.currentPracticeLoyaltyTier =
          (getLoyaltyTierResult.getCurrentPracticeLoyaltyProgram
            ?.loyaltyProgramTier as LoyaltyProgramTier) || null;
        draft.loyaltyQuotesResult =
          getLoyaltyQuotesResult?.getPracticeLoyaltyQuotes;
      });
    }
  }, [getLoyaltyTierResult, getLoyaltyQuotesResult]);

  useEffect(() => {
    if (meData) {
      dispatch(setUserInfo({ me: meData.me }));
      const email = meData.me?.email || undefined;
      setState((draft) => {
        draft.userInfo = meData.me;
      });
      Sentry.configureScope((scope) => scope.setUser({ email }));
      setTrackingUser(meData);
      if (email) {
        setLDKey(email);
        if (ldClient && ldClient.getContext().key !== email) {
          ldClient.identify({ key: email });
        }
        if (meData.me?.id) {
          //Appcues
          window.Appcues.identify(meData.me?.id!, {
            email: email,
          });

          //Fullstory
          window.FS.identify(meData.me?.id, {
            email: email,
          });
        }
      }
    }
  }, [meData, ldClient]);

  const handleLogIn = (
    accessToken: string,
    refreshToken: string,
    authGroups: string[],
    email: string
  ) => {
    setTokens(accessToken, refreshToken, email);
    setAuthGroups(authGroups);
    setState((draft) => {
      draft.authGroups = authGroups;
      draft.impersonatorId = getImpersonatorId();
      draft.isLoggedIn = true;
    });
    Sentry.configureScope((scope) => scope.setUser({ email }));
    setTrackingUser(meData);

    if (ldClient && ldClient.getContext().key !== email) {
      ldClient.identify({ key: email });
    }
    if (state?.userInfo?.id) {
      window.Appcues.identify(state.userInfo?.id, {
        email: email,
      });
    }
  };

  const handleLogOut = () => {
    removeTokens();
    removeAuthGroups();
    setState((draft) => {
      draft.authGroups = null;
      draft.isLoggedIn = false;
      draft.userInfo = null;
    });
    Sentry.configureScope((scope) => scope.setUser({}));
    setTrackingUser(meData);
    //Stop tracking user for full story
    window.FS.anonymize();
  };

  const checkHasAccess = (accessGroups: string[]) => {
    if (!accessGroups?.length) {
      return false;
    }

    return accessGroups.some((group) => state.authGroups?.includes(group));
  };

  return (
    <AuthContext.Provider
      value={{
        authGroups: state.authGroups,
        checkHasAccess,
        handleLogIn,
        handleLogOut,
        impersonatorId: state.impersonatorId,
        isLoggedIn: state.isLoggedIn,
        userInfo: state.userInfo,
        isProDoctor:
          !!state.userInfo?.doctor?.practices?.length &&
          state.userInfo?.doctor?.specialty === DoctorSpecialty.Dentist,
        currentPracticeLoyaltyTier: state.currentPracticeLoyaltyTier,
        loyaltyQuotesResult: state.loyaltyQuotesResult,
        products: productVariantsData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: propTypes.children.isRequired,
};

export default AuthProvider;
