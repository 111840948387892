import React, { useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, FormikValues } from 'formik';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { captureException } from '@sentry/react';

import { DentalNotationOptions } from 'generated/legacy/graphql';
import { getBrandFromDomain, getBrandSettings } from 'utils/brands';
import { Template, TemplateType } from 'generated/core/graphql';
import { NotificationContext, type } from 'core/components';
import { AuthContext } from 'components/AuthProvider';
import { CUST_CREATOR_ERROR_MESSAGES } from 'constants/index';
import { RESERVED_QUESTION_KEYS } from 'constants/treatmentObjective';
import { PageWrapper, LeftArrow, BackButton } from 'pages/Patient/styles.css';
import {
  selectActiveCase,
  selectPatient,
  fetchTreatmentGoalQuestions,
  selectPatientDoctorPreferences,
  selectCanEditSubmission,
} from 'pages/Patient/patientSlice';
import TreatmentObjectives from 'components/TreatmentObjectives/TreatmentObjectives';
import { RouteParams } from 'pages/Patient/CaseCreator/types';
import Footer, { Steps } from 'pages/Patient/Footer';
import api from 'state/api';
import {
  getTotalAndAnsweredQuestionsCount,
  getQuestionsAndAnswersFromForm,
} from 'utils/treatmentObjective';
import useTreatmentObjective from 'hooks/useTreatmentObjective';
import { CaseTypeNames } from 'constants/Case';

const TreatmentObjectivesPage = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch<RouteParams>();
  const { push } = useHistory();
  const { showNotification } = useContext(NotificationContext);
  const { userInfo } = useContext(AuthContext);
  const patient = useSelector(selectPatient);

  //Queries
  const activeCase = useSelector(selectActiveCase);
  const doctorPreferences = useSelector(selectPatientDoctorPreferences);
  const canEditSubmission = useSelector(selectCanEditSubmission);
  const brandId = getBrandSettings(getBrandFromDomain()).brandId;
  const isRetainerCase = activeCase?.caseType.name === CaseTypeNames.RETAINER;

  const {
    initialValues,
    latestTreatmentObjectiveWithQuestions,
    systemTemplates,
    caseTemplates,
    selectedCaseTemplate,
    setSelectedCaseTemplate,
  } = useTreatmentObjective({
    userId: userInfo?.id,
    caseType: activeCase?.caseType.name,
    caseRef: activeCase?.caseRef,
    brandId: brandId,
    userAccountPreferencesTemplateId:
      userInfo?.accountPreferences?.doctor
        ?.alignerClinicalPreferencesTemplateId, // Pass the user's clinical preferences template id to force using it in the form if submitting again
  });

  const [
    getUserClinicialPreferencesTemplate,
    { data: clinicalPreferencesTemplate },
  ] = api.useLazyGetTemplateQuery();

  const [submitTreatmentObjectiveMutation, { isLoading: isSubmitting }] =
    api.useSubmitTreatmentObjectiveMutation();

  useEffect(() => {
    if (!userInfo) {
      return;
    }
    const userClinicalTemplateId =
      userInfo.accountPreferences?.doctor?.alignerClinicalPreferencesTemplateId;
    const clinicalPreferencesTemplatePayload = userClinicalTemplateId
      ? {
          templateId: userClinicalTemplateId,
        }
      : {
          userId: Number(userInfo.id),
          brandId: getBrandSettings(getBrandFromDomain()).brandId,
          templateType: TemplateType.AccountPreferences,
        };

    getUserClinicialPreferencesTemplate(clinicalPreferencesTemplatePayload);
  }, [userInfo]);

  useEffect(() => {
    if (!latestTreatmentObjectiveWithQuestions || !caseTemplates) {
      return;
    }

    const submittedIds =
      latestTreatmentObjectiveWithQuestions.data.templateSections.map(
        (ts) => ts.templateId
      );

    const previouslySelectedCaseTemplate =
      caseTemplates.find((t) => submittedIds.includes(t.id)) ||
      systemTemplates?.find((t) => submittedIds.includes(t.id));

    if (previouslySelectedCaseTemplate) {
      setSelectedCaseTemplate(previouslySelectedCaseTemplate as Template);
    }
  }, [latestTreatmentObjectiveWithQuestions, caseTemplates]);

  const stringsToNumbers = (values: string[]): number[] => {
    return values?.map((value) => Number(value)) || [];
  };

  const onSubmit = async (values?: FormikValues): Promise<boolean> => {
    if (
      !userInfo ||
      !values ||
      !activeCase?.caseRef ||
      !patient?.id ||
      !systemTemplates?.length
    ) {
      return false;
    }
    try {
      const { total, current } = getTotalAndAnsweredQuestionsCount({
        values,
        systemTemplate: selectedCaseTemplate,
        includeChiefComplaint: !isRetainerCase,
      });

      const isDraft = total !== current;

      const templateSections = [
        getQuestionsAndAnswersFromForm({
          template: selectedCaseTemplate as Template,
          answers: values,
        }),
        getQuestionsAndAnswersFromForm({
          template: clinicalPreferencesTemplate as Template,
          answers: JSON.parse(
            clinicalPreferencesTemplate?.userAnswers?.root ?? '{}'
          ),
        }),
      ];

      const payload = {
        caseRef: activeCase.caseRef,
        patientId: Number(patient.id),
        isDraft,
        data: {
          ...(!isRetainerCase && {
            chiefComplaint: values[RESERVED_QUESTION_KEYS.CHIEF_COMPLAINT],
            toothCharts: {
              cannotMove: stringsToNumbers(
                values[RESERVED_QUESTION_KEYS.CANNOT_MOVE]
              ),
              eruptionCompensation: stringsToNumbers(
                values[RESERVED_QUESTION_KEYS.ERUPTION_COMPENSATION]
              ),
              willExtract: stringsToNumbers(
                values[RESERVED_QUESTION_KEYS.WILL_EXTRACT]
              ),
            },
          }),
          templateSections: templateSections,
        },
      };

      await submitTreatmentObjectiveMutation({
        input: payload,
      }).unwrap();

      showNotification(`Treatment goals saved`, 'success');

      await dispatch(
        fetchTreatmentGoalQuestions({
          caseRef: activeCase.caseRef,
          fetchBetaForms: false,
          formsToFetch: [],
        })
      );
      return true;
    } catch (err) {
      showNotification(
        CUST_CREATOR_ERROR_MESSAGES.treatment_goals_submission,
        'error'
      );

      captureException(err);
      return false;
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values }) => {
        const { total, current } = getTotalAndAnsweredQuestionsCount({
          values,
          systemTemplate: selectedCaseTemplate as Template,
          includeChiefComplaint: !isRetainerCase,
        });

        return (
          <PageWrapper isNarrow>
            <BackButton
              onClick={() => {
                push(`${match.url.split('/').slice(0, -1).join('/')}`);
              }}
            >
              <LeftArrow /> Back to all case tasks
            </BackButton>
            <type.H4>Treatment goals</type.H4>
            <TreatmentObjectives
              systemTemplates={systemTemplates as Template[]}
              caseTemplates={caseTemplates as Template[]}
              selectedCaseTemplate={selectedCaseTemplate}
              setSelectedCaseTemplate={setSelectedCaseTemplate}
              showDescription={false}
              dentalNotation={
                doctorPreferences?.dentalNotation as DentalNotationOptions
              }
              canEditSubmission={canEditSubmission}
              viewOnly={false}
            />
            <Footer
              currentStep={Steps.TreatmentGoals}
              progressBarProps={{ total, current }}
              rightMessage={
                total > 0 && current === total
                  ? '🎉  You’ve completed all the tasks for this case!'
                  : ''
              }
              onSubmit={() => {
                return onSubmit(values);
              }}
              disabled={false}
              isCtaLoading={isSubmitting}
            />
          </PageWrapper>
        );
      }}
    </Formik>
  );
};

export default TreatmentObjectivesPage;
