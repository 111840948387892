import React, { useCallback, useEffect, useMemo, useState } from 'react';

import useStateSyncedWithUrlQuery from 'hooks/useStateSyncedWithUrlQuery';
import useURLSearchParams from 'hooks/useURLSearchParams';

import api from 'state/api';
import {
  GetAllOrdersQuery,
  GetAllOrdersQueryVariables,
  OrderStatus,
} from 'generated/core/graphql';
import { Loading } from 'core/components';
import { debounce } from 'lodash';
import {
  IconContainer,
  TitleAndIconContainer,
  Title,
  FilterListIcon,
} from 'pages/OrdersAdmin/OrdersAdmin.css';
import FilterDrawer from 'pages/OrdersAdmin/FilterDrawer';
import OrderTable from 'pages/OrdersAdmin/OrderTable';

export type OrderListType = (GetAllOrdersQuery['orders']['edges'][0]['node'] & {
  practiceName?: string;
})[];

const { useLazyGetAllOrdersQuery, useLazyGetPracticeQuery } = api;

const stringToString = (value: string): string => value;
const stringToOrderStatus = (value: string) => value as OrderStatus;

const OrdersAdmin = () => {
  const [orders, setOrders] = useState<OrderListType>([]);
  const [
    getOrders,
    {
      data: orderData,
      currentData: currentOrderData,
      isLoading,
      isFetching,
      isError: getOrdersError,
    },
  ] = useLazyGetAllOrdersQuery();
  const [getPractice] = useLazyGetPracticeQuery();
  const [practiceIdToNameMap, setPracticeIdToNameMap] = useState<{
    [key: number]: string;
  }>({});
  const queryParams = useURLSearchParams();
  const [filterDrawerOpen, setFilterDrawerOpen] = useState<boolean>(false);
  const [patientIdFilter, setPatientIdFilter] =
    useStateSyncedWithUrlQuery<string>(
      queryParams,
      'patientId',
      '',
      stringToString
    );
  const [caseRefFilter, setCaseRefFilter] = useStateSyncedWithUrlQuery<string>(
    queryParams,
    'caseRef',
    '',
    stringToString
  );
  const [orderRefFilter, setOrderRefFilter] =
    useStateSyncedWithUrlQuery<string>(
      queryParams,
      'orderId',
      '',
      stringToString
    );
  const [practiceIdFilter, setPracticeIdFilter] =
    useStateSyncedWithUrlQuery<string>(
      queryParams,
      'practiceId',
      '',
      stringToString
    );
  const [statusFilter, setStatusFilter] = useStateSyncedWithUrlQuery<
    OrderStatus | undefined
  >(queryParams, 'status', undefined, stringToOrderStatus);

  const checkForPracticeName = useCallback(
    async (practiceId: number) => {
      if (!practiceIdToNameMap[practiceId]) {
        const practice = await getPractice({
          id: practiceId.toString(),
        }).unwrap();
        setPracticeIdToNameMap((prev) => ({
          ...prev,
          [practiceId]: practice.name,
        }));
      }
    },
    [practiceIdToNameMap, setPracticeIdToNameMap, getPractice]
  );

  const firstPageArgs: GetAllOrdersQueryVariables = useMemo(() => {
    return {
      first: 30,
      patientId: Number(patientIdFilter) || undefined,
      caseRef: caseRefFilter || undefined,
      orderId: orderRefFilter || undefined,
      practiceId: Number(practiceIdFilter) || undefined,
      status: statusFilter || undefined,
    };
  }, [
    patientIdFilter,
    caseRefFilter,
    orderRefFilter,
    practiceIdFilter,
    statusFilter,
  ]);

  const fetchFirstPageOrders = useCallback(() => {
    setOrders([]);
    getOrders(firstPageArgs);
  }, [firstPageArgs]);

  const debouncedFetchFirstPageOrders = debounce(() => {
    fetchFirstPageOrders();
  }, 500);

  useEffect(() => {
    if (orderData) {
      debouncedFetchFirstPageOrders();
    } else {
      fetchFirstPageOrders();
    }
    return debouncedFetchFirstPageOrders.cancel;
  }, [firstPageArgs]);

  const nextPageArgs: GetAllOrdersQueryVariables = useMemo(() => {
    return {
      first: 30,
      after: currentOrderData?.pageInfo.endCursor,
      patientId: Number(patientIdFilter) || undefined,
      caseRef: caseRefFilter || undefined,
      orderId: orderRefFilter || undefined,
      practiceId: Number(practiceIdFilter) || undefined,
      status: statusFilter || undefined,
    };
  }, [
    currentOrderData,
    patientIdFilter,
    caseRefFilter,
    orderRefFilter,
    practiceIdFilter,
    statusFilter,
  ]);

  const dedupeOrders = (
    existingOrders: OrderListType,
    newOrders: GetAllOrdersQuery['orders']['edges']
  ) => {
    // RTK query cache is invalidated when the order is updated,
    // which triggers a refetch of the orders, potentially causing duplicates
    // this "upserts" orders into the existing orders array, preventing duplicates.
    newOrders.forEach((edge) => {
      const existingOrderIndex = existingOrders.findIndex(
        (order) => order.id === edge.node.id
      );
      if (existingOrderIndex === -1) {
        existingOrders.push({
          ...edge.node,
          practiceName: practiceIdToNameMap[edge.node.practiceId],
        });
      } else {
        existingOrders[existingOrderIndex] = {
          ...edge.node,
          practiceName: practiceIdToNameMap[edge.node.practiceId],
        };
      }
    });
  };

  useEffect(() => {
    if (currentOrderData) {
      const newOrders = [...orders];
      dedupeOrders(newOrders, currentOrderData.edges);
      setOrders(newOrders);
      currentOrderData.edges.forEach((edge) => {
        checkForPracticeName(edge.node.practiceId);
      });
    }
  }, [currentOrderData, isLoading]);

  return (
    <>
      <TitleAndIconContainer>
        <Title>Orders</Title>
        <IconContainer>
          {isFetching && <Loading />}
          <FilterListIcon
            onClick={() => {
              setFilterDrawerOpen(true);
            }}
          />
        </IconContainer>
        <FilterDrawer
          practiceIdFilter={practiceIdFilter}
          filterDrawerOpen={filterDrawerOpen}
          setFilterDrawerOpen={setFilterDrawerOpen}
          patientIdFilter={patientIdFilter}
          setPatientIdFilter={setPatientIdFilter}
          caseRefFilter={caseRefFilter}
          setCaseRefFilter={setCaseRefFilter}
          orderRefFilter={orderRefFilter}
          setOrderRefFilter={setOrderRefFilter}
          setPracticeIdFilter={setPracticeIdFilter}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
        />
      </TitleAndIconContainer>
      <OrderTable
        isLoading={isLoading}
        orders={orders}
        setOrders={setOrders}
        nextPageArgs={nextPageArgs}
        practiceIdToNameMap={practiceIdToNameMap}
        getOrdersError={getOrdersError}
        currentOrderData={currentOrderData}
        getOrders={getOrders}
      />
    </>
  );
};

export default OrdersAdmin;
