import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  GenericAddressType,
  GetConsolidatedOrderItemsDocument,
  GetConsolidatedOrderItemsQuery,
  GetConsolidatedOrderItemsQueryVariables,
  OrderItemType,
} from 'generated/legacy/graphql';
import { useGQLMutation, useGQLQuery } from 'hooks/useGQL';
import { client as gqlClient } from 'gql/GraphQLProvider';
import {
  Address,
  AddShipmentDocument,
  AddShipmentMutation,
  AddShipmentMutationVariables,
  AddShipmentWithTrackingDocument,
  AddShipmentWithTrackingMutation,
  AddShipmentWithTrackingMutationVariables,
  CaseTypeFieldsFragment,
  Device,
  ExternalShippingServices,
  GenerateLabelDocument,
  GenerateLabelMutation,
  GenerateLabelMutationVariables,
  GetKrakenOrdersDocument,
  GetKrakenOrdersQuery,
  GetKrakenOrdersQueryVariables,
  GetShipmentByTrackingIdDocument,
  GetShipmentByTrackingIdQuery,
  GetShipmentByTrackingIdQueryVariables,
  GetShipmentForOrderItemDocument,
  GetShipmentForOrderItemQuery,
  GetShipmentForOrderItemQueryVariables,
  ListShippingLabelPrintersDocument,
  ListShippingLabelPrintersQuery,
  ListShippingLabelPrintersQueryVariables,
  PrintShippingLabelDocument,
  PrintShippingLabelMutation,
  PrintShippingLabelMutationVariables,
  RemoveItemFromOutboundShipmentDocument,
  RemoveItemFromOutboundShipmentMutation,
  RemoveItemFromOutboundShipmentMutationVariables,
  ServiceLevel,
  Shipment,
  SupportedCarriers,
} from 'generated/core/graphql';
import * as Sentry from '@sentry/react';
import { NotificationContext } from 'core/components';
import { useCookie } from 'hooks/useCookie';
import { useTranslation } from 'react-i18next';
import { ProductTypeNames } from 'constants/orders';
import { REFACTOR_ANY } from '@Types/refactor';
import { ApolloError } from '@apollo/client';
import { isNotNil } from 'utils/typeCheck';

export type EnrichedActiveCaseForShipping = CaseTypeFieldsFragment & {
  customerOrders?: OrderItemType[];
};
export interface ExtendedOrderItemType extends OrderItemType {
  krakenState: string[];
  krakenOrderId: number[];
}

type ShippingContextType = {
  availablePrinters?: Device[];
  loadingGetPrinters: boolean;
  deviceName: string | undefined;
  handleDeviceSelection: (value: string) => string | undefined;
  setSelectedOrder: (order: EnrichedOrderType | null) => void;
  setSelectedOrderItem: (orderItem: ExtendedOrderItemType | null) => void;
  selectedOrder?: EnrichedOrderType | null;
  selectedOrderItem?: ExtendedOrderItemType | null;
  selectedRate: any;
  setSelectedRate: (rate: IRate) => void;
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  shipItem: () => void;
  addShippedItem: (trackingId: string, carrier: SupportedCarriers) => void;
  regenerateLabel: () => void;
  printLabel: () => void;
  shippingLabelUrl: string | undefined | null;
  loading: boolean;
  loadingGetKrakenOrders: boolean;
  isFormValid: boolean;
  shipmentLabelExists: boolean;
  clearState: () => void;
  triggerFetchShipmentByTrackingId: () => void;
  removeShipmentFromExportableList: (id: string) => void;
  trackingId: string;
  setTrackingId: (value: string) => void;
  loadingExportPage: boolean;
  exportableShipments: GetShipmentByTrackingIdQuery['getShipmentByTrackingId'][];
  shipmentStatus?: string;
  closeClearShipmentModal: () => void;
  isClearShipmentModalOpen: boolean;
  fetchedShipment?: GetShipmentForOrderItemQuery['getShipmentForOrderItem'];
  removeItemFromShipment: (
    containedItemId: string,
    shipmentId: string
  ) => Promise<void>;
};

export interface IRate {
  carrier: SupportedCarriers;
  serviceLevel?: ServiceLevel;
}

export type EnrichedOrderType = {
  id?: string | null;
  journeyState?: string | null;
  patientId?: number | null;
  krakenId?: string | null;
  shippingAddress?: GenericAddressType | null;
  order?: OrderItemType;
  orderItems?: OrderItemType[] | null;
  associatedCase?: CaseTypeFieldsFragment | null;
};

const fetchCustomerOrders = async (customerRef: number) => {
  const customerOrders =
    (
      await gqlClient.query<
        GetConsolidatedOrderItemsQuery,
        GetConsolidatedOrderItemsQueryVariables
      >({
        query: GetConsolidatedOrderItemsDocument,
        variables: {
          patientId: customerRef.toString(),
        },
      })
    ).data?.consolidatedOrderItems?.filter(isNotNil) || [];
  return customerOrders;
};

export const enrichShippingCase =
  () =>
  async (
    activeCaseForShipping: CaseTypeFieldsFragment
  ): Promise<EnrichedActiveCaseForShipping> => {
    const customerRef = activeCaseForShipping.patientId;
    const customerOrders = await fetchCustomerOrders(customerRef);
    return {
      ...activeCaseForShipping,
      customerOrders: customerOrders as OrderItemType[],
    };
  };

const ShippingContext = createContext<ShippingContextType>(
  {} as ShippingContextType
);
export const useShippingContext = () => useContext(ShippingContext);

const allAddressFieldsPresent = (address: Address): [boolean, string[]] => {
  const requiredKeys = [
    'country',
    'addressLines',
    'postalCode',
    'city',
    'adminRegion',
    'name',
  ];
  const failedKeys: string[] = [];
  const test = Object.entries(address).every(([key, value]) => {
    if (!value && key in requiredKeys) {
      failedKeys.push(key);
      return false;
    }
    return true;
  });

  return [test, failedKeys];
};

const fromAddress: Address = {
  addressLines: ['2695 Customhouse Ct.'],
  city: 'San Diego',
  adminRegion: 'CA',
  country: 'US',
  postalCode: '92154',
  name: 'CANDID C/O G-Global',
};

export const buildToAddress = (
  selectedOrderItem?: ExtendedOrderItemType | null,
  selectedOrder?: EnrichedOrderType | null
) => {
  /* TODO: Casting is a quickfix */
  const unsafeSelectedOrderItem = selectedOrderItem as REFACTOR_ANY;

  if (!unsafeSelectedOrderItem?.shippingAddress) {
    return null;
  }
  const name = `(${selectedOrder?.krakenId} ${selectedOrder?.patientId}) ${selectedOrderItem?.customer?.firstName} ${selectedOrderItem?.customer?.lastName}`;
  const toAddress: Address = {
    name,
    company:
      unsafeSelectedOrderItem.shippingAddress!.name ??
      unsafeSelectedOrderItem?.customer?.practice?.name,
    addressLines: [unsafeSelectedOrderItem.shippingAddress!.addressLine1],
    city: unsafeSelectedOrderItem.shippingAddress!.city,
    adminRegion: unsafeSelectedOrderItem.shippingAddress!.stateCode,
    postalCode: unsafeSelectedOrderItem.shippingAddress!.zip,
    country: unsafeSelectedOrderItem.shippingAddress!.countryCode as string,
  };
  if (unsafeSelectedOrderItem?.shippingAddress?.addressLine2) {
    toAddress.addressLines.push(
      unsafeSelectedOrderItem.shippingAddress.addressLine2
    );
  }
  return toAddress;
};

export const ShippingProvider: React.FunctionComponent = ({ children }) => {
  const [deviceName, setDeviceName, removeStoredDeviceName] =
    useCookie('mfgDevicePrinter');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [exportableShipments, setExportableShipments] = useState<
    GetShipmentByTrackingIdQuery['getShipmentByTrackingId'][]
  >([]);
  const [trackingId, setTrackingId] = useState<string>('');
  const [selectedOrder, setSelectedOrder] =
    useState<EnrichedOrderType | null>();
  const [filteredSelectedOrder, setFilteredSelectedOrder] =
    useState<EnrichedOrderType | null>();
  const [shipmentLabelExists, setShipmentLabelExists] =
    useState<boolean>(false);
  const [shipmentStatus, setShipmentStatus] = useState<string | undefined>();
  const [selectedOrderItem, setSelectedOrderItem] =
    useState<ExtendedOrderItemType | null>();
  const [selectedRate, setSelectedRate] = useState<IRate | null>();
  const [shippingLabelUrl, setShippingLabelUrl] = useState<
    string | undefined | null
  >();

  const [isClearShipmentModalOpen, setIsClearShipmentModalOpen] =
    useState(false);
  /* TODO: Casting is a quickfix */
  const unsafeSelectedOrderItem = selectedOrderItem as REFACTOR_ANY;

  const toAddress = useMemo(() => {
    if (!selectedOrderItem) {
      return null;
    }

    const address: Address = {
      name: unsafeSelectedOrderItem.shippingAddress?.name ?? '',
      addressLines: [unsafeSelectedOrderItem.shippingAddress!.addressLine1],
      city: unsafeSelectedOrderItem.shippingAddress!.city,
      adminRegion: unsafeSelectedOrderItem.shippingAddress!.stateCode,
      postalCode: unsafeSelectedOrderItem.shippingAddress!.zip,
      country: unsafeSelectedOrderItem.shippingAddress!.countryCode as string,
    };
    if (unsafeSelectedOrderItem?.shippingAddress?.addressLine2) {
      address.addressLines.push(
        unsafeSelectedOrderItem.shippingAddress.addressLine2
      );
    }
    const [isAllAddressFieldsPresent, missingAddressFields] =
      allAddressFieldsPresent(address);
    if (!isAllAddressFieldsPresent) {
      throw new Error(
        `Address fields are missing: ${missingAddressFields.join(', ')}`
      );
    }
    return address;
  }, [selectedOrderItem]);
  const [
    fetchShipmentByTrackingId,
    { loading: loadingFetchShipmentByTrackingIdQuery },
  ] = useGQLQuery<
    GetShipmentByTrackingIdQuery,
    GetShipmentByTrackingIdQueryVariables
  >(GetShipmentByTrackingIdDocument, true);
  const [
    fetchShipment,
    { loading: loadingFetchShipmentQuery, data: fetchedShipment },
  ] = useGQLQuery<
    GetShipmentForOrderItemQuery,
    GetShipmentForOrderItemQueryVariables
  >(GetShipmentForOrderItemDocument);
  const [getKrakenOrders, { loading: loadingGetKrakenOrders }] = useGQLQuery<
    GetKrakenOrdersQuery,
    GetKrakenOrdersQueryVariables
  >(GetKrakenOrdersDocument);
  const [getPrinters, { data: printerData, loading: loadingGetPrinters }] =
    useGQLQuery<
      ListShippingLabelPrintersQuery,
      ListShippingLabelPrintersQueryVariables
    >(ListShippingLabelPrintersDocument);
  const [sendToZPLPrinter] = useGQLMutation<
    PrintShippingLabelMutation,
    PrintShippingLabelMutationVariables
  >(PrintShippingLabelDocument, true);
  const [addShipment, { loading: loadingShipmentMutation }] = useGQLMutation<
    AddShipmentMutation,
    AddShipmentMutationVariables
  >(AddShipmentDocument, true);
  const [addShipmentWithTracking] = useGQLMutation<
    AddShipmentWithTrackingMutation,
    AddShipmentWithTrackingMutationVariables
  >(AddShipmentWithTrackingDocument, true);
  const [generateLabel, { loading: loadingLabelMutation }] = useGQLMutation<
    GenerateLabelMutation,
    GenerateLabelMutationVariables
  >(GenerateLabelDocument, true);
  const [removeItemFromOutboundShipment] = useGQLMutation<
    RemoveItemFromOutboundShipmentMutation,
    RemoveItemFromOutboundShipmentMutationVariables
  >(RemoveItemFromOutboundShipmentDocument, true);
  const { showNotification } = useContext(NotificationContext);
  const availablePrinters = useMemo(
    () => printerData?.listShippingLabelPrinters ?? [],
    [printerData]
  ) as Device[];

  const triggerFetchShipmentByTrackingId = async () => {
    try {
      const shipment = await fetchShipmentByTrackingId({
        trackingId,
      });
      if (shipment?.getShipmentByTrackingId) {
        setExportableShipments([
          ...exportableShipments.filter(
            (s) => s?.id !== shipment?.getShipmentByTrackingId?.id
          ),
          shipment.getShipmentByTrackingId,
        ]);
      }
      showNotification('Shipment successfully added', 'success');
      setTrackingId('');
    } catch (err) {
      if (!(err instanceof Error)) {
        throw err;
      }

      showNotification(err?.message, 'error');
    }
  };
  const removeShipmentFromExportableList = (id: Shipment['id']) => {
    setExportableShipments(
      exportableShipments.filter((shipment) => shipment?.id !== id)
    );
    showNotification('Removed shipment from exportable list', 'success');
  };

  const fetchKrakenOrderItem = async (orderItemRefs: string[]) => {
    const rsp = await getKrakenOrders({ orderItemRefs });
    return rsp?.getKrakenOrders;
  };

  const getKrakenFilteredOrderItems = async (orderItemRefs: string[]) => {
    const krakenOrders = await fetchKrakenOrderItem(orderItemRefs);
    const krakenOrderRefs =
      krakenOrders?.map((krakenOrder) => krakenOrder?.externalOrderRef) || [];
    const krakenOrderMap: {
      [key: string]: GetKrakenOrdersQuery['getKrakenOrders'];
    } = {};
    krakenOrders?.forEach((krakenOrder) => {
      if (!krakenOrder?.externalOrderRef) {
        return;
      }
      if (!(krakenOrder.externalOrderRef in krakenOrderMap)) {
        krakenOrderMap[krakenOrder?.externalOrderRef] = [];
      }
      krakenOrderMap[krakenOrder.externalOrderRef]?.push(krakenOrder);
    });
    return (
      selectedOrder?.orderItems
        ?.filter((orderItem) =>
          krakenOrderRefs.includes(orderItem?.orderItemRef)
        )
        .map((filteredItem) => ({
          ...filteredItem,
          krakenState: krakenOrderMap[filteredItem?.orderItemRef]?.map(
            (order) => order?.state
          ),
          krakenOrderId: krakenOrderMap[filteredItem?.orderItemRef]?.map(
            (order) => order?.id
          ),
        })) || []
    );
  };

  const getClearCorrectFilteredOrderItems = () => {
    const allowedOrderItemProductTypes: string[] = [
      ProductTypeNames.WHITENING_ZOOM,
      ProductTypeNames.RETAINER,
      ProductTypeNames.REPLACEMENT_TRAY,
    ];
    return (
      selectedOrder?.orderItems?.filter((orderItem) =>
        allowedOrderItemProductTypes.includes(
          orderItem?.product?.productType || ''
        )
      ) || []
    );
  };

  const filterOrderItems = async (orderItemRefs: string[]) => {
    const krakenFilteredOrderItems =
      await getKrakenFilteredOrderItems(orderItemRefs);
    const clearCorrectFilteredOrderItems = getClearCorrectFilteredOrderItems();
    const krakenOrderItemRefs = krakenFilteredOrderItems.map(
      (krakenOrderItem) => krakenOrderItem?.orderItemRef
    );
    // we want to include the ClearCorrect order items that have not been enriched with the Kraken state and Order ID
    const uniqueClearCorrectFilteredOrderItems =
      clearCorrectFilteredOrderItems.filter(
        (ccOrderItems) =>
          !krakenOrderItemRefs.includes(ccOrderItems?.orderItemRef)
      );
    setFilteredSelectedOrder({
      ...selectedOrder,
      orderItems: [
        ...krakenFilteredOrderItems,
        ...uniqueClearCorrectFilteredOrderItems,
      ],
    });
  };

  const fetchShipmentLabel = async () => {
    if (!selectedOrderItem?.orderItemRef) {
      setShippingLabelUrl(null);
      return;
    }
    const shipment = await fetchShipment({
      orderItemRef: selectedOrderItem?.orderItemRef ?? '',
    });
    const label = shipment?.getShipmentForOrderItem?.label?.publicUrl;
    if (label) {
      setShipmentLabelExists(true);
    }
    setShippingLabelUrl(label ?? null);
    setShipmentStatus(
      shipment?.getShipmentForOrderItem?.tracker?.status ?? 'No shipment yet'
    );
  };
  useEffect(() => {
    getPrinters({});
  }, []);

  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage('es');
  }, [i18n]);
  useEffect(() => {
    setSelectedRate(null);
    setShipmentLabelExists(false);
    fetchShipmentLabel();
  }, [selectedOrderItem]);

  useEffect(() => {
    setSelectedOrderItem(null);
    setSelectedRate(null);
    setShippingLabelUrl(null);
    if (selectedOrder?.orderItems) {
      const orderItemRefs: string[] = selectedOrder?.orderItems?.map(
        (orderItem) => orderItem?.orderItemRef
      );
      filterOrderItems(orderItemRefs);
    }
  }, [selectedOrder]);

  const clearState = () => {
    setSelectedOrder(null);
    setFilteredSelectedOrder(null);
    setSelectedOrderItem(null);
    setSelectedRate(null);
    setShippingLabelUrl(null);
  };

  const isFormValid: boolean = Boolean(
    selectedOrderItem &&
      selectedRate &&
      selectedRate.carrier &&
      selectedRate.serviceLevel
  );

  const handleDeviceSelection = (deviceName: string) => {
    showNotification(`Set ${deviceName} as printer.`, 'success');
    setDeviceName(deviceName);
    return deviceName;
  };
  const printLabel = async () => {
    if (!deviceName) {
      showNotification(`Please assign a printer`, 'error');
      return;
    }
    const printer = availablePrinters.find(
      (printer) => printer.name === deviceName
    );
    if (!printer) {
      showNotification(
        `Printer ${deviceName} is no longer available. Please select a new one.`,
        'error'
      );
      removeStoredDeviceName();
      return;
    }
    const result = await sendToZPLPrinter({
      deviceId: printer.id,
      labelZplUrl: shippingLabelUrl,
    });
    if (result?.printShippingLabel?.success) {
      showNotification(`Sent label to ${deviceName}`, 'success');
    } else {
      showNotification(`Sent label to ${deviceName} and not success.`, 'error');
    }
  };

  const shipItem = async () => {
    /**
     * This creates a shipment, buys the postage, and returns the shipment label url to print.
     */
    if (!selectedOrderItem) {
      throw new Error('Must have order item selected before shipping.');
    }
    const toAddress = buildToAddress(selectedOrderItem, selectedOrder);
    if (!toAddress) {
      throw new Error('Must have a destination address to ship to.');
    }
    const [isAllAddressFieldsPresent, missingAddressFields] =
      allAddressFieldsPresent(toAddress);
    if (!isAllAddressFieldsPresent) {
      throw new Error(
        `Address fields are missing: ${missingAddressFields.join(', ')}`
      );
    }

    try {
      const shipment = await addShipment({
        containedItems: [
          {
            orderItemRef: selectedOrderItem?.orderItemRef ?? '',
            quantity: unsafeSelectedOrderItem.quantity,
            productType: unsafeSelectedOrderItem?.product?.productType ?? '',
          },
        ],
        toAddress,
        fromAddress,
      });
      const shipmentId = shipment?.addShipment?.shipment?.id ?? '';

      const purchasedShipment = await generateLabel({
        shipmentId,
        carrier: selectedRate!.carrier,
        serviceLevel: selectedRate!.serviceLevel as ServiceLevel,
      });
      setShippingLabelUrl(
        purchasedShipment?.generateLabel?.shipment?.label?.publicUrl
      );
      showNotification(
        'Shipment created successfully. You can now print the shipment label',
        'success'
      );
    } catch (e) {
      if (e instanceof ApolloError) {
        Sentry.captureException(e);
        showNotification(`Couldn't create shipment: ${e}`, 'error');
        if (e?.message?.includes('already part of a  shipment')) {
          setIsClearShipmentModalOpen(true);
        }
      }
      return;
    }
  };

  const addShippedItem = async (
    trackingId: string,
    carrier: SupportedCarriers
  ) => {
    /**
     * This creates a shipment, buys the postage, and returns the shipment label url to print.
     */
    if (!selectedOrderItem) {
      throw new Error('Must have order item selected before shipping.');
    }
    const toAddress = buildToAddress(selectedOrderItem, selectedOrder);
    if (!toAddress) {
      throw new Error('Must have a destination address to ship to.');
    }
    const [isAllAddressFieldsPresent, missingAddressFields] =
      allAddressFieldsPresent(toAddress);
    if (!isAllAddressFieldsPresent) {
      throw new Error(
        `Address fields are missing: ${missingAddressFields.join(', ')}`
      );
    }

    try {
      await addShipmentWithTracking({
        containedItems: [
          {
            orderItemRef: selectedOrderItem?.orderItemRef ?? '',
            quantity: unsafeSelectedOrderItem.quantity,
            productType: unsafeSelectedOrderItem?.product?.productType ?? '',
          },
        ],
        toAddress,
        fromAddress,
        trackingInformation: {
          trackingId,
          carrier,
          externalService: ExternalShippingServices.Easypost,
        },
      });
      showNotification('Shipment registered successfully.', 'success');
    } catch (e) {
      if (e instanceof ApolloError) {
        Sentry.captureException(e);
        showNotification(`Couldn't create shipment: ${e}`, 'error');
        if (e?.message?.includes('already part of a  shipment')) {
          setIsClearShipmentModalOpen(true);
        }
      }
      return;
    }
  };

  const regenerateLabel = async () => {
    /**
     * This buys the postage for a new label, and returns the shipment label url to print.
     */
    if (!selectedOrderItem) {
      throw new Error('Must have order item selected before shipping.');
    }
    if (!toAddress) {
      throw new Error('Must have a destination address to ship to.');
    }

    try {
      const shipment = await fetchShipment({
        orderItemRef: selectedOrderItem?.orderItemRef ?? '',
      });
      const shipmentId = shipment?.getShipmentForOrderItem?.id ?? '';

      const purchasedShipment = await generateLabel({
        shipmentId,
        carrier: selectedRate!.carrier,
        serviceLevel: selectedRate!.serviceLevel as ServiceLevel,
      });
      setShippingLabelUrl(
        purchasedShipment?.generateLabel?.shipment?.label?.publicUrl
      );
      showNotification(
        'Shipment label regenerated successfully. You can now print the shipment label',
        'success'
      );
    } catch (e) {
      Sentry.captureException(e);
      showNotification(`Couldn't regenerate label for shipment: ${e}`, 'error');
      return;
    }
  };

  const removeItemFromShipment = async (
    containedItemId: string,
    shipmentId: string
  ) => {
    const response = await removeItemFromOutboundShipment({
      shipmentContainedItems: [{ containedItemId, shipmentId }],
    });
    if (
      response?.removeItemFromOutboundShipment?.shipments?.[0]?.id ===
      fetchedShipment?.getShipmentForOrderItem?.id
    ) {
      showNotification('Item removed from shipment', 'success');
      setIsClearShipmentModalOpen(false);
      setSelectedOrderItem(null);
    } else {
      showNotification('Failed to remove item from shipment', 'error');
    }
  };
  return (
    <ShippingContext.Provider
      value={{
        shipmentStatus,
        selectedOrder: filteredSelectedOrder,
        selectedOrderItem,
        selectedRate,
        deviceName,
        isModalOpen,
        availablePrinters,
        loadingGetPrinters,
        setIsModalOpen,
        setSelectedRate,
        setSelectedOrder,
        setSelectedOrderItem,
        printLabel,
        shipItem,
        addShippedItem,
        regenerateLabel,
        handleDeviceSelection,
        shippingLabelUrl,
        loading:
          loadingShipmentMutation ||
          loadingLabelMutation ||
          loadingFetchShipmentQuery,
        loadingGetKrakenOrders,
        isFormValid,
        shipmentLabelExists,
        clearState,
        triggerFetchShipmentByTrackingId,
        removeShipmentFromExportableList,
        trackingId,
        setTrackingId,
        loadingExportPage: loadingFetchShipmentByTrackingIdQuery,
        exportableShipments,
        closeClearShipmentModal: () => setIsClearShipmentModalOpen(false),
        isClearShipmentModalOpen,
        fetchedShipment: fetchedShipment?.getShipmentForOrderItem,
        removeItemFromShipment,
      }}
    >
      {children}
    </ShippingContext.Provider>
  );
};
