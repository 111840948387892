import React, { useState, useCallback, useEffect } from 'react';
import { debounce } from 'lodash';
import ReactSelect, { ValueType } from 'react-select';
import { useFormikContext } from 'formik';
import api from 'state/api';
import { AddressSelection } from 'components/AddressSelection/AddressSelection';
import { SelectedAddress } from 'components/AddressForm/types';
import ProductSelect from 'components/ProductSelect';
import { getAddressInputFromAddressForm } from 'components/AddressForm/utils';
import {
  AddressSelectionContainer,
  PracticeName,
  SelectedPracticeContainer,
  ProductSelectContainer,
} from 'pages/OrdersPortal/PracticeOrderForm.css';
import { FormType, SelectOption } from 'pages/OrdersPortal/types';
import { CANDID_BRAND_NAME, convertToBrand } from 'utils/brands';
import TotalBox from 'components/TotalBox/TotalBox';
import { CatalogItemType, SelectionOptionTypes } from 'types/checkout';
import DineroFactory from 'dinero.js';
import { Nullable } from 'utils/types';
import { Label } from 'styles/inputs.css';

type PracticeOrderFormProps = {
  addressFormSubmitBtnRef: React.RefObject<HTMLButtonElement>;
  handleConfirmedAddress: (confirmedAddress: SelectedAddress) => void;
  setAddressFormIsValid: (isValid: boolean) => void;
};

const PracticeOrderForm = ({
  addressFormSubmitBtnRef,
  handleConfirmedAddress,
  setAddressFormIsValid,
}: PracticeOrderFormProps) => {
  const practiceBrandMap: { [key: string]: string } = {};
  const [practiceBrand, setPracticeBrand] = useState<string | null>('');
  const { setFieldValue } = useFormikContext();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPractice, setSelectedPractice] = useState<SelectOption | null>(
    null
  );
  const [productSelectValue, setProductSelectValue] =
    useState<ValueType<SelectOption>>();
  const [cartItems, setCartItems] = useState<CatalogItemType[]>([]);
  const deleteItem = (index: number) => {
    setCartItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems.splice(index, 1);
      return updatedItems;
    });
  };
  const getItemWithUpdatedPrice = (
    item: CatalogItemType,
    newUnitPrice: number
  ) => {
    return {
      ...item,
      price: DineroFactory({ amount: newUnitPrice }),
      totalPriceBeforeDiscounts: DineroFactory({
        amount: newUnitPrice * item.quantity,
      }),
      totalPriceAfterDiscounts: DineroFactory({
        amount: newUnitPrice * item.quantity,
      }),
      totalPriceAfterLoyalty: DineroFactory({
        amount: newUnitPrice * item.quantity,
      }),
    };
  };
  const updateItemQuantity = (index: number, newQuantity: number) => {
    newQuantity >= 0 &&
      setCartItems((prevItems) => {
        const updatedItems = [...prevItems];
        const itemToUpdate = updatedItems[index];
        itemToUpdate.quantity = newQuantity;
        const updatedItem = getItemWithUpdatedPrice(
          itemToUpdate,
          itemToUpdate.price.getAmount()
        );
        updatedItems[index] = updatedItem;
        return updatedItems;
      });
  };
  const [
    getFilteredPractices,
    { data: practicesData, isFetching: isFetchingSearchResults },
  ] = api.useLazyGetFilteredPracticesQuery();

  const practiceOptions: SelectOption[] =
    practicesData?.reduce<SelectOption[]>((options, practice) => {
      practiceBrandMap[practice.id] = practice?.brand?.name ?? '';
      options.push({
        value: `${practice.id}`,
        label: `${practice.name}`,
      });

      return options;
    }, []) || [];

  const debouncedGetFilteredPractices = useCallback(
    debounce(getFilteredPractices, 500),
    []
  );

  const handlePracticeChange = (option: ValueType<SelectOption>) => {
    const selectedOption = option as SelectOption;
    setSelectedPractice(selectedOption);
    setFieldValue('practiceId', Number(selectedOption.value));
    setFieldValue('orderItems', []);
  };

  const handleProductChange = ({
    sku,
    title,
    price,
  }: {
    sku?: Nullable<string>;
    title?: string;
    price?: number;
  }) => {
    setProductSelectValue(null);
    if (
      sku &&
      title &&
      price !== undefined &&
      cartItems.every((item) => item.sku !== sku)
    ) {
      setCartItems((prevItems) => [
        ...prevItems,
        {
          sku,
          quantity: 1,
          providerFacingProductName: `${title} - ${sku}`,
          price: DineroFactory({ amount: price }),
          // The remaining fields are not used in this context yet
          title,
          description: '',
          question: '',
          imageSrc: '',
          optionType: SelectionOptionTypes.QuantityDropDown,
          totalPriceAfterDiscounts: DineroFactory({
            amount: price,
          }),
          totalPriceAfterLoyalty: DineroFactory({ amount: price }),
          totalPriceBeforeDiscounts: DineroFactory({
            amount: price,
          }),
        },
      ]);
    }
  };

  useEffect(() => {
    if (selectedPractice) {
      setPracticeBrand(practiceBrandMap[selectedPractice.value]);
    }
  }, [selectedPractice]);

  useEffect(() => {
    setFieldValue(
      'orderItems',
      cartItems.map((item) => ({
        productVariantSku: item.sku,
        quantity: item.quantity,
      }))
    );
  }, [cartItems]);

  return (
    <div>
      <h3>Practice Order</h3>
      <ReactSelect
        placeholder="Search for a practice..."
        options={practiceOptions}
        isLoading={isFetchingSearchResults}
        inputValue={searchTerm}
        onInputChange={(newValue) => {
          setSearchTerm(newValue);
          if (newValue) {
            debouncedGetFilteredPractices({ name: newValue });
          }
        }}
        onChange={handlePracticeChange} // Use the new handlePracticeChange function
      />
      {selectedPractice && (
        <>
          <SelectedPracticeContainer>
            <h4>Selected Practice:</h4>
            <PracticeName>{selectedPractice.label}</PracticeName>
          </SelectedPracticeContainer>
          <ProductSelectContainer>
            <ProductSelect
              label="Product"
              brand={convertToBrand(practiceBrand, CANDID_BRAND_NAME)}
              includeGenericProducts={true}
              formType={FormType.practice}
              controlledValue={productSelectValue}
              setControlledValue={setProductSelectValue}
              onProductChange={({ sku, title, price }) => {
                handleProductChange({
                  sku,
                  title,
                  price,
                });
              }}
              key={`ProductSelect-${selectedPractice.value}`} // reset the product select when the practice changes
            />
          </ProductSelectContainer>
          <Label>Cart</Label>
          <TotalBox
            orderItems={cartItems}
            supportQuantity={true}
            displayQuantity={true}
            setQuantity={(index, quantity) => {
              updateItemQuantity(index, quantity);
            }}
            onDeleteItem={deleteItem}
          />
          <AddressSelectionContainer>
            <AddressSelection
              practiceId={selectedPractice.value}
              setResultAddress={(address) => {
                setFieldValue(
                  'shippingAddress',
                  getAddressInputFromAddressForm(address.value)
                );
              }}
              addressFormSubmitBtnRef={addressFormSubmitBtnRef}
              handleConfirmedAddress={handleConfirmedAddress}
              setAddressFormIsValid={setAddressFormIsValid}
              key={`AddressSelection-${selectedPractice.value}`} // reset the address selection when the practice changes
            />
          </AddressSelectionContainer>
        </>
      )}
    </div>
  );
};

export default PracticeOrderForm;
