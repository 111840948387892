import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import * as Sentry from '@sentry/react';
import { FormikProps, FormikValues } from 'formik';
import { debounce } from 'lodash';
import { isEqual } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { NotificationContext, type } from 'core/components';
import { SkeletonCaseCreator } from 'pages/Patient/CaseCreator/Skeletons';
import {
  IntakeTypes,
  FormQuestion,
  QuestionTypes,
  Form,
  Maybe,
} from 'generated/core/graphql';

import {
  getListAnswerFieldName,
  getExplanationFieldName,
  getAnswerFieldName,
} from 'components/FormikForms/utils';

import { StringToBooleanMap } from 'utils/types';
import { CUST_CREATOR_ERROR_MESSAGES } from 'constants/index';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchTreatmentGoalQuestions,
  selectTreatmentGoalsInitialValues,
  uploadIntakeForms,
  selectActiveCase,
  selectPatient,
  selectedTreatmentGoalFormsFromWorkflow,
  selectTreatmentGoalForms,
  setUnsubmittedTreatmentGoalsAreValid,
} from 'pages/Patient/patientSlice';
import { PageWrapper, LeftArrow, BackButton } from 'pages/Patient/styles.css';
import { RouteParams } from 'pages/Patient/CaseCreator/types';
import { AppDispatch } from 'state/store';
import Footer, { Steps } from 'pages/Patient/Footer';
import { CaseTypeNames } from 'constants/Case';
import { AlignerTreatmentGoalContainer } from 'pages/Patient/CaseCreator/TreatmentGoals/AlignerTreatmentGoalContainer';
import { GenericTreatmentGoalContainer } from 'pages/Patient/CaseCreator/TreatmentGoals/GenericTreatmentGoalContainer';

const answerMap: StringToBooleanMap = {
  yes: true,
  no: false,
};

const TreatmentGoals = () => {
  const match = useRouteMatch<RouteParams>();
  const { push } = useHistory();
  const { showNotification } = useContext(NotificationContext);
  const [questionsAnswered, setQuestionsAnswered] = useState<number>(0);
  const {
    'case-creator-auto-save': enableAutoSave,
    'beta-treatment-goals': fetchBetaForms,
  } = useFlags();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const activeCase = useSelector(selectActiveCase);
  const patient = useSelector(selectPatient);
  const treatmentGoalFormNames = useSelector(
    selectedTreatmentGoalFormsFromWorkflow
  );
  const treatmentGoalForms = useSelector(selectTreatmentGoalForms);

  const initialValues = useSelector(selectTreatmentGoalsInitialValues);
  const dispatch = useDispatch<AppDispatch>();
  const [selectedForm, setSelectedForm] = useState<Form | null>();
  useEffect(() => {
    updateAnsweredQuestions();
  }, [selectedForm]);

  const customerId = patient?.id;
  const caseRef = activeCase?.caseRef;
  const formRef = useRef<FormikProps<FormikValues>>(null);

  const getTotalQuestions = (form: Maybe<Form> | null) => {
    if (form) {
      return form.formSchema.reduce(
        (acc, cur) => acc + cur.questions.filter((q) => q.isRequired).length,
        0
      );
    }
    return 0;
  };

  const updateAnsweredQuestions = () => {
    if (!selectedForm) {
      return 0;
    }

    if (!formRef.current?.values) {
      return 0;
    }

    //Need to worry about if the user has some answers in both forms, so loop over the questions, and check if the question key is in values
    const total = selectedForm.formSchema.reduce((sectionAcc, section) => {
      return (
        sectionAcc +
        section.questions.reduce((acc, question) => {
          if (!question.isRequired) {
            return acc;
          }
          const key = getQuestionFormKey(section.label, question);
          const isQuestionAnswered = Array.isArray(
            formRef.current?.values[key!]
          )
            ? formRef.current?.values[key!].length
            : formRef.current?.values[key!];
          return key &&
            key in (formRef.current?.values! ?? {}) &&
            isQuestionAnswered
            ? acc + 1
            : acc;
        }, 0)
      );
    }, 0);

    setQuestionsAnswered(total);

    const isValid = total === getTotalQuestions(selectedForm);
    dispatch(setUnsubmittedTreatmentGoalsAreValid(isValid));
  };

  const getQuestionFormKey = (
    sectionLabel: string,
    question: FormQuestion
  ): string | null => {
    switch (question.questionType) {
      case QuestionTypes.Boolean:
        return getAnswerFieldName(sectionLabel, question?.questionKey!);
      case QuestionTypes.Choice:
      case QuestionTypes.Date:
      case QuestionTypes.Text:
        return getExplanationFieldName(sectionLabel, question.questionKey!);
      case QuestionTypes.ToothChart:
        return getListAnswerFieldName(sectionLabel, question?.questionKey!);
    }
    return null;
  };

  const onSubmit = async (values: FormikValues): Promise<boolean> => {
    try {
      setIsSubmitting(true);
      await postIntakeForms(values);

      showNotification(`Treatment goals saved`, 'success');
      return true;
    } catch (err) {
      showNotification(
        CUST_CREATOR_ERROR_MESSAGES.treatment_goals_submission,
        'error'
      );

      Sentry.captureException(err);
      return false;
    } finally {
      setIsSubmitting(false);
    }
  };

  const onAutoSave = async (values: FormikValues) => {
    if (isEqual(initialValues, values)) {
      return;
    }
    try {
      await postIntakeForms(values);
      dispatch(
        fetchTreatmentGoalQuestions({
          caseRef: caseRef!,
          fetchBetaForms,
          refetchQuestions: false, // We don't want to unnecessarily refetch the questions
          formsToFetch: treatmentGoalFormNames ?? [],
        })
      );
    } catch (err) {
      showNotification(
        CUST_CREATOR_ERROR_MESSAGES.treatment_goals_submission,
        'error'
      );
      Sentry.captureException(err);
    }
  };

  const autosaveForm = debounce(() => {
    onAutoSave(formRef.current?.values!);
  }, 1000);

  const onValuesChanged = () => {
    updateAnsweredQuestions();
    if (enableAutoSave) {
      autosaveForm();
    }
  };

  const postIntakeForms = async (values: FormikValues) => {
    if (!selectedForm) {
      return;
    }

    let isDraft = false;

    const sectionSubmissions = selectedForm?.formSchema?.map((section) => {
      const answers = section.questions.map((intakeQuestion, index) => {
        const answerRaw =
          values[
            getAnswerFieldName(
              section.label,
              intakeQuestion.questionKey ?? index.toString()
            )
          ];
        const explanation =
          values[
            getExplanationFieldName(
              section.label,
              intakeQuestion.questionKey ?? index.toString()
            )
          ];

        const listAnswer =
          values[
            getListAnswerFieldName(
              section.label,
              intakeQuestion.questionKey ?? index.toString()
            )
          ];

        //If any are missing, form is not complete
        if (
          !explanation &&
          !answerRaw &&
          !listAnswer?.length &&
          intakeQuestion.isRequired
        ) {
          isDraft = true;
        }

        return {
          question: intakeQuestion.question,
          answer: answerRaw ? answerMap[answerRaw] : null,
          explanation: explanation || '',
          questionKey: intakeQuestion.questionKey,
          listAnswer: listAnswer,
          questionType: intakeQuestion.questionType,
        };
      });

      return {
        label: section.label,
        answers,
      };
    });

    await dispatch(
      uploadIntakeForms({
        patientId: Number(customerId),
        intakeType: selectedForm.materialType.name as IntakeTypes,
        sections: sectionSubmissions!,
        version: selectedForm?.version,
        isDraft: isDraft,
        caseRef: caseRef!,
      })
    ).unwrap();
  };

  useEffect(() => {
    return () => {
      // When the user navigates away from the page, refetch questions so they are up to date if they come back during the session
      dispatch(
        fetchTreatmentGoalQuestions({
          caseRef: caseRef!,
          fetchBetaForms,
          formsToFetch: treatmentGoalFormNames ?? [],
        })
      );
    };
  }, []);

  const onBackClick = () => {
    push(`${match.url.split('/').slice(0, -1).join('/')}`);
  };

  type ContainerMapType = {
    [name: string]: ReactNode;
  };

  const containerMapping: ContainerMapType = {
    [CaseTypeNames.ALIGNER]: (
      <AlignerTreatmentGoalContainer
        treatmentGoalForms={treatmentGoalForms}
        formRef={formRef}
        setSelectedForm={setSelectedForm}
        onValuesChanged={onValuesChanged}
        onSubmit={onSubmit}
      />
    ),
    [CaseTypeNames.REFINEMENTS]: (
      <GenericTreatmentGoalContainer
        treatmentGoalForms={treatmentGoalForms}
        formRef={formRef}
        setSelectedForm={setSelectedForm}
        onValuesChanged={onValuesChanged}
        onSubmit={onSubmit}
      />
    ),
    [CaseTypeNames.RETAINER]: (
      <GenericTreatmentGoalContainer
        treatmentGoalForms={treatmentGoalForms}
        formRef={formRef}
        onValuesChanged={onValuesChanged}
        setSelectedForm={setSelectedForm}
        onSubmit={onSubmit}
      />
    ),
  };

  const caseType = activeCase?.caseType.name ?? '';
  if (!caseType || !containerMapping[caseType]) {
    return <SkeletonCaseCreator />;
  }
  const container = containerMapping[caseType];

  const progressBarProps = {
    total: selectedForm ? getTotalQuestions(selectedForm) : 0,
    current: questionsAnswered,
  };

  return (
    <PageWrapper isNarrow>
      <BackButton onClick={onBackClick}>
        <LeftArrow /> Back to all case tasks
      </BackButton>
      <type.H4>Treatment goals</type.H4>
      {container}
      <Footer
        currentStep={Steps.TreatmentGoals}
        progressBarProps={progressBarProps}
        rightMessage={
          progressBarProps?.current === progressBarProps?.total &&
          progressBarProps?.total // if the total is zero then the user hasn't selected a TG form
            ? '🎉  You’ve completed all the tasks for this case!'
            : ''
        }
        onSubmit={() => {
          return onSubmit(formRef.current?.values!);
        }}
        disabled={false}
        isCtaLoading={isSubmitting}
      />
    </PageWrapper>
  );
};

export default TreatmentGoals;
