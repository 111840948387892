import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { NotificationContext } from 'core/components';
import { useTransitionJourneyMutation } from 'generated/legacy/graphql';
import {
  selectActiveCase as selectActiveCasePatientSlice,
  selectPatient,
  selectScansUploadType,
  updateScanUploadType,
} from 'pages/Patient/patientSlice';
import { coreClient } from 'gql/GraphQLProvider';
import {
  GenerateImpressionKitContainer,
  TextContainer,
} from 'pages/Patient/CaseCreator/DiagnosticMaterials/Scans/PvsScan.css';
import {
  Container,
  CheckboxContainer,
} from 'pages/Patient/CaseCreator/DiagnosticMaterials/Scans/Styles.css';
import GenerateSvg from 'assets/fi_file-text.svg?react';
import {
  ListContainer,
  ListItem,
} from 'pages/Patient/CaseCreator/DiagnosticMaterials/Scans/Styles.css';
import { ScanJourneyTransitions } from 'utils/journey';
import { AppDispatch } from 'state/store';
import { selectLatestScanSubmission } from 'pages/Patient/patientSlice';
import { useAuthContext } from 'components/AuthProvider';
import {
  CaseSource,
  WorkflowUploadSources,
  UpdateCaseWorkflowMutation,
  UpdateCaseWorkflowDocument,
  UpdateCaseWorkflowMutationVariables,
} from 'generated/core/graphql';

const PvsScan = ({ isReadOnly = false }: { isReadOnly?: boolean }) => {
  const practiceEmailField = 'entry.203004945';
  const practiceNameField = 'entry.1448504955';
  const practiceAddressField = 'entry.1192458806';
  const custIdField = 'entry.1647390845';
  const patientNameField = 'entry.859921773';

  const patient = useSelector(selectPatient);
  const activeCase = useSelector(selectActiveCasePatientSlice);
  const submittedScans = useSelector(selectLatestScanSubmission);
  const scanUploadSource = useSelector(selectScansUploadType);
  const [pvsSelected, setPvsSelected] = useState<boolean>(false);
  const { userInfo } = useAuthContext();

  const { scansLabRequirements } = userInfo?.brandInfo?.configs?.zendesk || {};
  const address = patient?.practice?.address;

  const addressFields = [
    address?.name,
    address?.addressLine1,
    address?.addressLine2 ?? '',
    address?.city,
    address?.stateCode,
    address?.zip,
  ];

  const practiceAddress = addressFields.filter((f) => !!f).join(' ');
  const queryString = `https://formfacade.com/public/113606064810223952308/home/form/1FAIpQLSdORLI5kj7QD8-c6IRtTT-Zzsr99-jk_5R1D4KoXukj8T5RVQ?${practiceNameField}=${patient?.practice?.name}&${practiceAddressField}=${practiceAddress}&${practiceEmailField}=${userInfo?.email}&${custIdField}=${patient?.id}&${patientNameField}=${patient?.firstName} ${patient?.lastName}`;
  const [transitionJourney] = useTransitionJourneyMutation();
  const dispatch = useDispatch<AppDispatch>();

  const { showNotification } = useContext(NotificationContext);

  //This use effect gets triggered from external source.
  //For example if the journey state was changed outside of this component
  useEffect(() => {
    const pvsSelectedInWorkflow =
      scanUploadSource === WorkflowUploadSources.Pvs;

    if (!pvsSelected && pvsSelectedInWorkflow) {
      setPvsSelected(true);
    } else if (pvsSelected && !pvsSelectedInWorkflow) {
      setPvsSelected(false);
    }
  }, [activeCase]);

  const onCheckboxClicked = async () => {
    if (isReadOnly) {
      return;
    }

    const newValue = !pvsSelected;

    //If we're coming from a rejection/needs clarification, make sure the state is kept in sync
    //Assumption here is if we have a submission, the original state is
    const transition = submittedScans
      ? ScanJourneyTransitions.REQUEST_ACTION
      : ScanJourneyTransitions.RESET;

    try {
      if (activeCase?.source === CaseSource.Core) {
        await coreClient.mutate<
          UpdateCaseWorkflowMutation,
          UpdateCaseWorkflowMutationVariables
        >({
          fetchPolicy: 'no-cache',
          mutation: UpdateCaseWorkflowDocument,
          variables: {
            caseRef: activeCase?.caseRef!,
            workflowOptions: {
              scansUploadSource: newValue
                ? WorkflowUploadSources.Pvs
                : WorkflowUploadSources.Provider,
            },
          },
        });
      } else {
        await submitJourneyTransition(
          newValue ? ScanJourneyTransitions.MAIL_PVS : transition
        );
      }

      dispatch(
        updateScanUploadType({
          caseRef: activeCase?.caseRef!,
          newState: newValue
            ? WorkflowUploadSources.Pvs
            : WorkflowUploadSources.Provider,
        })
      );
      setPvsSelected(newValue);
    } catch (err) {
      showNotification(
        'An error has occured, please refresh and try again',
        'error'
      );
      Sentry.captureException(err);
    }
  };

  const submitJourneyTransition = async (transition: string) => {
    await transitionJourney({
      variables: {
        caseRef: activeCase?.caseRef!,
        component: 'scans_aligner_leg',
        transition: transition,
      },
    });
  };

  return (
    <>
      <Container>
        <TextContainer>
          <div>Here’s how it works:</div>
          <ListContainer>
            <ListItem>
              Check each impressions to make sure they meet{' '}
              {scansLabRequirements ? (
                <a
                  href={scansLabRequirements}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  the requirements of our lab.
                </a>
              ) : (
                'the requirements of our lab.'
              )}
            </ListItem>
            <ListItem>
              Place the impressions in a shipping box with our packing slip
              below.
            </ListItem>
            <ListItem>
              Place the shipping label on the box, and mail it to us.
            </ListItem>
          </ListContainer>
        </TextContainer>
        {!isReadOnly && (
          <GenerateImpressionKitContainer href={queryString} target="_blank">
            <GenerateSvg />
            <div>Generate impression kit insert</div>
          </GenerateImpressionKitContainer>
        )}
        <CheckboxContainer onClick={onCheckboxClicked} isReadonly={isReadOnly}>
          <input
            type="checkbox"
            checked={pvsSelected}
            data-testid="diagnostic-materials-scans-pvs"
            disabled={isReadOnly}
          />
          <div>
            I have shipped or plan to ship my PVS impressions. I understand my
            case will be delayed if I don’t send the packing slip.
          </div>
        </CheckboxContainer>
      </Container>
    </>
  );
};

export default PvsScan;
