import styled from 'styled-components/macro';
import { Button, mediaQueries, type } from 'core/components';
import PencilIcon from 'assets/pencil-2.svg?react';

export const GroupContainer = styled.div`
  padding: 32px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.black30};
  box-shadow: 0px 1px 4px 0px ${({ theme }) => theme.colors.text20};
  margin-bottom: 16px;
`;

export const QuestionsContainer = styled.div`
  margin-top: 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.black30};
  padding-top: 24px;
`;

export const QuestionsImage = styled.img`
  max-width: 100%;
  margin-bottom: 16px;
`;

export const QuestionContainer = styled.div<{ isFollowupQuestion?: boolean }>`
  margin-bottom: 20px;

  ${({ theme, isFollowupQuestion }) =>
    isFollowupQuestion &&
    `
    padding-left: 32px;
    border-left: 1px solid ${theme.colors.black30};
    margin-bottom: 16px;
  `}
`;

export const QuestionLabel = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 0.2rem;
  gap: 2px;
  margin-bottom: 8px;
  font-weight: 700;

  @media ${mediaQueries.mobile} {
    font-size: ${type.size.t1};
  }
`;

export const TemplateButtonContainer = styled.div`
  margin: 18px 0;
  display: flex;
  flex-direction: row;
  gap: 8px;
  max-height: 48px;
  flex-wrap: wrap;
`;

export const TemplateButton = styled(Button)<{
  isSelected: boolean;
  disabled: boolean;
}>`
  flex: 0 0 auto;

  ${({ theme, isSelected, disabled }) => {
    const color = isSelected ? theme.colors.blue50 : theme.colors.black50;
    const fontColor = isSelected ? theme.colors.white : theme.colors.black50;
    const backgroundColor = isSelected
      ? theme.colors.blue50
      : theme.colors.white;
    return `
      border-color: ${color};
      color: ${color};
      background-color: ${backgroundColor};
      span {
        color: ${fontColor};
      }
      ${
        !disabled &&
        `
        &:hover {
          background-color: ${theme.colors.blue30};
          border-color: ${theme.colors.blue70};
          color: ${theme.colors.blue70};
          span {
            color: ${isSelected ?? theme.colors.black90};
          }
      `
      }
    `;
  }}
`;

export const ManualButton = styled(Button)<{
  isSelected: boolean;
  disabled: boolean;
}>`
  ${({ theme, isSelected, disabled }) => {
    const color = isSelected ? theme.colors.blue50 : theme.colors.black50;
    const fontColor = isSelected ? theme.colors.white : theme.colors.black50;
    const backgroundColor = isSelected
      ? theme.colors.blue50
      : theme.colors.white;
    return `
      border: 1px dashed ${theme.colors.blue50};
      border-color: ${color};
      color: ${color};
      background-color: ${backgroundColor};
      span {
        color: ${fontColor};
      }
      ${
        !disabled &&
        `
        &:hover {
          background-color: ${theme.colors.blue30};
          border-color: ${theme.colors.blue70};
          color: ${theme.colors.blue70};
          span {
            color: ${isSelected ?? theme.colors.black90};
          }
      `
      }
    `;
  }}
`;
export const EditButton = styled.div<{ disabled: boolean }>`
  cursor: pointer;
  flex: 0 0 auto; /* Ensure items don't shrink or grow */
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.black20 : theme.colors.blue50};
  font-family: Modern Era;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

export const PencilIconStyled = styled(PencilIcon)`
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
  path {
    stroke: ${({ theme }) => theme.colors.blue50};
  }
`;
