import styled from 'styled-components';
import { FilterList } from '@material-ui/icons';

export const TitleAndIconContainer = styled.div`
  width: 100%;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h2`
  margin: 0;
  padding: 0;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
`;

export const FilterListIcon = styled(FilterList)`
  float: right;
  cursor: pointer;
`;
