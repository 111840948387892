import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { Formik } from 'formik';
import { type, NotificationContext } from 'core/components';
import { PageWrapper, BackButton, LeftArrow } from 'pages/Patient/styles.css';
import BasicInfoForm, {
  FormProps,
} from 'pages/Patient/CaseCreator/BasicInfo/BasicInfoForm';
import { AuthContext } from 'components/AuthProvider';
import { convertPracticesToFormikOptions } from 'components/FormikForms/utils';

import {
  selectAreDiagnosticMaterialsReadyForSubmit,
  selectAreTreatmentGoalsReadyForSubmit,
  selectPatient,
  selectPatientBasicInfoFormValues,
  selectIsSubmitted,
} from 'pages/Patient/patientSlice';
import {
  useCreateCustomerAndCase,
  useUpdateCustomer,
} from 'pages/Patient/CaseCreator/BasicInfo/helpers';
import { RouteParams } from 'pages/Patient/CaseCreator/types';
import { getBasicInfoNextPageInfo } from 'pages/Patient/utils';
import { CaseTypeNames } from 'constants/Case';
import { convertToBrand, CANDID_BRAND_NAME } from 'utils/brands';
import useOrthoBypass from 'hooks/useOrthoBypass';

const BasicInfo = () => {
  const { userInfo } = useContext(AuthContext);
  const brand = convertToBrand(userInfo?.brandInfo?.name, CANDID_BRAND_NAME);
  const bypassOrthoReviewProcess = useOrthoBypass(brand);
  const { id } = useParams<RouteParams>();
  const match = useRouteMatch<RouteParams>();
  const { createCustomerAndCase } = useCreateCustomerAndCase();
  const { updateCustomer } = useUpdateCustomer();
  const practiceOptions = convertPracticesToFormikOptions(
    userInfo?.doctor?.practices
  );
  const { showNotification } = useContext(NotificationContext);
  const patient = useSelector(selectPatient);
  const [isCreatingCustomer, setIsCreatingCustomer] = useState(false);
  const { push } = useHistory();

  const patientBasicInfoFormValues = useSelector(
    selectPatientBasicInfoFormValues
  );
  const areDiagnosticMaterialsComplete = useSelector(
    selectAreDiagnosticMaterialsReadyForSubmit
  );
  const areTreatmentGoalsComplete = useSelector(
    selectAreTreatmentGoalsReadyForSubmit
  );

  const submitButtonRef = React.createRef<HTMLButtonElement>();
  const isSubmitted = useSelector(selectIsSubmitted);
  const isNewPatient = !id;
  const isExistingPatient = !!id && !isNewPatient;

  const handleSubmit = async (values: FormProps) => {
    const referringDentistId = userInfo?.doctor?.id;
    const requireOrthoReview = !bypassOrthoReviewProcess;

    const {
      firstName,
      lastName,
      email,
      dateOfBirth,
      preferredName,
      phone,
      middleName,
      shippingAddress,
      practiceId,
      legalGuardian,
    } = values;

    try {
      setIsCreatingCustomer(true);
      if (!referringDentistId) {
        throw new Error('Only doctors can create patients');
      }

      if (isNewPatient) {
        const { customerId } = await createCustomerAndCase({
          email,
          firstName,
          lastName,
          preferredName,
          dateOfBirth,
          referringDentistId,
          practiceId,
          phone,
          middleName,
          shippingAddress,
          legalGuardian,
          requireOrthoReview,
          caseType: CaseTypeNames.ALIGNER,
        });
        showNotification(`${firstName} ${lastName} added`, 'success');
        const nextPageInfo = getBasicInfoNextPageInfo(
          customerId,
          areDiagnosticMaterialsComplete,
          areTreatmentGoalsComplete,
          true
        );
        push(nextPageInfo.nextPage || '');
      } else {
        const customerId = patient?.id;
        const addressToUpdateId = patient?.addresses!.filter(
          (a) => a.addressType === 'Shipping Address'
        )[0].id;
        await updateCustomer({
          customerId,
          addressToUpdateId,
          email,
          firstName,
          lastName,
          middleName,
          preferredName,
          dateOfBirth,
          phone,
          shippingAddress,
          legalGuardian,
        });
        showNotification(`${firstName} ${lastName} updated`, 'success');
        const nextPageInfo = getBasicInfoNextPageInfo(
          customerId,
          areDiagnosticMaterialsComplete,
          areTreatmentGoalsComplete,
          true
        );
        push(nextPageInfo.nextPage || '');
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        throw err;
      }

      showNotification(err.message, 'error');
    } finally {
      setIsCreatingCustomer(false);
    }
  };

  const validateSubmittedAddress = async (_: FormProps) => {
    if (submitButtonRef.current) {
      submitButtonRef.current.click();
    }
  };

  const onBackClick = () => {
    push(`${match.url.split('/').slice(0, -1).join('/')}`);
  };

  return (
    <PageWrapper isNarrow>
      <BackButton onClick={onBackClick}>
        <LeftArrow /> Back to all case tasks
      </BackButton>
      <>
        <type.H4>Basic Info</type.H4>
        {isNewPatient && (
          <type.Body>
            First, enter the patient’s name, email address and date of birth to
            add them into the Candid system.
          </type.Body>
        )}
        <Formik
          enableReinitialize
          initialValues={patientBasicInfoFormValues || {}}
          onSubmit={validateSubmittedAddress}
        >
          <BasicInfoForm
            customerExists={isExistingPatient}
            practiceList={practiceOptions}
            disabled={isCreatingCustomer || isSubmitted}
            submitButtonRef={submitButtonRef}
            handleSubmit={handleSubmit}
          />
        </Formik>
      </>
    </PageWrapper>
  );
};

export default BasicInfo;
