import React, { useRef, useMemo } from 'react';
import {
  Header,
  Body,
  StyledModal,
} from 'components/Modals/RefinementsModal.css';
import FullPageModal from 'components/FullPageModal/FullPageModal';
import { Template } from 'generated/core/graphql';
import api from 'state/api';
import QuestionGroups from 'components/TreatmentObjectives/TemplateQuestions';
import { Formik, FormikProps, FormikValues } from 'formik';
import styled from 'styled-components/macro';

export const TemplateDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
`;

type EditTemplateModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: FormikValues) => void;
  template: Template;
};

const EditTemplateModal = ({
  isOpen,
  onClose,
  template,
}: EditTemplateModalProps) => {
  const [updateUserTemplate, { status: updatingStatus }] =
    api.useUpdateUserTemplateMutation();

  const formRef = useRef<FormikProps<FormikValues>>(null);

  if (!template) {
    return null;
  }

  const initialValues = useMemo(() => {
    return JSON.parse(template.userAnswers?.root || '{}');
  }, [template.id]);

  const onConfirmEdit = async (values: FormikValues) => {
    if (!template) {
      return;
    }
    const input = {
      id: template.id,
      userAnswers: JSON.stringify(values),
    };
    await updateUserTemplate({
      input: input,
    });
    onClose();
  };

  const { name } = template;
  return (
    <FullPageModal
      isOpen={isOpen}
      titleText="Edit case template"
      onClose={onClose}
      onContinue={() => {
        if (formRef && formRef.current) {
          formRef.current.submitForm();
        }
      }}
      continueDisabled={!template}
      patientName={name}
      isLoading={updatingStatus === 'pending'}
    >
      <StyledModal>
        <Header>Edit Template</Header>
        <TemplateDescription>
          {
            'Select the answers you want to use as defaults for this template. When you select the template during case submissions, we’ll save you time by pre-selecting all the answers you’ve chosen.'
          }
        </TemplateDescription>
        <Body>
          <Formik
            initialValues={initialValues}
            onSubmit={onConfirmEdit}
            innerRef={formRef}
            enableReinitialize
          >
            {template && <QuestionGroups template={template} />}
          </Formik>
        </Body>
      </StyledModal>
    </FullPageModal>
  );
};

export default EditTemplateModal;
