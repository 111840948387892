import { useState } from 'react';

export default () => {
  const [state, setState] = useState({
    isError: false,
    isLoading: false,
    isSuccess: false,
    isUninitialized: true,
  });

  const executor = async <T>(fn: () => T) => {
    setState({
      ...state,
      isLoading: true,
      isUninitialized: false,
    });

    try {
      await fn();

      setState({
        ...state,
        isLoading: false,
        isSuccess: true,
      });
    } catch (e) {
      setState({
        ...state,
        isLoading: false,
        isError: true,
      });
      throw e;
    }
  };

  return [executor, state] as const;
};
