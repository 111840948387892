import styled from 'styled-components';
import { TextInput } from 'core/components';
import { Label } from 'styles/inputs.css';

export const DrawerTitle = styled.h3`
  margin: 5% 5% 0;
`;

export const FilterLabel = styled(Label)`
  margin: 5% 5% 0;
`;

export const FilterTextInput = styled(TextInput)`
  width: 90%;
  margin: 0 5%;
`;

export const SelectContainer = styled.div`
  margin: 0 5%;
`;
