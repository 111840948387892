import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  CreateUserTemplateMutation,
  CreateUserTemplateMutationVariables,
  CreateUserTemplateDocument,
  GetUserTemplatesDocument,
  GetUserTemplatesQuery,
  GetUserTemplatesQueryVariables,
  UpdateUserTemplateDocument,
  UpdateUserTemplateMutation,
  UpdateUserTemplateMutationVariables,
  ActionItemsOverviewDocument,
  ActionItemsOverviewQuery,
  ActionItemsOverviewQueryVariables,
  AddPartDocument,
  AddPartMutation,
  AddPartMutationVariables,
  AddTreatmentPlanStagingDocument,
  AddTreatmentPlanStagingMutation,
  AddTreatmentPlanStagingMutationVariables,
  AddTreatmentPlanToolingDocument,
  AddTreatmentPlanToolingMutation,
  AddTreatmentPlanToolingMutationVariables,
  CancelOrderDocument,
  CancelOrderMutation,
  CancelOrderMutationVariables,
  CreateOrderDocument,
  CreateOrderMutation,
  CreateOrderMutationVariables,
  GenerateTemplatedTreatmentPlanToolingFileDocument,
  GenerateTemplatedTreatmentPlanToolingFileMutation,
  GenerateTemplatedTreatmentPlanToolingFileMutationVariables,
  GetAllOrdersDocument,
  GetAllOrdersQuery,
  GetAllOrdersQueryVariables,
  GetCaseDocument,
  GetCaseQuery,
  GetCaseQueryVariables,
  GetCasesDocument,
  GetCasesForSearchDocument,
  GetCasesForSearchQuery,
  GetCasesForSearchQueryVariables,
  GetCasesQuery,
  GetCasesQueryVariables,
  GetInvoicesForCaseDocument,
  GetInvoicesForCaseQuery,
  GetInvoicesForCaseQueryVariables,
  GetManufacturingRequestStatusDocument,
  GetManufacturingRequestStatusQuery,
  GetManufacturingRequestStatusQueryVariables,
  GetLatestManufacturingRequestStatusDocument,
  GetLatestManufacturingRequestStatusQuery,
  GetLatestManufacturingRequestStatusQueryVariables,
  GetLatestViewerUpdateRequestStatusDocument,
  GetLatestViewerUpdateRequestStatusQuery,
  GetLatestViewerUpdateRequestStatusQueryVariables,
  GetMaterialUploadDataDocument,
  GetMaterialUploadDataQuery,
  GetMaterialUploadDataQueryVariables,
  GetMostRecentlyApprovedTpStagingForPatientDocument,
  GetMostRecentlyApprovedTpStagingForPatientQuery,
  GetMostRecentlyApprovedTpStagingForPatientQueryVariables,
  GetOrdersDocument,
  GetOrdersQuery,
  GetOrdersQueryVariables,
  GetOrderItemsCoreDocument,
  GetOrderItemsCoreQuery,
  GetOrderItemsCoreQueryVariables,
  GetPracticePaymentStatusDocument,
  GetPracticePaymentStatusQuery,
  GetPracticePaymentStatusQueryVariables,
  GetProductVariantsDocument,
  GetProductVariantsQuery,
  GetProductVariantsQueryVariables,
  GetSystemTemplatesDocument,
  GetSystemTemplatesQuery,
  GetSystemTemplatesQueryVariables,
  GetTreatmentPlanningSoftwareOpeningPayloadDocument,
  GetTreatmentPlanningSoftwareOpeningPayloadQuery,
  GetTreatmentPlanningSoftwareOpeningPayloadQueryVariables,
  GetTreatmentPlanStagingsByPatientIdDocument,
  GetTreatmentPlanStagingsByPatientIdQuery,
  GetTreatmentPlanStagingsByPatientIdQueryVariables,
  ProcessManufacturingRequestDocument,
  ProcessManufacturingRequestMutation,
  ProcessManufacturingRequestMutationVariables,
  SaveManufacturingFilesDocument,
  SaveManufacturingFilesMutation,
  SaveManufacturingFilesMutationVariables,
  SubmitUserTreatmentObjectiveDocument,
  SubmitUserTreatmentObjectiveMutation,
  SubmitUserTreatmentObjectiveMutationVariables,
  GetTemplateDocument,
  GetTemplateQuery,
  GetTemplateQueryVariables,
  GetTemplateByIdDocument,
  GetTemplateByIdQuery,
  GetTemplateByIdQueryVariables,
  GetLatestTreatmentObjectiveWithQuestionsDocument,
  GetLatestTreatmentObjectiveWithQuestionsQuery,
  GetLatestTreatmentObjectiveWithQuestionsQueryVariables,
  GetTreatmentPlanStagingByIdQuery,
  GetTreatmentPlanStagingByIdQueryVariables,
  GetTreatmentPlanStagingByIdDocument,
  CreateDfaTreatmentPlanMutation,
  CreateDfaTreatmentPlanDocument,
  CreateDfaTreatmentPlanMutationVariables,
  ClearTreatmentPlanningDraftFilesDocument,
  ClearTreatmentPlanningDraftFilesMutation,
  ClearTreatmentPlanningDraftFilesMutationVariables,
  UpdateOrderMutation,
  UpdateOrderMutationVariables,
  UpdateOrderDocument,
  GetShipmentForOrderItemQuery,
  GetShipmentForOrderItemQueryVariables,
  GetShipmentForOrderItemDocument,
} from 'generated/core/graphql';

import {
  CustomerSearchDocument,
  CustomerSearchQuery,
  CustomerSearchQueryVariables,
  GetAllPracticesDocument,
  GetAllPracticesQuery,
  GetAllPracticesQueryVariables,
  GetConsolidatedOrderItemsByCaseRefDocument,
  GetConsolidatedOrderItemsByCaseRefQuery,
  GetConsolidatedOrderItemsByCaseRefQueryVariables,
  GetPracticesDocument,
  GetPracticesQuery,
  GetPracticesQueryVariables,
  GetConsolidatedOrderItemsDocument,
  GetConsolidatedOrderItemsQuery,
  GetConsolidatedOrderItemsQueryVariables,
  GetPracticeDocument,
  GetPracticeQuery,
  GetPracticeQueryVariables,
  CandidProCustomerDocument,
  CandidProCustomerQuery,
  CandidProCustomerQueryVariables,
  SetAccountPreferencesDocument,
  SetAccountPreferencesMutation,
  SetAccountPreferencesMutationVariables,
  MeDocument,
  MeQuery,
  MeQueryVariables,
  SetPreferencesDocument,
  SetPreferencesMutation,
  SetPreferencesMutationVariables,
} from 'generated/legacy/graphql';

import { coreClient, client as legacyClient } from 'gql/GraphQLProvider';
import { mutateDecorator, queryDecorator } from 'state/api/utils';

const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: 'invalid://unknown/use_queryFn_instead',
  }),
  tagTypes: [
    'ActionItem',
    'Case',
    'Customer',
    'Invoice',
    'Order',
    'Part',
    'Patient',
    'OrderItem',
    'Practice',
    'PracticePaymentStatus',
    'ProductVariant',
    'Shipment',
    'TreatmentPlanStaging',
    'TreatmentPlanTooling',
    'User',
    'UserTemplate',
    'UserClinicalPreferencesTemplate',
  ],
  keepUnusedDataFor: 60 /* Default is 60; in seconds */,
  endpoints: (builder) => ({
    /* action item */
    getActionItemsOverview: builder.query({
      providesTags: ['ActionItem'],
      queryFn: queryDecorator(
        coreClient.query<
          ActionItemsOverviewQuery,
          ActionItemsOverviewQueryVariables
        >,
        'actionItemsOverview',
        ActionItemsOverviewDocument
      ),
    }),
    /* user */
    getMe: builder.query({
      providesTags: ['User'],
      queryFn: queryDecorator(
        legacyClient.query<MeQuery, MeQueryVariables>,
        'me',
        MeDocument
      ),
    }),

    /* cases */
    getCasesForPatients: builder.query({
      providesTags: ['Case'],
      queryFn: queryDecorator(
        coreClient.query<
          GetCasesForSearchQuery,
          GetCasesForSearchQueryVariables
        >,
        'getCases',
        GetCasesForSearchDocument
      ),
    }),
    getCases: builder.query({
      providesTags: ['Case'],
      queryFn: queryDecorator(
        coreClient.query<GetCasesQuery, GetCasesQueryVariables>,
        'getCases',
        GetCasesDocument
      ),
    }),
    getCase: builder.query({
      providesTags: ['Case'],
      queryFn: queryDecorator(
        coreClient.query<GetCaseQuery, GetCaseQueryVariables>,
        'getCase',
        GetCaseDocument
      ),
    }),

    /* customers */
    getCustomers: builder.query({
      providesTags: ['Customer'],
      queryFn: queryDecorator(
        legacyClient.query<CustomerSearchQuery, CustomerSearchQueryVariables>,
        'customerSearch',
        CustomerSearchDocument
      ),
    }),

    /* manufacturing */
    getManufacturingRequestStatus: builder.query({
      providesTags: [],
      queryFn: queryDecorator(
        coreClient.query<
          GetManufacturingRequestStatusQuery,
          GetManufacturingRequestStatusQueryVariables
        >,
        'getManufacturingRequestStatus',
        GetManufacturingRequestStatusDocument
      ),
    }),
    getLatestManufacturingRequestStatus: builder.query({
      providesTags: [],
      queryFn: queryDecorator(
        coreClient.query<
          GetLatestManufacturingRequestStatusQuery,
          GetLatestManufacturingRequestStatusQueryVariables
        >,
        'getLatestManufacturingRequestStatus',
        GetLatestManufacturingRequestStatusDocument
      ),
    }),
    getLatestViewerUpdateRequestStatus: builder.query({
      providesTags: [],
      queryFn: queryDecorator(
        coreClient.query<
          GetLatestViewerUpdateRequestStatusQuery,
          GetLatestViewerUpdateRequestStatusQueryVariables
        >,
        'getLatestViewerUpdateRequestStatus',
        GetLatestViewerUpdateRequestStatusDocument
      ),
    }),
    processManufacturingRequest: builder.mutation({
      invalidatesTags: ['Part', 'TreatmentPlanTooling'],
      queryFn: mutateDecorator(
        coreClient.mutate<
          ProcessManufacturingRequestMutation,
          ProcessManufacturingRequestMutationVariables
        >,
        'processManufacturingRequest',
        ProcessManufacturingRequestDocument
      ),
    }),

    /* s3 presigned */
    getMaterialUploadData: builder.query({
      providesTags: [],
      queryFn: queryDecorator(
        coreClient.query<
          GetMaterialUploadDataQuery,
          GetMaterialUploadDataQueryVariables
        >,
        'getMaterialUploadData',
        GetMaterialUploadDataDocument
      ),
    }),

    /* order */
    createOrder: builder.mutation({
      invalidatesTags: ['Order'],
      queryFn: mutateDecorator(
        coreClient.mutate<CreateOrderMutation, CreateOrderMutationVariables>,
        'createOrder',
        CreateOrderDocument
      ),
    }),
    updateOrder: builder.mutation({
      invalidatesTags: ['Order'],
      queryFn: mutateDecorator(
        coreClient.mutate<UpdateOrderMutation, UpdateOrderMutationVariables>,
        'updateOrder',
        UpdateOrderDocument
      ),
    }),
    cancelOrder: builder.mutation({
      invalidatesTags: ['Order'],
      queryFn: mutateDecorator(
        coreClient.mutate<CancelOrderMutation, CancelOrderMutationVariables>,
        'cancelOrder',
        CancelOrderDocument
      ),
    }),
    getConsolidatedOrderItems: builder.query({
      providesTags: ['Order'],
      queryFn: queryDecorator(
        legacyClient.query<
          GetConsolidatedOrderItemsByCaseRefQuery,
          GetConsolidatedOrderItemsByCaseRefQueryVariables
        >,
        'consolidatedOrderItems',
        GetConsolidatedOrderItemsByCaseRefDocument
      ),
    }),
    getOrders: builder.query({
      providesTags: ['Order'],
      queryFn: queryDecorator(
        coreClient.query<GetOrdersQuery, GetOrdersQueryVariables>,
        'orders',
        GetOrdersDocument
      ),
    }),
    getAllOrders: builder.query({
      providesTags: ['Order'],
      queryFn: queryDecorator(
        coreClient.query<GetAllOrdersQuery, GetAllOrdersQueryVariables>,
        'orders',
        GetAllOrdersDocument
      ),
    }),
    getOrderItemsCore: builder.query({
      providesTags: ['OrderItem'],
      queryFn: queryDecorator(
        coreClient.query<
          GetOrderItemsCoreQuery,
          GetOrderItemsCoreQueryVariables
        >,
        'orderItems',
        GetOrderItemsCoreDocument
      ),
    }),
    getOrderItems: builder.query({
      providesTags: ['OrderItem'],
      queryFn: queryDecorator(
        legacyClient.query<
          GetConsolidatedOrderItemsQuery,
          GetConsolidatedOrderItemsQueryVariables
        >,
        'consolidatedOrderItems',
        GetConsolidatedOrderItemsDocument
      ),
    }),
    getShipmentForOrderItem: builder.query({
      providesTags: ['Shipment'],
      queryFn: queryDecorator(
        coreClient.query<
          GetShipmentForOrderItemQuery,
          GetShipmentForOrderItemQueryVariables
        >,
        'getShipmentForOrderItem',
        GetShipmentForOrderItemDocument
      ),
    }),
    getInvoicesForCase: builder.query({
      providesTags: ['Invoice'],
      queryFn: queryDecorator(
        coreClient.query<
          GetInvoicesForCaseQuery,
          GetInvoicesForCaseQueryVariables
        >,
        'getInvoicesForCase',
        GetInvoicesForCaseDocument
      ),
    }),
    /* part */
    addPart: builder.mutation({
      invalidatesTags: ['Part'],
      queryFn: mutateDecorator(
        coreClient.mutate<AddPartMutation, AddPartMutationVariables>,
        'addPart',
        AddPartDocument
      ),
    }),

    /* patient */
    getPatient: builder.query({
      providesTags: ['Patient'],
      queryFn: queryDecorator(
        legacyClient.query<
          CandidProCustomerQuery,
          CandidProCustomerQueryVariables
        >,
        'customer',
        CandidProCustomerDocument
      ),
    }),

    /* practice */
    getPractices: builder.query({
      providesTags: ['Practice'],
      queryFn: queryDecorator(
        legacyClient.query<GetAllPracticesQuery, GetAllPracticesQueryVariables>,
        'practices',
        GetAllPracticesDocument
      ),
    }),
    getFilteredPractices: builder.query({
      providesTags: ['Practice'],
      queryFn: queryDecorator(
        legacyClient.query<GetPracticesQuery, GetPracticesQueryVariables>,
        'practices',
        GetPracticesDocument
      ),
    }),
    getPractice: builder.query({
      providesTags: ['Practice'],
      queryFn: queryDecorator(
        legacyClient.query<GetPracticeQuery, GetPracticeQueryVariables>,
        'practice',
        GetPracticeDocument
      ),
    }),
    setAccountPreferences: builder.mutation({
      invalidatesTags: ['User'],
      queryFn: mutateDecorator(
        legacyClient.mutate<
          SetAccountPreferencesMutation,
          SetAccountPreferencesMutationVariables
        >,
        'setAccountPreferences',
        SetAccountPreferencesDocument
      ),
    }),
    setPreferences: builder.mutation({
      queryFn: mutateDecorator(
        legacyClient.mutate<
          SetPreferencesMutation,
          SetPreferencesMutationVariables
        >,
        'setPreferences',
        SetPreferencesDocument
      ),
    }),

    /* practice payment status */
    getPracticePaymentStatus: builder.query({
      providesTags: ['PracticePaymentStatus'],
      queryFn: queryDecorator(
        coreClient.query<
          GetPracticePaymentStatusQuery,
          GetPracticePaymentStatusQueryVariables
        >,
        'practicePaymentStatus',
        GetPracticePaymentStatusDocument
      ),
    }),

    /* product variants */
    getProductVariants: builder.query({
      providesTags: ['ProductVariant'],
      queryFn: queryDecorator(
        coreClient.query<
          GetProductVariantsQuery,
          GetProductVariantsQueryVariables
        >,
        'productVariants',
        GetProductVariantsDocument
      ),
    }),

    /* treatment plan staging */
    addTreatmentPlanStaging: builder.mutation({
      invalidatesTags: ['TreatmentPlanStaging'],
      queryFn: mutateDecorator(
        coreClient.mutate<
          AddTreatmentPlanStagingMutation,
          AddTreatmentPlanStagingMutationVariables
        >,
        'addTreatmentPlanStaging',
        AddTreatmentPlanStagingDocument
      ),
    }),

    getTreatmentPlanStagingForPatient: builder.query({
      providesTags: ['TreatmentPlanStaging'],
      queryFn: queryDecorator(
        coreClient.query<
          GetTreatmentPlanStagingsByPatientIdQuery,
          GetTreatmentPlanStagingsByPatientIdQueryVariables
        >,
        'getTreatmentPlanStagingMaterials',
        GetTreatmentPlanStagingsByPatientIdDocument
      ),
    }),
    getTreatmentPlanStagingById: builder.query({
      providesTags: ['TreatmentPlanStaging'],
      queryFn: queryDecorator(
        coreClient.query<
          GetTreatmentPlanStagingByIdQuery,
          GetTreatmentPlanStagingByIdQueryVariables
        >,
        'getTreatmentPlanStagingById',
        GetTreatmentPlanStagingByIdDocument
      ),
    }),
    createDfaTreatmentPlan: builder.mutation({
      invalidatesTags: ['TreatmentPlanStaging'],
      queryFn: mutateDecorator(
        coreClient.mutate<
          CreateDfaTreatmentPlanMutation,
          CreateDfaTreatmentPlanMutationVariables
        >,
        'createDfaTreatmentPlan',
        CreateDfaTreatmentPlanDocument
      ),
    }),
    getMostRecentlyApprovedTpStagingForPatient: builder.query({
      providesTags: ['TreatmentPlanStaging'],
      queryFn: queryDecorator(
        coreClient.query<
          GetMostRecentlyApprovedTpStagingForPatientQuery,
          GetMostRecentlyApprovedTpStagingForPatientQueryVariables
        >,
        'getMostRecentlyApprovedTpStagingForPatient',
        GetMostRecentlyApprovedTpStagingForPatientDocument
      ),
    }),

    /* treatment plan tooling */
    addTreatmentPlanTooling: builder.mutation({
      invalidatesTags: ['TreatmentPlanTooling'],
      queryFn: mutateDecorator(
        coreClient.mutate<
          AddTreatmentPlanToolingMutation,
          AddTreatmentPlanToolingMutationVariables
        >,
        'addTreatmentPlanTooling',
        AddTreatmentPlanToolingDocument
      ),
    }),
    generateTemplatedTreatmentPlanToolingFile: builder.mutation({
      invalidatesTags: ['TreatmentPlanTooling'],
      queryFn: mutateDecorator(
        coreClient.mutate<
          GenerateTemplatedTreatmentPlanToolingFileMutation,
          GenerateTemplatedTreatmentPlanToolingFileMutationVariables
        >,
        'generateTemplatedTreatmentPlanToolingFile',
        GenerateTemplatedTreatmentPlanToolingFileDocument
      ),
    }),

    /* workflows */
    getTreatmentPlanningSoftwareOpeningPayload: builder.query({
      providesTags: [],
      queryFn: queryDecorator(
        coreClient.query<
          GetTreatmentPlanningSoftwareOpeningPayloadQuery,
          GetTreatmentPlanningSoftwareOpeningPayloadQueryVariables
        >,
        'getTreatmentPlanningSoftwareOpeningPayload',
        GetTreatmentPlanningSoftwareOpeningPayloadDocument
      ),
    }),
    saveManufacturingFiles: builder.mutation({
      invalidatesTags: ['Part', 'TreatmentPlanTooling'],
      queryFn: mutateDecorator(
        coreClient.mutate<
          SaveManufacturingFilesMutation,
          SaveManufacturingFilesMutationVariables
        >,
        'saveManufacturingFiles',
        SaveManufacturingFilesDocument
      ),
    }),
    /* materials */
    createTemplate: builder.mutation({
      invalidatesTags: ['UserTemplate'],
      queryFn: mutateDecorator(
        coreClient.mutate<
          CreateUserTemplateMutation,
          CreateUserTemplateMutationVariables
        >,
        'createTemplate',
        CreateUserTemplateDocument
      ),
    }),
    getUserTemplates: builder.query({
      providesTags: ['UserTemplate'],
      queryFn: queryDecorator(
        coreClient.query<GetUserTemplatesQuery, GetUserTemplatesQueryVariables>,
        'getUserTemplates',
        GetUserTemplatesDocument
      ),
    }),
    getUserClinicalPreferencesTemplate: builder.query({
      providesTags: ['UserClinicalPreferencesTemplate'],
      queryFn: queryDecorator(
        coreClient.query<GetUserTemplatesQuery, GetUserTemplatesQueryVariables>,
        'getUserTemplates',
        GetUserTemplatesDocument
      ),
    }),
    updateUserTemplate: builder.mutation({
      invalidatesTags: ['UserClinicalPreferencesTemplate'],
      queryFn: mutateDecorator(
        coreClient.mutate<
          UpdateUserTemplateMutation,
          UpdateUserTemplateMutationVariables
        >,
        'updateTemplate',
        UpdateUserTemplateDocument
      ),
    }),
    getLatestTreatmentObjectiveWithQuestions: builder.query({
      queryFn: queryDecorator(
        coreClient.query<
          GetLatestTreatmentObjectiveWithQuestionsQuery,
          GetLatestTreatmentObjectiveWithQuestionsQueryVariables
        >,
        'getLatestTreatmentObjectiveWithQuestions',
        GetLatestTreatmentObjectiveWithQuestionsDocument
      ),
    }),
    getSystemTemplates: builder.query({
      providesTags: ['UserTemplate'],
      queryFn: queryDecorator(
        coreClient.query<
          GetSystemTemplatesQuery,
          GetSystemTemplatesQueryVariables
        >,
        'getSystemTemplates',
        GetSystemTemplatesDocument
      ),
    }),
    // TODO rename
    getTemplate: builder.query({
      providesTags: ['UserClinicalPreferencesTemplate'],
      queryFn: queryDecorator(
        coreClient.query<GetTemplateQuery, GetTemplateQueryVariables>,
        'getOrCreateTemplate',
        GetTemplateDocument
      ),
    }),
    getTemplateById: builder.query({
      queryFn: queryDecorator(
        coreClient.query<GetTemplateByIdQuery, GetTemplateByIdQueryVariables>,
        'getOrCreateTemplate',
        GetTemplateByIdDocument
      ),
    }),
    submitTreatmentObjective: builder.mutation({
      queryFn: mutateDecorator(
        coreClient.mutate<
          SubmitUserTreatmentObjectiveMutation,
          SubmitUserTreatmentObjectiveMutationVariables
        >,
        'submitTreatmentObjective',
        SubmitUserTreatmentObjectiveDocument
      ),
    }),
    clearTreatmentPlanningDraftFiles: builder.mutation({
      queryFn: mutateDecorator(
        coreClient.mutate<
          ClearTreatmentPlanningDraftFilesMutation,
          ClearTreatmentPlanningDraftFilesMutationVariables
        >,
        'clearTreatmentPlanningDraftFiles',
        ClearTreatmentPlanningDraftFilesDocument
      ),
    }),
  }),
});

export default api;
