import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import moment from 'moment';

import { Maybe } from 'graphql/jsutils/Maybe';

import {
  AggregateType,
  CreatePrismPhotoDocument,
  CreatePrismPhotoMutation,
  CreatePrismPhotoMutationVariables,
  PhotoType,
  PhotoTypes,
  PhotoTypeType,
  PrismAggregateDocument,
  PrismAggregateQuery,
  PrismAggregateQueryVariables,
  PrismAggregateStateDocument,
  PrismAggregateStateQuery,
  PrismAggregateStateQueryVariables,
  SupportedFileTypes,
  TransitionPrismPhotoDocument,
  MutationsTransitionPrismPhotoArgs,
  SubmissionItemTransitions,
  TransitionPrismPhotoMutation,
  UpdatePhotoMutation,
  UpdatePhotoMutationVariables,
  UpdatePhotoFields,
  UpdatePhotoDocument,
  CreatePrismSubmissionMutation,
  CreatePrismSubmissionDocument,
  CreatePrismSubmissionMutationVariables,
  SubmissionType,
  RejectionReason,
  CustomerType,
  CustomerWithCasesPrismQuery,
  CustomerWithCasesPrismQueryVariables,
  CustomerWithCasesPrismDocument,
  DoctorType,
  Case,
  CaseType,
} from 'generated/legacy/graphql';
import { client as apolloClient } from 'gql/GraphQLProvider';
import { AppThunk, RootState } from 'state/store';
import { NavDirection } from 'utils/types';
// TODO tdror uncomment when studios is better prepared for this
// import { validateMimeType } from 'utils/prism';
import { getNavigatedView, Sort, sortByCreated, splitList } from 'utils/prism';

import { AggregateState } from 'pages/Prism/types';
import { canUpdatePhotoOrView } from 'pages/Prism/utils';

type SelectedPhotos = {
  [key: string]: PhotoType | null;
};

enum NoticiationType {
  Info = 'info',
  Error = 'error',
  Success = 'success',
}

type NotificationEvent = {
  message: string;
  type: NoticiationType;
};

type PrismCustomerWithCasesType = {
  customer: Maybe<
    { __typename?: 'CustomerType' } & Pick<
      CustomerType,
      'id' | 'firstName' | 'lastName'
    > & {
        referringDentist: Maybe<
          { __typename?: 'DoctorType' } & Pick<DoctorType, 'id' | 'fullName'>
        >;
        cases: Maybe<
          Array<
            Maybe<
              { __typename?: 'Case' } & Pick<
                Case,
                | 'caseRef'
                | 'isActive'
                | 'state'
                | 'isGen2'
                | 'requireOrthoReview'
              > & {
                  caseType: Maybe<
                    { __typename?: 'CaseType' } & Pick<
                      CaseType,
                      'id' | 'name' | 'label'
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

type PrismState = {
  aggregate: AggregateType | null;
  aggregateState: AggregateState | null;
  currentView: PhotoTypeType | null;
  error: string | null;
  isCreatingSubmission: boolean;
  isEditing: boolean;
  isLoading: boolean;
  isTransitioningPhoto: boolean;
  isUploadingPhoto: boolean;
  isZippingPhotos: boolean;
  notificationEvent: NotificationEvent | null;
  photos: PhotoType[];
  selectedPhotos: SelectedPhotos;
  submissionChecksPass: boolean;
  submissionDate: string | null;
  prismCustomer: PrismCustomerWithCasesType | null;
  isFetchingPrismCustomer: boolean;
};

// Due to migrations, the createdAt date on PhotoType cannot be
// trusted and we have to get the `stateData.created` to find out when
// it was submitted.
const photoSort = (a: PhotoType, b: PhotoType) =>
  sortByCreated(Sort.Desc)(a.stateData, b.stateData);

const filterPhotosByName =
  (name?: string) =>
  ({ photoType }: PhotoType) =>
    photoType.name === name;

const initSelectedPhotos = (
  photos: PhotoType[],
  photoTypes: PhotoTypeType[]
) => {
  return photoTypes.reduce((acc: SelectedPhotos, { name }) => {
    // use the most recent photo that matches the name
    const matching = photos.filter(filterPhotosByName(name)).sort(photoSort);

    return {
      ...acc,
      [name]: matching[0] ?? null,
    };
  }, {});
};

export const fetchAggregate = createAsyncThunk(
  'prism/fetchAggregate',
  async (aggregateRef: string) => {
    const { data } = await apolloClient.query<
      PrismAggregateQuery,
      PrismAggregateQueryVariables
    >({
      query: PrismAggregateDocument,
      variables: {
        aggregateRef,
      },
    });

    return data;
  }
);

const fetchAggregateState = createAsyncThunk(
  'prism/fetchAggregateState',
  async (aggregateRef: string) => {
    const { data } = await apolloClient.query<
      PrismAggregateStateQuery,
      PrismAggregateStateQueryVariables
    >({
      query: PrismAggregateStateDocument,
      variables: {
        aggregateRef,
      },
    });

    return data;
  }
);

export const fetchPrismCustomer = createAsyncThunk(
  'prism/fetchPrismCustomer',
  async (customerId: string) => {
    const { data } = await apolloClient.query<
      CustomerWithCasesPrismQuery,
      CustomerWithCasesPrismQueryVariables
    >({
      query: CustomerWithCasesPrismDocument,
      variables: {
        customerId,
      },
    });
    return data;
  }
);

export const createSubmission = createAsyncThunk(
  'prism/createSubmission',
  async ({
    aggregateRef,
    initialNote,
    submissionPhotoRefs,
  }: {
    aggregateRef: string;
    initialNote: string;
    submissionPhotoRefs: Array<string>;
  }) => {
    const { data } = await apolloClient.mutate<
      CreatePrismSubmissionMutation,
      CreatePrismSubmissionMutationVariables
    >({
      mutation: CreatePrismSubmissionDocument,
      variables: {
        input: {
          aggregateRef,
          initialNote: initialNote !== '' ? initialNote : null,
          submissionPhotoRefs,
        },
      },
      fetchPolicy: 'no-cache',
    });

    return data;
  }
);

const isHEIC = (upload: File | Blob) => upload.type === 'image/heic';

const isPNG = (upload: File | Blob) => upload.type === 'image/png';

export const uploadPhoto = createAsyncThunk(
  'prism/uploadPhoto',
  async (
    {
      aggregateRef,
      photoType,
      file,
      prevPhotoRef,
      cropMetadata,
      refetchAggregate,
    }: {
      aggregateRef: string;
      photoType: PhotoTypes;
      file: File | Blob;
      prevPhotoRef?: string;
      cropMetadata?: any;
      refetchAggregate?: boolean;
    },
    { dispatch }
  ) => {
    // TODO tdror uncomment when studios is better prepared for this
    // const validateMimeTypes = ['image/jpeg'];
    // const isValidMimeType = await validateMimeType(file, validateMimeTypes);
    // if (!isValidMimeType) {
    //   throw Error('Must upload jpg or jpeg file');
    // }

    const { data } = await apolloClient.mutate<
      CreatePrismPhotoMutation,
      CreatePrismPhotoMutationVariables
    >({
      mutation: CreatePrismPhotoDocument,
      variables: {
        input: {
          aggregateRef,
          photoType,
          fileExt: isPNG(file)
            ? SupportedFileTypes.Png
            : SupportedFileTypes.Jpg,
          prevPhotoRef,
          cropMetadata,
        },
      },
      fetchPolicy: 'no-cache',
    });

    let blob = file;

    if (isHEIC(file)) {
      const { default: h2a } = await import('heic2any');
      const converted = await h2a({ blob: file, toType: 'image/jpeg' });

      if ('size' in converted) {
        blob = converted as Blob;
      } else {
        blob = (converted as Blob[])[0];
      }
    }

    const { url, fields } = data?.createPrismPhoto?.uploadData || {};
    const uploadData = new FormData();
    const parsedFields: { [key: string]: string } = JSON.parse(fields);
    Object.entries(parsedFields).forEach(([key, value]) =>
      uploadData.append(key, value as string | Blob)
    );

    uploadData.append('file', blob);

    await axios({
      method: 'POST',
      url: url!,
      data: uploadData,
      headers: {
        'Content-Type': file.type,
      },
    });

    // @TODO Call query to fetch updated photoSet data (when created) here
    // instead of prepending photo to the photos array in uploadPhoto.fulfilled?

    // Refresh aggregate state (2s delay ensures backend state is updated)
    window.setTimeout(() => {
      if (refetchAggregate) {
        dispatch(fetchAggregate(aggregateRef));
      } else {
        dispatch(fetchAggregateState(aggregateRef));
      }
    }, 2000);

    return data;
  }
);

export const transitionPhoto = createAsyncThunk(
  'prism/transitionPhoto',
  async ({
    photoRef,
    transition,
    rejectionReasons,
    rejectionNotes,
  }: {
    photoRef: string;
    transition: SubmissionItemTransitions;
    rejectionReasons?: RejectionReason[];
    rejectionNotes?: string;
  }) => {
    const { data } = await apolloClient.mutate<
      TransitionPrismPhotoMutation,
      MutationsTransitionPrismPhotoArgs
    >({
      mutation: TransitionPrismPhotoDocument,
      variables: {
        input: {
          photoRef,
          transition,
          rejectionReasons,
          rejectionNotes,
        },
      },
      fetchPolicy: 'no-cache',
    });

    return data;
  }
);

export const updatePhoto = createAsyncThunk(
  'prism/updatePhoto',
  async ({
    photoRef,
    updates,
  }: {
    photoRef: string;
    updates: UpdatePhotoFields;
  }) => {
    const { data } = await apolloClient.mutate<
      UpdatePhotoMutation,
      UpdatePhotoMutationVariables
    >({
      mutation: UpdatePhotoDocument,
      variables: {
        input: {
          photoRef,
          updates,
        },
      },
      fetchPolicy: 'no-cache',
    });

    return data?.updatePrismPhoto?.photo || {};
  }
);

export const zipPhotos = createAsyncThunk(
  'prism/zipPhotos',
  async ({
    photos,
    customer,
  }: {
    photos: PhotoType[];
    customer: CustomerType;
  }) => {
    const zip = new JSZip();
    const { id: customerId, firstName, lastName } = customer;
    const lastNameFormatted = lastName.replace(/\s/g, '');
    const filePrefix = `${customerId}_${lastNameFormatted}${firstName[0]}`;
    const date = moment().format('MM-DD-YYYY');

    await Promise.all(
      photos.map(async ({ photoUrl, photoType }) => {
        if (!photoUrl) {
          console.warn(`No photo url for ${photoType.label}`);
          return;
        }
        const ext = photoUrl.split('?')[0].split('.').pop() || 'jpg'; // prettier-ignore
        const { data } = await axios(photoUrl, {
          responseType: 'blob',
        });
        zip.file(
          `${filePrefix}_Photos/${filePrefix}_${photoType.name}.${ext}`,
          data
        );
      })
    );

    const content = await zip.generateAsync({ type: 'blob' });
    saveAs(content, `${filePrefix}_${date}.zip`);
  }
);

const initialState: PrismState = {
  aggregate: null,
  aggregateState: null,
  currentView: null,
  error: null,
  isCreatingSubmission: false,
  isEditing: false,
  isLoading: true,
  isTransitioningPhoto: false,
  isUploadingPhoto: false,
  isZippingPhotos: false,
  notificationEvent: null,
  photos: [],
  selectedPhotos: {},
  submissionChecksPass: false,
  submissionDate: null,
  prismCustomer: null,
  isFetchingPrismCustomer: false,
};

const prismSlice = createSlice({
  name: 'prism',
  initialState,
  reducers: {
    resetState: () => initialState,
    setIsEditing: (state, action: PayloadAction<boolean>) => {
      state.isEditing = action.payload;
    },
    setSelectedPhotoForView: (
      state,
      action: PayloadAction<{ view: string; photo: PhotoType }>
    ) => {
      const { view, photo } = action.payload;
      state.selectedPhotos[view] = photo;
    },
    setCurrentView: (state, action: PayloadAction<string>) => {
      const view =
        state.aggregate?.aggregateType.requiredPhotoTypes.find(
          ({ name }) => name === action.payload
        ) ?? null;
      state.currentView = view;
    },
  },
  extraReducers: (builder) => {
    // fetchAggregate
    builder.addCase(fetchAggregate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAggregate.fulfilled, (state, action) => {
      const aggregate = action.payload?.prismAggregate as AggregateType;
      const photos = [...aggregate.photoSet].sort(sortByCreated(Sort.Desc));
      const photoTypes = aggregate.aggregateType.requiredPhotoTypes;
      state.aggregate = aggregate;
      state.aggregateState =
        (aggregate.stateData?.data as AggregateState) ?? null;
      if (!state.currentView) {
        state.currentView = aggregate.aggregateType?.requiredPhotoTypes?.[0];
      }
      state.photos = photos;
      state.selectedPhotos = initSelectedPhotos(photos, photoTypes);
      state.submissionChecksPass =
        !!aggregate.createSubmissionCriteriaMet?.checksPass;
      state.isLoading = false;
      state.isEditing = false;
      // If a doctor is not assigned to the aggregate, show an error.
      const {
        photos_aggregate_has_doctor_assigned: isDoctorAssigned,
        required_photo_types_present_for_aggregate: hasRequiredPhotos,
      } = JSON.parse(aggregate.createSubmissionCriteriaMet?.checksSummary);
      if (!isDoctorAssigned && hasRequiredPhotos) {
        state.error =
          'This case is missing an assigned doctor. Please assign a doctor to this case before submitting.';
      }
    });
    builder.addCase(fetchAggregate.rejected, (state, action) => {
      state.error = action.error.message as string;
      state.isLoading = false;
    });
    // fetchAggregateState
    builder.addCase(fetchAggregateState.fulfilled, (state, action) => {
      const aggregateState = action.payload?.prismAggregate?.stateData
        ?.data as AggregateState;
      if (aggregateState) {
        state.aggregateState = aggregateState;
      }
    });
    builder.addCase(fetchAggregateState.rejected, (state, action) => {
      state.error = action.error.message as string;
    });
    // uploadPhoto
    builder.addCase(uploadPhoto.pending, (state) => {
      state.isUploadingPhoto = true;
    });
    builder.addCase(uploadPhoto.fulfilled, (state, action) => {
      const photo = action.payload?.createPrismPhoto?.photo as PhotoType;
      const view = photo?.photoType.name;
      // Prepend uploaded photo to photos, and select in current view
      state.photos.unshift(photo);
      state.selectedPhotos[view] = photo;
      state.isUploadingPhoto = false;
    });
    builder.addCase(uploadPhoto.rejected, (state, action) => {
      state.error = action.error.message as string;
      state.isUploadingPhoto = false;
    });
    // transitionPhoto
    builder.addCase(transitionPhoto.pending, (state) => {
      state.isTransitioningPhoto = true;
    });
    builder.addCase(transitionPhoto.fulfilled, (state, action) => {
      const photo = action.payload?.transitionPrismPhoto?.photo as PhotoType;
      const aggregateState = photo?.aggregate.stateData?.data as AggregateState;
      const oldPhotoIndex = state.photos.findIndex((p) => p.ref === photo?.ref);
      if (photo && state.photos && oldPhotoIndex >= 0) {
        state.photos[oldPhotoIndex] = photo;
        // Keep abo/sidebar and lower array in sync
        state.selectedPhotos[photo.photoType.name] = photo;
      }
      state.isTransitioningPhoto = false;
      // Update aggregate state
      if (aggregateState) {
        state.aggregateState = aggregateState;
      }
      state.submissionChecksPass =
        !!photo?.aggregate.createSubmissionCriteriaMet?.checksPass;
    });
    builder.addCase(transitionPhoto.rejected, (state, action) => {
      state.error = action.error.message as string;
      state.isTransitioningPhoto = false;
    });
    // movePhoto
    builder.addCase(updatePhoto.pending, (state) => {
      state.isTransitioningPhoto = true;
    });
    builder.addCase(updatePhoto.fulfilled, (state, action) => {
      const photo = action.payload as PhotoType;
      const oldPhotoIndex = state.photos.findIndex((p) => p.ref === photo?.ref);
      if (photo && state.photos && oldPhotoIndex >= 0) {
        state.photos[oldPhotoIndex] = photo;
        // Keep abo/sidebar and lower array in sync
        if (state.selectedPhotos[photo.photoType.name]?.ref === photo.ref) {
          state.selectedPhotos[photo.photoType.name] = photo;
        }
      }
      state.isTransitioningPhoto = false;
    });
    builder.addCase(updatePhoto.rejected, (state, action) => {
      state.error = action.error.message as string;
      state.isTransitioningPhoto = false;
    });
    builder.addCase(createSubmission.pending, (state) => {
      state.isCreatingSubmission = true;
    });
    builder.addCase(createSubmission.fulfilled, (state, action) => {
      const submission = action.payload?.createPrismSubmission
        ?.submission as SubmissionType;
      const aggregateState = submission.aggregate?.stateData
        ?.data as AggregateState;
      state.submissionDate = submission.submissionDate;
      state.isCreatingSubmission = false;
      // Update aggregate state
      if (aggregateState) {
        state.aggregateState = aggregateState;
      }
      state.isEditing = false;
      state.notificationEvent = {
        message: 'Submitted for ortho review',
        type: NoticiationType.Success,
      };
    });
    builder.addCase(createSubmission.rejected, (state, action) => {
      state.error = action.error.message as string;
      state.submissionDate = null;
      state.isCreatingSubmission = false;
    });
    // zipPhotos
    builder.addCase(zipPhotos.pending, (state) => {
      state.isZippingPhotos = true;
    });
    builder.addCase(zipPhotos.fulfilled, (state, _) => {
      state.isZippingPhotos = false;
    });
    builder.addCase(zipPhotos.rejected, (state, action) => {
      state.error = action.error.message as string;
      state.isZippingPhotos = false;
    });
    // fetchPrismCustomer
    builder.addCase(fetchPrismCustomer.pending, (state) => {
      state.isFetchingPrismCustomer = true;
    });
    builder.addCase(fetchPrismCustomer.fulfilled, (state, action) => {
      state.prismCustomer = action.payload as PrismCustomerWithCasesType;
      state.isFetchingPrismCustomer = false;
    });
    builder.addCase(fetchPrismCustomer.rejected, (state, action) => {
      state.error = action.error.message as string;
      state.isZippingPhotos = false;
    });
  },
});

export const { resetState, setIsEditing } = prismSlice.actions;

const { setSelectedPhotoForView, setCurrentView } = prismSlice.actions;

export const selectAggregate = (state: RootState) => state.prism.aggregate;
export const selectAggregateState = (state: RootState) =>
  state.prism.aggregateState;
export const selectCurrentView = (state: RootState) => state.prism.currentView;
export const selectCustomer = (state: RootState) =>
  state.prism.aggregate?.customer;
export const selectError = (state: RootState) => state.prism.error;
export const selectIsCreatingSubmission = (state: RootState) =>
  state.prism.isCreatingSubmission;
export const selectIsLoading = (state: RootState) => state.prism.isLoading;
export const selectIsTransitioningPhoto = (state: RootState) =>
  state.prism.isTransitioningPhoto;
export const selectNotificationEvent = (state: RootState) =>
  state.prism.notificationEvent;
const selectPhotos = (state: RootState) => state.prism.photos;
const selectPhotoTypes = (state: RootState) =>
  state.prism.aggregate?.aggregateType?.requiredPhotoTypes;
export const selectSelectedPhotos = (state: RootState) =>
  state.prism.selectedPhotos ?? [];
export const selectSubmissionDate = (state: RootState) =>
  state.prism.submissionDate;
const selectRequiredPhotoTypes = (state: RootState) =>
  state.prism.aggregate?.aggregateType.requiredPhotoTypes;
export const selectIsEditing = (state: RootState) => state.prism.isEditing;
export const selectIsUploadingPhoto = (state: RootState) =>
  state.prism.isUploadingPhoto;
export const selectIsZippingPhotos = (state: RootState) =>
  state.prism.isZippingPhotos;
export const selectIsFetchingPrismCustomer = (state: RootState) =>
  state.prism.isFetchingPrismCustomer;
export const selectPrismCustomer = (state: RootState) =>
  state.prism.prismCustomer;

export const selectPhotoViewNames = createSelector(
  selectRequiredPhotoTypes,
  (requiredPhotoTypes): [string[], string[]] => {
    const photoNames: string[] =
      requiredPhotoTypes?.map(({ name }) => name) ?? [];

    return splitList(photoNames, (name) => name.endsWith('_w_aligners'));
  }
);

export const selectShortName = createSelector(selectCustomer, (customer) => {
  if (!customer) {
    return '';
  }
  const [firstInitial] = customer.firstName;
  return `${firstInitial}. ${customer.lastName}`;
});

export const selectFullName = createSelector(selectCustomer, (customer) => {
  if (!customer) {
    return '';
  }
  return `${customer.preferredName ?? customer.firstName} ${customer.lastName}`;
});

export const selectPhotosInView = createSelector(
  selectPhotos,
  selectCurrentView,
  (photos, currentView) =>
    photos.filter(filterPhotosByName(currentView?.name)).sort(photoSort)
);

export const selectCurrentViewRejectionReasons = createSelector(
  selectCurrentView,
  (currentView): RejectionReason[] =>
    currentView?.photoQualityRejectionReasons?.map(({ name, label }) => ({
      name,
      label,
    })) ?? []
);

export const updateView =
  (view: string): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const isEditing = selectIsEditing(state);
    const isUploadingPhoto = selectIsUploadingPhoto(state);

    if (!canUpdatePhotoOrView(isEditing, isUploadingPhoto)) {
      return;
    }

    // @TODO Check if photos have expired and refresh if so?

    dispatch(setCurrentView(view));
  };

export const navigateToView =
  (direction: NavDirection): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const photoTypes = selectPhotoTypes(state)!;
    const currentView = selectCurrentView(state)?.name!;
    const next = getNavigatedView(photoTypes, currentView, direction);

    dispatch(updateView(next));
  };

export const updateSelectedPhoto =
  (view: string, photo: PhotoType): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const isEditing = selectIsEditing(state);
    const isUploadingPhoto = selectIsUploadingPhoto(state);

    if (!canUpdatePhotoOrView(isEditing, isUploadingPhoto)) {
      return;
    }

    dispatch(setSelectedPhotoForView({ view, photo }));
  };

export const movePhoto =
  ({ to, photo }: { to: PhotoTypes; photo: PhotoType }): AppThunk =>
  async (dispatch, getState) => {
    const aggRef = selectAggregate(getState())?.ref ?? '';

    await Promise.all([
      dispatch(setCurrentView(to)),
      dispatch(
        updatePhoto({
          photoRef: photo.ref,
          updates: {
            photoType: to,
          },
        })
      ),
      dispatch(fetchAggregate(aggRef)),
    ]);

    // Refresh aggregate state
    dispatch(fetchAggregateState(aggRef));
  };

export default prismSlice.reducer;
