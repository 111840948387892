import React, { useMemo } from 'react';
import Dinero from 'dinero.js';
import { HRDividerLine } from 'styles/layout.css';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import {
  TotalContainer,
  PriceInfoContainer,
  Total,
  LineItemContainer,
  TaxInfo,
  BulletedLineItem,
  PopoverMessage,
  DiscountDescription,
  DiscountAmount,
  TotalPrice,
  ZoomDisclaimer,
  StyledDeleteOutline,
  QuantityControls,
  StyledTextInput,
  NameAndQuantityContainer,
  PriceContainer,
} from 'components/TotalBox/TotalBox.css';
import { Popover } from 'core/components';
import { Discount, Maybe } from 'generated/core/graphql';
import { Skeleton } from 'core/components';
import CheckoutAlertCard from 'components/AlertCards/CheckoutAlertCard';

import { CatalogItemType } from 'types/checkout';

const TotalSummary = ({
  totalQuantity,
  subtotal,
}: {
  totalQuantity: number;
  subtotal: number;
}) => (
  <PriceInfoContainer>
    <LineItemContainer>
      <Total>Total</Total>
      <TaxInfo>
        <BulletedLineItem>Tax not included</BulletedLineItem>
        <Popover
          on="hover"
          direction="top"
          anchor={<InfoIcon fontSize="small" />}
        >
          <PopoverMessage>
            Your state taxes will be added to your invoice
          </PopoverMessage>
        </Popover>
      </TaxInfo>
    </LineItemContainer>
    <div>
      {totalQuantity ? (
        <TotalPrice>{Dinero({ amount: subtotal }).toFormat()}</TotalPrice>
      ) : (
        '—'
      )}
    </div>
  </PriceInfoContainer>
);

const LineItemDiscount = ({ discount }: { discount?: Discount }) => (
  <PriceInfoContainer>
    <DiscountDescription>{discount?.description}</DiscountDescription>
    <DiscountAmount>
      -{Dinero({ amount: discount?.reductionInCents }).toFormat()}
    </DiscountAmount>
  </PriceInfoContainer>
);

const TotalsLineItem = ({
  item,
  loyaltyDiscount,
  supportQuantity = true,
  displayQuantity = false,
  cartItemIndex,
  setQuantity = () => {},
  onDeleteItem = () => {},
}: {
  item: CatalogItemType;
  loyaltyDiscount?: Discount;
  supportQuantity?: boolean;
  displayQuantity?: boolean;
  cartItemIndex: number;
  setQuantity?: (index: number, quantity: number) => void;
  onDeleteItem?: (index: number) => void;
}) => {
  const priceDisplay =
    item.quantity > 0
      ? item.totalPriceBeforeDiscounts.toFormat()
      : supportQuantity
        ? 'Select quantity above'
        : '';
  const discountedQuantity = Dinero({
    amount:
      item.totalPriceBeforeDiscounts.getAmount() -
      item.totalPriceAfterDiscounts.getAmount(),
  });

  return (
    <>
      <PriceInfoContainer>
        {supportQuantity && displayQuantity ? (
          <NameAndQuantityContainer>
            {item.providerFacingProductName}
            <QuantityControls>
              <StyledDeleteOutline
                onClick={() => onDeleteItem(cartItemIndex)}
              />
              <StyledTextInput
                value={item.quantity.toString()}
                type="number"
                onChange={(e) => {
                  if (!parseInt(e.currentTarget.value)) {
                    e.currentTarget.value = '0';
                  }
                  setQuantity(
                    cartItemIndex,
                    parseInt(e.currentTarget.value) || 0
                  );
                }}
                onBlur={(e) => {
                  if (!parseInt(e.currentTarget.value)) {
                    onDeleteItem(cartItemIndex);
                  }
                }}
              />
            </QuantityControls>
          </NameAndQuantityContainer>
        ) : (
          <div>{item.providerFacingProductName}</div>
        )}
        {supportQuantity ? (
          <PriceContainer>{item.quantity ? priceDisplay : '—'}</PriceContainer>
        ) : (
          <PriceContainer>{priceDisplay}</PriceContainer>
        )}
      </PriceInfoContainer>
      {loyaltyDiscount && loyaltyDiscount?.reductionInCents > 0 && (
        <LineItemDiscount discount={loyaltyDiscount} />
      )}
      {discountedQuantity.getAmount() > 0 &&
        item.appliedDiscounts &&
        item.appliedDiscounts.length > 0 && (
          <>
            {item.appliedDiscounts.map(
              (discount: Maybe<Discount>, index: number) =>
                discount && <LineItemDiscount discount={discount} key={index} />
            )}
          </>
        )}
    </>
  );
};

const TotalBox = ({
  orderItems,
  loyaltyDiscount,
  supportQuantity,
  displayQuantity = false,
  isLoading,
  message,
  setQuantity,
  onDeleteItem,
  displayZoomDisclaimer = true,
}: {
  orderItems: CatalogItemType[];
  loyaltyDiscount?: Discount;
  supportQuantity?: boolean;
  displayQuantity?: boolean;
  isLoading?: boolean;
  message?: string;
  setQuantity?: (index: number, quantity: number) => void;
  onDeleteItem?: (index: number) => void;
  displayZoomDisclaimer?: boolean;
}) => {
  // subtotal and totalQuantity update when the quantity updates on the order item
  const subtotal = useMemo(() => {
    return orderItems.reduce(
      (total, item) => total + item.totalPriceAfterDiscounts.getAmount(),
      0
    );
  }, [orderItems]);

  const totalQuantity = useMemo(() => {
    return orderItems.reduce((acc, item) => acc + item.quantity, 0);
  }, [orderItems]);

  return (
    <TotalContainer>
      {message && <CheckoutAlertCard message={message} />}
      {orderItems.map((item: CatalogItemType, index) =>
        isLoading ? (
          <Skeleton
            animation="wave"
            variant="text"
            width={'100%'}
            key={index}
          />
        ) : (
          <>
            <TotalsLineItem
              key={index}
              cartItemIndex={index}
              item={item}
              loyaltyDiscount={loyaltyDiscount}
              supportQuantity={supportQuantity}
              displayQuantity={displayQuantity}
              setQuantity={setQuantity}
              onDeleteItem={onDeleteItem}
            />
          </>
        )
      )}
      <HRDividerLine spacing="0.5rem" mobileSpacing="0.25rem" />
      {isLoading ? (
        <Skeleton animation="wave" variant="text" width={'100%'} />
      ) : (
        <>
          <TotalSummary totalQuantity={totalQuantity} subtotal={subtotal} />
          {displayZoomDisclaimer && (
            <ZoomDisclaimer>
              Zoom Whitening will be invoiced separately if requested during
              case submission
            </ZoomDisclaimer>
          )}
        </>
      )}
    </TotalContainer>
  );
};

export default TotalBox;
