import React from 'react';
import { CaseData, ContextProps } from 'pages/TpCentralEnhanced/types';
import UploadForm from 'pages/TpCentralEnhanced/UploadForm';
import { ActionSection } from 'pages/TpCentralEnhanced/Actions/components';
import { useTranslation } from 'react-i18next';
import {
  CaseSoftwareChoice,
  TreatmentPlanStaging,
} from 'generated/core/graphql';
import { ManufacturingFileVersions } from 'pages/TpCentralEnhanced/UploadForm/UploadForm';
import { Button } from 'core/components';
import useSaveTestManufacturingFiles from 'hooks/useSaveTestManufacturingFiles';

export const UploadManufacturingFiles = ({
  customerRef,
  caseData,
  isComplete = false,
  isDisabled = true,
  selectedTreatmentPlan,
  isApproved,
  parts,
}: {
  customerRef: string;
  caseData: CaseData;
  isComplete?: boolean;
  isDisabled?: boolean;
  selectedTreatmentPlan: TreatmentPlanStaging;
  isApproved: boolean;
  parts: ContextProps['parts'];
}) => {
  const [saveTestManufacturingFiles, { isUninitialized }] =
    useSaveTestManufacturingFiles();

  const { t } = useTranslation();
  return !selectedTreatmentPlan.createdAt ? null : (
    <>
      <ActionSection
        title={t('tp_central_enhanced.manufacturing_file_versions')}
        disabledText={t(
          'tp_central_enhanced.the_treatment_plan_must_be_approved'
        )}
        buttonTitle={t('common.show')}
      >
        <ManufacturingFileVersions
          selectedTreatmentPlanStaging={selectedTreatmentPlan}
          parts={parts}
        />
      </ActionSection>
      <ActionSection
        title={t('tp_central_enhanced.upload_manufacturing_files')}
        isComplete={isComplete}
        isDisabled={isDisabled}
        showWhenComplete={isApproved}
        disabledText={t(
          'tp_central_enhanced.the_treatment_plan_must_be_approved'
        )}
        buttonTitle={t('common.show')}
      >
        {import.meta.env.VITE_REACT_APP_ENV !== 'production' &&
          (caseData?.caseSoftware === CaseSoftwareChoice.Vision ||
            caseData?.caseSoftware === null) && (
            <Button
              buttonType="secondary-outline"
              disabled={!isUninitialized}
              onClick={() =>
                saveTestManufacturingFiles({
                  caseRef: caseData.caseRef,
                  krakenCaseId: caseData.krakenCaseIdentifier,
                  patientId: parseInt(customerRef),
                })
              }
            >
              Generate Manufacturing Files
            </Button>
          )}
        <UploadForm caseData={caseData} patientId={parseInt(customerRef)} />
      </ActionSection>
    </>
  );
};
