import React, { useMemo } from 'react';
import ReactSelect, { ValueType } from 'react-select';
import api from 'state/api';
import { FormType, SelectOption } from 'pages/OrdersPortal/types';
import {
  includeProductVariantByBrand,
  includeProductVariantByFormType,
} from 'pages/OrdersPortal/utils';
import { ProductSelectContainer } from 'pages/OrdersPortal/OrdersPortal.css';
import { Label } from 'styles/inputs.css';
import { OrdersProductManufacturingPartTypeChoices } from 'generated/core/graphql';
import { Nullable } from 'utils/types';

export type SelectedProduct = {
  sku?: Nullable<string>;
  manufacturingPartType?: Nullable<OrdersProductManufacturingPartTypeChoices>;
  title?: string;
  price?: number;
};

const ProductSelect = ({
  label,
  onProductChange,
  brand,
  includeGenericProducts,
  formType,
  controlledValue,
  setControlledValue = () => {},
}: {
  label?: string;
  onProductChange?: ({
    sku,
    manufacturingPartType,
    title,
    price,
  }: SelectedProduct) => void;
  brand: string;
  includeGenericProducts: boolean;
  formType?: FormType;
  controlledValue?: ValueType<SelectOption>;
  setControlledValue?: (value: ValueType<SelectOption>) => void;
}) => {
  const { data: productVariantsData, isFetching: isFetchingProductVariants } =
    api.useGetProductVariantsQuery({});

  const productVariantsPartTypeMap = useMemo(() => {
    const skuToProductVariantMap = new Map<string, SelectedProduct>();
    productVariantsData?.forEach((productVariant) => {
      const {
        sku,
        product: { manufacturingPartType },
      } = productVariant;
      skuToProductVariantMap.set(sku, {
        manufacturingPartType,
        title: String(productVariant.label),
        price: productVariant.defaultPriceInCents,
      });
    });
    return skuToProductVariantMap;
  }, [productVariantsData]);

  const productOptions: SelectOption[] =
    productVariantsData
      ?.filter(
        (productVariant) =>
          productVariant?.isVisible &&
          includeProductVariantByFormType(productVariant, formType) &&
          includeProductVariantByBrand(
            productVariant,
            brand,
            includeGenericProducts
          )
      )
      .map((productVariant) => {
        const { sku, label } = productVariant;
        return {
          value: sku,
          label: `${label} - ${sku}`,
        };
      }) || [];

  const handleProductChange = (option: ValueType<SelectOption>) => {
    const selectedOption = option as SelectOption;
    setControlledValue(selectedOption);

    if (selectedOption) {
      onProductChange?.({
        sku: selectedOption.value,
        ...productVariantsPartTypeMap.get(selectedOption.value),
      });
    }
  };

  return (
    <ProductSelectContainer>
      {label && <Label>{label}</Label>}
      {controlledValue !== undefined ? (
        // value controlled by parent component
        <ReactSelect
          placeholder="Select a product..."
          options={productOptions}
          isLoading={isFetchingProductVariants}
          onChange={handleProductChange}
          value={controlledValue}
        />
      ) : (
        // value uncontrolled
        <ReactSelect
          placeholder="Select a product..."
          options={productOptions}
          isLoading={isFetchingProductVariants}
          onChange={handleProductChange}
        />
      )}
    </ProductSelectContainer>
  );
};

export default ProductSelect;
