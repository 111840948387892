import { useState, useEffect } from 'react';
import { RESERVED_QUESTION_KEYS } from 'constants/treatmentObjective';
import {
  Template,
  TemplateType,
  TreatmentObjectiveQuestionTypes,
} from 'generated/core/graphql';
import api from 'state/api';
import moment from 'moment';

// Create type for the parameters
type UseTreatmentObjectiveParams = {
  userId: string | undefined;
  caseType: string | undefined;
  caseRef: string | undefined;
  brandId: number | undefined;
  userAccountPreferencesTemplateId?: string | null | undefined; // Optional if we want to force using the user account preferences in the logged in user instead of the submitted treatment objective in the form
};

const useTreatmentObjective = ({
  userId,
  caseType,
  caseRef,
  brandId,
  userAccountPreferencesTemplateId,
}: UseTreatmentObjectiveParams) => {
  const [initialValues, setInitialValues] = useState({});
  const [selectedCaseTemplate, setSelectedCaseTemplate] =
    useState<Template | null>(null);
  const [
    getLatestTreatmentObjectiveWithQuestions,
    { data: latestTreatmentObjectiveWithQuestions },
  ] = api.useLazyGetLatestTreatmentObjectiveWithQuestionsQuery({});
  const [getSystemTemplates, { data: systemTemplates = [] }] =
    api.useLazyGetSystemTemplatesQuery({});
  const [getTemplate, { data: originatedTemplate }] =
    api.useLazyGetTemplateByIdQuery();
  const [
    getUserClinicialPreferencesTemplate,
    { data: clinicalPreferencesTemplate },
  ] = api.useLazyGetTemplateQuery();
  const [getCaseTemplates, { data: caseTemplates = [] }] =
    api.useLazyGetUserTemplatesQuery();

  useEffect(() => {
    if (!userId || !caseRef) {
      return;
    }
    getLatestTreatmentObjectiveWithQuestions({
      caseRef: caseRef,
    });
    getSystemTemplates({
      caseType: caseType,
      type: TemplateType.CaseTemplate,
    });
    const [caseTemplateSection, userClinicalTemplateSection] =
      latestTreatmentObjectiveWithQuestions?.data.templateSections ?? [];

    if (caseTemplateSection?.templateId) {
      getTemplate({
        templateId: caseTemplateSection?.templateId,
      });
    }
    const clinicalPreferencesTemplatePayload = {
      templateId:
        userAccountPreferencesTemplateId ||
        userClinicalTemplateSection?.templateId,
      userId: Number(userId),
      brandId: brandId,
      templateType: TemplateType.AccountPreferences,
    };
    getUserClinicialPreferencesTemplate(clinicalPreferencesTemplatePayload);

    getCaseTemplates({
      userId: Number(userId),
      type: TemplateType.CaseTemplate,
    });
  }, [
    userId,
    caseRef,
    latestTreatmentObjectiveWithQuestions?.data.templateSections[0]?.templateId,
  ]);

  useEffect(() => {
    const { templateSections, chiefComplaint, toothCharts } =
      latestTreatmentObjectiveWithQuestions?.data ?? {};
    const fetchedTemplateAnswers: [string, string | boolean | string[]][] = [];
    for (const sections of templateSections ?? []) {
      for (const questions of sections.sections) {
        for (const question of questions?.questions ?? []) {
          fetchedTemplateAnswers.push([question.questionId, question.answer]);
          // if the question is multi select, we need to add the question id with the option text
          if (question.type === TreatmentObjectiveQuestionTypes.MultiSelect) {
            for (const option of question.answer ?? []) {
              fetchedTemplateAnswers.push([
                `${question.questionId}-${option}`,
                true,
              ]);
            }
          }
          for (const followUp of question.followUpQuestions ?? []) {
            if (followUp.type === TreatmentObjectiveQuestionTypes.MultiSelect) {
              for (const option of followUp.answer ?? []) {
                fetchedTemplateAnswers.push([
                  `${followUp.followUpQuestionId}-${option}`,
                  true,
                ]);
              }
            } else {
              fetchedTemplateAnswers.push([
                followUp.followUpQuestionId,
                followUp.answer,
              ]);
            }
          }
        }
      }
    }
    setInitialValues({
      [RESERVED_QUESTION_KEYS.CHIEF_COMPLAINT]: chiefComplaint,
      [RESERVED_QUESTION_KEYS.CANNOT_MOVE]: toothCharts?.cannotMove,
      [RESERVED_QUESTION_KEYS.WILL_EXTRACT]: toothCharts?.willExtract,
      [RESERVED_QUESTION_KEYS.ERUPTION_COMPENSATION]:
        toothCharts?.eruptionCompensation,
      ...Object.fromEntries(fetchedTemplateAnswers),
      ...JSON.parse(selectedCaseTemplate?.userAnswers?.root ?? '{}'),
    });
  }, [latestTreatmentObjectiveWithQuestions?.id, selectedCaseTemplate?.id]);

  return {
    initialValues,
    latestTreatmentObjectiveWithQuestions,
    systemTemplates: systemTemplates?.sort((a, b) =>
      moment(b.createdAt).diff(moment(a.createdAt))
    ), // Sort by createdAt date by the latest
    originatedTemplate: originatedTemplate,
    clinicalPreferencesTemplate: clinicalPreferencesTemplate,
    caseTemplates: caseTemplates,
    selectedCaseTemplate,
    setSelectedCaseTemplate,
  };
};

export default useTreatmentObjective;
