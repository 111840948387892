import React, { FormEvent, useCallback } from 'react';
import { Drawer } from '@material-ui/core';
import {
  DrawerTitle,
  FilterLabel,
  FilterTextInput,
  SelectContainer,
} from 'pages/OrdersAdmin/FilterDrawer.css';
import { Button } from 'core/components';
import PracticeSelect from 'components/PracticeSelect';
import { OrderStatus } from 'generated/core/graphql';
import { SelectInput } from 'core/components';

type StatusFilterSelectOption = {
  label: string;
  value: OrderStatus;
} | null;

type FilterDrawerProps = {
  filterDrawerOpen: boolean;
  setFilterDrawerOpen: (value: boolean) => void;
  patientIdFilter: string;
  practiceIdFilter: string;
  setPatientIdFilter: (value: string) => void;
  caseRefFilter: string;
  setCaseRefFilter: (value: string) => void;
  orderRefFilter: string;
  setOrderRefFilter: (value: string) => void;
  setPracticeIdFilter: (value: string) => void;
  statusFilter: OrderStatus | undefined;
  setStatusFilter: (value: OrderStatus | undefined) => void;
};

const FilterDrawer = ({
  filterDrawerOpen,
  setFilterDrawerOpen,
  patientIdFilter,
  practiceIdFilter,
  setPatientIdFilter,
  caseRefFilter,
  setCaseRefFilter,
  orderRefFilter,
  setOrderRefFilter,
  setPracticeIdFilter,
  statusFilter,
  setStatusFilter,
}: FilterDrawerProps) => {
  const onInputKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        setFilterDrawerOpen(false);
      }
    },
    [setFilterDrawerOpen]
  );

  return (
    <Drawer
      anchor="right"
      open={filterDrawerOpen}
      onClose={() => {
        setFilterDrawerOpen(false);
      }}
      ModalProps={{
        BackdropProps: {
          style: {
            backgroundColor: '#0000',
          },
        },
      }}
      PaperProps={{
        style: {
          width: '300px',
          padding: '1rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          gap: '2rem',
        },
        elevation: 2,
      }}
      keepMounted
    >
      <DrawerTitle>Filter orders</DrawerTitle>
      <div>
        <Button
          type="button"
          onClick={() => {
            setPatientIdFilter('');
            setCaseRefFilter('');
            setOrderRefFilter('');
            setPracticeIdFilter('');
            setStatusFilter(undefined);
          }}
        >
          Reset
        </Button>
      </div>
      <div>
        <FilterLabel>Status</FilterLabel>
        <SelectContainer>
          <SelectInput
            key={`order-admin-status-filter-${statusFilter}`}
            options={Object.values(OrderStatus).map((status) => ({
              label: status.toString(),
              value: status,
            }))}
            onChange={(value) => {
              const selectedOption = value as StatusFilterSelectOption;
              if (!selectedOption) {
                setStatusFilter(undefined);
                return;
              }
              setStatusFilter(selectedOption.value as OrderStatus);
            }}
            value={
              statusFilter
                ? {
                    label: statusFilter.toString(),
                    value: statusFilter,
                  }
                : undefined
            }
            isClearable
          />
        </SelectContainer>
      </div>
      <div>
        <FilterLabel>Practice</FilterLabel>
        <SelectContainer>
          <PracticeSelect
            defaultSelection={{
              value: practiceIdFilter,
              label: `${practiceIdFilter}`,
            }}
            setSelectedPracticeId={(id?: number) => {
              setPracticeIdFilter(id?.toString() ?? '');
            }}
          />
        </SelectContainer>
        <FilterLabel>Patient ID</FilterLabel>
        <FilterTextInput
          value={patientIdFilter}
          onChange={(e: FormEvent<HTMLInputElement>) => {
            const value = e.currentTarget.value;
            setPatientIdFilter(value.replaceAll(/[^0-9]/g, ''));
          }}
          onKeyPress={onInputKeyPress}
        />
        <FilterLabel>Case ref</FilterLabel>
        <FilterTextInput
          value={caseRefFilter}
          onChange={(e: FormEvent<HTMLInputElement>) => {
            const value = e.currentTarget.value;
            setCaseRefFilter(value);
          }}
          onKeyPress={onInputKeyPress}
        />
        <FilterLabel>Order ref</FilterLabel>
        <FilterTextInput
          value={orderRefFilter}
          onChange={(e: FormEvent<HTMLInputElement>) => {
            const value = e.currentTarget.value;
            setOrderRefFilter(value);
          }}
          onKeyPress={onInputKeyPress}
        />
      </div>
    </Drawer>
  );
};

export default FilterDrawer;
