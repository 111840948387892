import styled from 'styled-components/macro';
import { colors } from 'core/styles';

export const TreatmentPlanIframe = styled.iframe`
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  z-index: 999;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  width: 100%;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  background-color: white;
  border-radius: 4px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 1rem;
`;

export const TextArea = styled.textarea`
  width: 100%;
  min-height: 6.5rem;
  padding: 0.625rem;
  line-height: 1.4;
  border: 1px solid ${colors.black20};
  border-radius: 0.125rem;
  transition: border-color 0.2s ease-in-out;

  &:hover,
  &:focus {
    border-color: ${colors.black40};
  }
`;
