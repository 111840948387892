import { useFlags } from 'launchdarkly-react-client-sdk';
import { SupportedBrand, OLIV_BRAND_NAME } from 'utils/brands';

const useOrthoBypass = (brand: SupportedBrand) => {
  if (brand === OLIV_BRAND_NAME) {
    return true;
  }
  const { 'bypass-ortho-review-process': bypassOrthoReviewProcess } =
    useFlags();
  return bypassOrthoReviewProcess;
};

export default useOrthoBypass;
