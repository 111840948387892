import { useState } from 'react';
import { useHistory } from 'react-router-dom';

// This is a custom hook build on top of `useState` which allow to set default value
// base on URL query parameters and keep state consistent with URL query parameters.
// This also allows using history navigation (back and forward) to go to the previous state.

const useStateSyncedWithUrlQuery = <T>(
  query: URLSearchParams,
  paramName: string,
  initialValue: T,
  convertFunction: (arg0: string) => T
): [T, (arg0: T) => void] => {
  const queryValue = query.get(paramName);
  const [value, setValue] = useState<T>(
    queryValue ? convertFunction(queryValue) : initialValue
  );
  const history = useHistory();

  const setValueAndUpdateUrlQuery = (newValue: T) => {
    if (newValue === undefined || newValue === null) {
      query.delete(paramName);
    } else {
      query.set(paramName, new String(newValue).valueOf());
    }

    history.push(`${history.location.pathname}?${query.toString()}`);

    return setValue(newValue);
  };

  return [value, setValueAndUpdateUrlQuery];
};

export default useStateSyncedWithUrlQuery;
