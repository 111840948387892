import React from 'react';
import { Template } from 'generated/core/graphql';
import { type } from 'core/components';

import {
  GroupContainer,
  QuestionsContainer,
  QuestionsImage,
} from 'components/TreatmentObjectives/styles.css';

import Question from 'components/TreatmentObjectives/QuestionInputs';

const TemplateQuestions = ({
  template,
  disabled,
}: {
  template: Template;
  disabled?: boolean;
}) => {
  const { groupStructure, renderedQuestions } = template;

  return (
    <>
      {groupStructure?.groupOrder.map((title, index) => {
        const groupQuestions = renderedQuestions[index];

        return (
          <GroupContainer key={title}>
            <type.H4>{title}</type.H4>
            <QuestionsContainer>
              {groupQuestions?.map((question) => (
                <>
                  {question.imageUrl && !disabled && (
                    <QuestionsImage src={question.imageUrl} />
                  )}
                  <Question
                    key={question.id}
                    question={question}
                    disabled={disabled}
                  />
                </>
              ))}
            </QuestionsContainer>
          </GroupContainer>
        );
      })}
    </>
  );
};

export default TemplateQuestions;
