import React, { useEffect, useState } from 'react';
import { type } from 'core/components';
import QuestionGroups from 'components/TreatmentObjectives/TemplateQuestions';
import Question from 'components/TreatmentObjectives/QuestionInputs';
import {
  Template,
  TreatmentObjectiveQuestionTypes,
} from 'generated/core/graphql';
import { DentalNotationOptions } from 'generated/legacy/graphql';
import { RESERVED_QUESTION_KEYS } from 'constants/treatmentObjective';
import {
  ManualButton,
  TemplateButtonContainer,
  TemplateButton,
  GroupContainer,
  QuestionsContainer,
  EditButton,
  PencilIconStyled,
} from 'components/TreatmentObjectives/styles.css';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CaseTypeNames } from 'constants/Case';
import { templateHasBeenCompletedByUser } from 'utils/treatmentObjective';
const TreatmentGoalsGroup = () => {
  return (
    <GroupContainer key={'Treatment Goals'}>
      <type.H4>Treatment Goals</type.H4>
      <QuestionsContainer>
        {`Simplify case creation by creating treatment templates here.`}
      </QuestionsContainer>
    </GroupContainer>
  );
};

const TemplateButtons = ({
  caseTemplates,
  handleSelectTemplateButtonClick,
  selectedCaseTemplate,
  disabled,
}: {
  caseTemplates: Template[] | [];
  handleSelectTemplateButtonClick: (template: Template) => void;
  selectedCaseTemplate: Template | null | undefined;
  disabled: boolean;
}) => {
  if (!caseTemplates) {
    return null;
  }

  return (
    <>
      {caseTemplates.filter(templateHasBeenCompletedByUser).map((template) => (
        <TemplateButton
          buttonType="secondary-outline"
          key={template.id}
          onClick={() => handleSelectTemplateButtonClick(template)}
          isSelected={selectedCaseTemplate?.id === template.id}
          disabled={disabled}
        >
          {template.name}
        </TemplateButton>
      ))}
    </>
  );
};

const TemplateForm = ({
  systemTemplates,
  caseTemplates,
  canEditSubmission,
  selectedCaseTemplate,
  setSelectedCaseTemplate,
  viewOnly = true,
}: {
  systemTemplates: Template[];
  caseTemplates?: Template[] | [];
  canEditSubmission: boolean;
  selectedCaseTemplate?: Template | null;
  setSelectedCaseTemplate?: (template: Template) => void | null;
  viewOnly?: boolean;
}) => {
  const { 'enable-case-templates': enableCaseTemplates } = useFlags();

  const systemTemplate: Template = systemTemplates[0];
  const [answerManually, setAnswerManually] = useState(false);
  const [inlineEdit, setInlineEdit] = useState(false);

  useEffect(() => {
    if (!(selectedCaseTemplate?.id !== systemTemplate.id)) {
      // If the selected case template is the current system template, then we should answer manually
      setAnswerManually(true);
    }
  }, [selectedCaseTemplate, caseTemplates]);

  useEffect(() => {
    if (!selectedCaseTemplate?.id && systemTemplate) {
      // If no template is selected, default to the system template
      setSelectedCaseTemplate && setSelectedCaseTemplate(systemTemplate);
    }
  }, [selectedCaseTemplate?.id, systemTemplate]);

  const handleAnswerManuallyButtonClick = () => {
    setAnswerManually(true);
    setSelectedCaseTemplate && setSelectedCaseTemplate(systemTemplate);
  };

  const handleSelectTemplateButtonClick = (template: Template) => {
    if (!canEditSubmission) {
      return;
    }
    setAnswerManually(false);
    setSelectedCaseTemplate && setSelectedCaseTemplate(template);
  };

  const handleInlineEditCaseTemplateButtonClick = () => {
    if (answerManually || !canEditSubmission) {
      return;
    }
    setInlineEdit(!inlineEdit);
  };

  const showInlineEdit =
    !enableCaseTemplates || answerManually || inlineEdit || viewOnly;
  const showCaseTemplatesSection =
    caseTemplates &&
    caseTemplates.length > 0 &&
    enableCaseTemplates &&
    !viewOnly;

  return (
    <>
      {showCaseTemplatesSection && (
        <GroupContainer>
          <type.H4>Select your case template</type.H4>
          <TemplateButtonContainer>
            <ManualButton
              buttonType="secondary-outline"
              onClick={handleAnswerManuallyButtonClick}
              isSelected={selectedCaseTemplate?.id === systemTemplate.id}
              disabled={!canEditSubmission}
            >
              Answer manually
            </ManualButton>
            <TemplateButtons
              caseTemplates={caseTemplates}
              handleSelectTemplateButtonClick={handleSelectTemplateButtonClick}
              selectedCaseTemplate={selectedCaseTemplate}
              disabled={!canEditSubmission}
            />
          </TemplateButtonContainer>
          <br />
          {!answerManually && (
            <TemplateButtonContainer>
              <EditButton
                onClick={handleInlineEditCaseTemplateButtonClick}
                disabled={!canEditSubmission}
              >
                <PencilIconStyled />
                {inlineEdit
                  ? `Close inline changes for template`
                  : `Make inline changes to template for this case`}
              </EditButton>
            </TemplateButtonContainer>
          )}
        </GroupContainer>
      )}
      {showInlineEdit && (
        <>
          {enableCaseTemplates && !viewOnly && <TreatmentGoalsGroup />}
          {(answerManually ||
            !enableCaseTemplates ||
            inlineEdit ||
            viewOnly) && (
            <QuestionGroups
              template={selectedCaseTemplate || systemTemplate}
              disabled={!canEditSubmission}
            />
          )}
        </>
      )}
    </>
  );
};

const TreatmentObjectivesForm = ({
  systemTemplates,
  caseTemplates,
  dentalNotation,
  canEditSubmission,
  showDescription = true,
  clinicalPreferencesTemplate,
  selectedCaseTemplate,
  setSelectedCaseTemplate,
  viewOnly = true,
}: {
  systemTemplates: Template[];
  caseTemplates?: Template[] | [];
  dentalNotation: DentalNotationOptions;
  canEditSubmission: boolean;
  showDescription?: boolean;
  clinicalPreferencesTemplate?: Template;
  selectedCaseTemplate?: Template | null;
  setSelectedCaseTemplate?: (template: Template) => void | null;
  viewOnly?: boolean;
}) => {
  if (!systemTemplates || systemTemplates.length === 0) {
    return null;
  }
  const isRetainerTemplate =
    systemTemplates[0].caseType?.name === CaseTypeNames.RETAINER;
  const showChiefComplaintQuestion = !isRetainerTemplate;
  const showToothChartQuestions = !isRetainerTemplate;

  return (
    <div>
      {showDescription && (
        <type.Body>
          Specify how you want to treat this patient based on your case
          template, as well as any considerations for specific teeth. All cases
          will be treated according to your clinical preferences.
        </type.Body>
      )}
      {showChiefComplaintQuestion && (
        <GroupContainer>
          <type.H4>Patient’s chief complaint</type.H4>
          <Question
            question={{
              id: RESERVED_QUESTION_KEYS.CHIEF_COMPLAINT,
              text: '',
              type: TreatmentObjectiveQuestionTypes.Text,
              options: null,
            }}
            disabled={!canEditSubmission}
          />
        </GroupContainer>
      )}
      {showToothChartQuestions && (
        <GroupContainer>
          <type.H4>Tooth charts</type.H4>
          Click a button below then mark the appropriate teeth
          <Question
            question={{
              id: RESERVED_QUESTION_KEYS.CANNOT_MOVE,
              text: 'Do not move these teeth (i.e. implants, bridges, ankylosed teeth, etc.):',
              type: TreatmentObjectiveQuestionTypes.ToothChart,
              options: null,
            }}
            dentalNotation={dentalNotation}
            disabled={!canEditSubmission}
          />
          <Question
            question={{
              id: RESERVED_QUESTION_KEYS.WILL_EXTRACT,
              text: 'Mark any teeth that will be extracted prior to treatment (a virtual pontic may be placed):',
              type: TreatmentObjectiveQuestionTypes.ToothChart,
              options: null,
            }}
            dentalNotation={dentalNotation}
            disabled={!canEditSubmission}
          />
          <Question
            question={{
              id: RESERVED_QUESTION_KEYS.ERUPTION_COMPENSATION,
              text: 'Mark any teeth that require eruption compensation:',
              type: TreatmentObjectiveQuestionTypes.ToothChart,
              options: null,
            }}
            dentalNotation={dentalNotation}
            disabled={!canEditSubmission}
          />
        </GroupContainer>
      )}

      <TemplateForm
        systemTemplates={systemTemplates}
        caseTemplates={caseTemplates}
        canEditSubmission={canEditSubmission}
        selectedCaseTemplate={selectedCaseTemplate}
        setSelectedCaseTemplate={setSelectedCaseTemplate}
        viewOnly={viewOnly}
      />
      {clinicalPreferencesTemplate && (
        <>
          <type.H4
            style={{
              margin: '40px 0',
            }}
          >
            Clinical preferences
          </type.H4>
          <QuestionGroups
            template={clinicalPreferencesTemplate as Template}
            disabled={!canEditSubmission}
          />
        </>
      )}
    </div>
  );
};

export default TreatmentObjectivesForm;
