import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from 'components/AuthProvider';
import { Loading } from 'core/components';
import CreateTemplateModal from 'components/Modals/CreateTemplateModal';
import EditTemplateModal from 'components/Modals/EditTemplateModal';
import api from 'state/api';
import { CaseTypeNames } from 'constants/Case';
import PreferenceSection from 'pages/AccountPreferencesPage/PreferenceSection';
import {
  AddButton,
  ButtonContent,
  PlusIcon,
} from 'pages/AccountPreferencesPage/CaseTemplates.css';
import { Template, TemplateType } from 'generated/core/graphql';
import { createUserTemplateFromDefaultPayload } from 'pages/AccountPreferencesPage/utils';
import { CaseTemplate } from 'pages/AccountPreferencesPage/CaseTemplate';

type CaseTemplateListProps = {
  userTemplates: Template[] | null;
  setCurrentTemplate: (template: Template) => void;
  setIsEditModalOpen: (isOpen: boolean) => void;
};

export const CaseTemplateList = ({
  userTemplates,
  setCurrentTemplate,
  setIsEditModalOpen,
}: CaseTemplateListProps) => {
  return (
    <>
      {userTemplates &&
        userTemplates.map((template) => (
          <CaseTemplate
            key={template.id}
            template={template}
            setCurrentTemplate={setCurrentTemplate}
            setIsEditModalOpen={setIsEditModalOpen}
          />
        ))}
    </>
  );
};

const CaseTemplates = ({
  isLoading,
  id,
}: {
  isLoading: boolean;
  id?: string;
}) => {
  const { userInfo } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState<Template>();
  const [getSystemTemplates, { data: systemTemplates = [], isFetching }] =
    api.useLazyGetSystemTemplatesQuery({});
  const [getUserTemplates, { data: userTemplates = [] }] =
    api.useLazyGetUserTemplatesQuery();
  const [createUserTemplate, { status: isCreatingTemplate }] =
    api.useCreateTemplateMutation();

  const isLoadingGeneral = isLoading || isFetching;

  useEffect(() => {
    if (!userInfo) {
      return;
    }
    getUserTemplates({
      userId: parseInt(userInfo.id),
      type: TemplateType.CaseTemplate,
    });
    getSystemTemplates({
      caseType: CaseTypeNames.ALIGNER,
      type: TemplateType.CaseTemplate,
    });
  }, [userInfo]);
  const handleAddButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentTemplate(undefined);
  };

  const handleConfirmModal = async (
    templateName: string,
    description?: string
  ) => {
    const defaultTemplates =
      systemTemplates && (systemTemplates[0] as Template);

    if (!defaultTemplates || !userInfo) {
      return;
    }
    const variables = createUserTemplateFromDefaultPayload(
      defaultTemplates,
      templateName,
      userInfo.id,
      description
    );
    const createdTemplate = await createUserTemplate(variables).unwrap();
    if (createdTemplate) {
      setCurrentTemplate(createdTemplate.template as Template);
      setIsEditModalOpen(true);
    }
    setIsModalOpen(false);
  };

  return (
    <PreferenceSection
      title="Case templates"
      description="Speed up case submission by creating templates to answer questions in one click. Create as many different templates as you want."
      id={id}
    >
      {isLoadingGeneral ? (
        <Loading isCentered />
      ) : (
        <>
          <>
            {userTemplates && (
              <CaseTemplateList
                userTemplates={userTemplates as Template[]}
                setCurrentTemplate={setCurrentTemplate}
                setIsEditModalOpen={setIsEditModalOpen}
              />
            )}
          </>
          <AddButton onClick={handleAddButtonClick}>
            <ButtonContent>
              <PlusIcon />
              Add new template
            </ButtonContent>
          </AddButton>
        </>
      )}
      <CreateTemplateModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirmModal}
        isLoading={isCreatingTemplate === 'pending'}
      />
      {currentTemplate && (
        <EditTemplateModal
          isOpen={isEditModalOpen}
          onClose={() => {
            setIsEditModalOpen(false);
          }}
          onSubmit={() => {
            setIsEditModalOpen(false);
          }}
          template={currentTemplate}
        />
      )}
    </PreferenceSection>
  );
};

export default CaseTemplates;
