import axios from 'axios';
import useGenericAsyncExecutor from 'hooks/useGenericAsyncExecutor';

import api from 'state/api';
import { StringMap } from 'utils/types';

export default () => {
  const [getMaterialUploadData, getMaterialUploadDataState] =
    api.useLazyGetMaterialUploadDataQuery();

  const [uploadFile, uploadFileState] = useGenericAsyncExecutor();

  const [saveManufacturingFiles, saveManufacturingFilesState] =
    api.useSaveManufacturingFilesMutation();

  const executor = async ({
    caseRef,
    file,
    patientId,
  }: {
    caseRef: string;
    file: File;
    patientId: number;
  }) => {
    const fileName = file.name;

    const uploadResponse = await getMaterialUploadData({
      patientId,
      fileName,
    }).unwrap();

    const fields: StringMap = uploadResponse.fields;
    const url = uploadResponse.url;
    const awsFileLocation = uploadResponse.awsLocation;

    const data = new FormData();
    Object.entries(fields).forEach(([key, value]) => data.append(key, value));
    data.append('file', file);

    await uploadFile(() =>
      axios({
        method: 'POST',
        url,
        data,
        headers: {
          'Content-Type': file.type,
        },
      })
    );

    await saveManufacturingFiles({
      caseRef,
      files: [awsFileLocation],
    });
  };

  const states = [
    getMaterialUploadDataState,
    uploadFileState,
    saveManufacturingFilesState,
  ];

  return [
    executor,
    {
      ...saveManufacturingFilesState,
      isError: states.some((state) => state.isError),
      isLoading: states.some((state) => state.isLoading),
      isSuccess: states.every((state) => state.isSuccess),
      isUninitialized: states.every((state) => state.isUninitialized),
    },
  ] as const;
};
