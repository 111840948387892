import React from 'react';

import {
  ContactHeaderSection,
  ContactSectionTitle,
  ContactHeader,
  ContactSubHeader,
  PageBody,
  Title,
  ContactBody,
  PageSection,
} from 'pages/ContactUs/ContactUs.css';

import ContactInfo from 'pages/ContactUs/ContactInfo';
import { getBrandSettings, getBrandFromDomain } from 'utils/brands';
import DocumentTitle from 'components/DocumentTitle';

const ContactUs = () => {
  const brand = getBrandFromDomain();
  const { caseSupport, billingSupport, showHelpSubHeading } =
    getBrandSettings(brand);

  const header = "We're here to help";

  return (
    <DocumentTitle title="Help center">
      <PageSection>
        <PageBody>
          <Title>Contact us</Title>
          <ContactBody>
            <ContactHeaderSection>
              <ContactHeader>{header}</ContactHeader>

              {showHelpSubHeading && (
                <ContactSubHeader>
                  Have a technical, clinical, or patient status question? Here
                  are two more ways to connect with our Provider Support Team:
                </ContactSubHeader>
              )}
            </ContactHeaderSection>
            <ContactInfo
              email={caseSupport.email}
              phoneNumbers={caseSupport.phoneNumbers ?? []}
              phoneNumbersHours={caseSupport.phoneNumbersHours}
              liveChatHours={caseSupport.liveChatHours}
              contactSupportOptions={caseSupport.contactSupportOptions}
            />
            {billingSupport && (
              <>
                <ContactSectionTitle>
                  For billing/finance support
                </ContactSectionTitle>
                <ContactInfo
                  email={billingSupport.email}
                  phoneNumbers={billingSupport.phoneNumbers}
                  phoneNumbersHours={billingSupport.phoneNumbersHours}
                  liveChatHours={billingSupport.liveChatHours}
                  contactSupportOptions={billingSupport.contactSupportOptions}
                />
              </>
            )}
          </ContactBody>
        </PageBody>
      </PageSection>
    </DocumentTitle>
  );
};

export default ContactUs;
