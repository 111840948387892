import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';
import { useFormikContext } from 'formik';
import api from 'state/api';

import PatientCaseInfo from 'pages/OrdersPortal/PatientCaseInfo';
import { StyledSelect } from 'pages/OrdersPortal/PatientOrderForm.css';
import { AlertCard, Loading } from 'core/components';
import { AddressSelection } from 'components/AddressSelection/AddressSelection';
import { FormType, SelectOption } from 'pages/OrdersPortal/types';
import ProductSelect, { SelectedProduct } from 'components/ProductSelect';
import StepPicker from 'components/StepPicker';
import { getAddressInputFromAddressForm } from 'components/AddressForm/utils';
import { SelectedAddress } from 'components/AddressForm/types';
import styled from 'styled-components';

const AddressSelectionContainer = styled.div`
  margin-top: 2rem;
`;
import { PatientType, CaseType } from 'pages/OrdersPortal/types';
import { CANDID_BRAND_NAME, convertToBrand } from 'utils/brands';
import { Label } from 'styles/inputs.css';
import { FormikSelectWrapper } from 'components/FormikForms';
import {
  formatSelectedSteps,
  numSelectedSteps,
} from 'components/StepPicker/StepPicker';
import {
  MutationsCreateOrderArgs,
  OrdersProductManufacturingPartTypeChoices,
} from 'generated/core/graphql';
import { quantityOptionsForFormikSelect } from 'pages/OrdersPortal/utils';

type PatientOrderFormProps = {
  addressFormSubmitBtnRef: React.RefObject<HTMLButtonElement>;
  handleConfirmedAddress: (confirmedAddress: SelectedAddress) => void;
  setSelectedPatient: (patient: PatientType) => void;
  setSelectedCase: (caseRef: CaseType) => void;
  setAddressFormIsValid: (isValid: boolean) => void;
  setSendPatientUpdate: (sendPatientUpdate: boolean) => void;
};

const PatientOrderForm = ({
  addressFormSubmitBtnRef,
  handleConfirmedAddress,
  setSelectedPatient,
  setSelectedCase,
  setAddressFormIsValid,
  setSendPatientUpdate,
}: PatientOrderFormProps) => {
  const { setFieldValue, values, setValues } = useFormikContext();

  const [selectedPatientId, setSelectedPatientId] = useState<
    string | undefined
  >();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedProduct, setSelectedProduct] = useState<SelectedProduct>({
    sku: undefined,
    manufacturingPartType: undefined,
  });
  const [selectedSteps, setSelectedSteps] = useState({
    upper: new Set<number>(),
    lower: new Set<number>(),
  });
  const [
    searchCustomers,
    { data: searchResultsData, isFetching: isFetchingSearchResults },
  ] = api.useLazyGetCustomersQuery();
  const [
    getCasesForPatients,
    { data: casesData, isFetching: isFetchingCases },
  ] = api.useLazyGetCasesForPatientsQuery();
  const [
    getTreatmentPlanStagingsForPatientId,
    { data: treatmentPlansForPatient, isFetching: isFetchingTpStagings },
  ] = api.useLazyGetTreatmentPlanStagingForPatientQuery();
  const [fetchPatientWithBrand, { data: patientWithBrandData }] =
    api.useLazyGetPatientQuery();
  const patientBrand = patientWithBrandData?.user?.brandInfo?.name;
  const showStepPicker = useMemo(
    () =>
      selectedProduct.manufacturingPartType ===
      OrdersProductManufacturingPartTypeChoices.Replacement,
    [selectedProduct]
  );
  const showQuantityPicker = useMemo(
    () =>
      selectedProduct.manufacturingPartType ===
      OrdersProductManufacturingPartTypeChoices.Retainer,
    [selectedProduct]
  );

  const fetchPatientData = async (patientId: string) => {
    if (!patientId) {
      return;
    }

    getCasesForPatients({ patientIds: [parseInt(patientId)] });
    getTreatmentPlanStagingsForPatientId({
      patientId: parseInt(patientId),
    });
    setSelectedPatientId(patientId);
  };

  const onPatientChange = (patientOption: SelectOption) => {
    const patientId = patientOption?.value;
    if (!patientId) {
      return;
    }
    fetchPatientData(patientId);
    setFieldValue('patientId', patientId);

    setSelectedProduct({
      sku: undefined,
      manufacturingPartType: undefined,
    });
    setFieldValue('orderItems', []);

    setSelectedSteps({ upper: new Set(), lower: new Set() });
  };

  const onProductChange = ({ sku, manufacturingPartType }: SelectedProduct) => {
    if (!sku) {
      return;
    }
    setSelectedProduct({ sku, manufacturingPartType });
    setFieldValue('orderItems', [{ productVariantSku: sku, quantity: 1 }]);
    setSelectedSteps({ upper: new Set(), lower: new Set() });
  };

  const debouncedSearchCustomers = useCallback(
    debounce(searchCustomers, 500),
    []
  );

  const patientOptions: SelectOption[] =
    searchResultsData?.edges?.reduce<SelectOption[]>((options, edge) => {
      const patientId = edge?.node?.id;
      const fullName = edge?.node?.fullName;
      if (!patientId || !fullName) {
        return options;
      }

      options.push({
        value: patientId,
        label: `${fullName} (${patientId})`,
      });

      return options;
    }, []) || [];

  const selectedPatient = searchResultsData?.edges
    ?.map((edge) => edge?.node)
    .find((node) => selectedPatientId && node?.id == selectedPatientId);

  // The two lines below can be replaced by one API call to getMostRecentlyApprovedTpStagingForPatient
  const caseWithApprovedTPStaging = casesData?.find(
    (c) => c?.isActive === true
  );

  const approvedTpStaging = treatmentPlansForPatient?.find(
    (tpStaging) =>
      tpStaging?.isApproved &&
      tpStaging?.caseRef === caseWithApprovedTPStaging?.caseRef
  );

  useEffect(() => {
    if (caseWithApprovedTPStaging) {
      setSelectedCase(caseWithApprovedTPStaging as CaseType);
    }
  }, [caseWithApprovedTPStaging]);

  useEffect(() => {
    if (selectedPatientId) {
      fetchPatientWithBrand({ customerId: selectedPatientId });
    }
  }, [selectedPatientId]);

  useEffect(() => {
    if (patientWithBrandData) {
      setSelectedPatient(patientWithBrandData);
    }
  }, [patientWithBrandData]);

  useEffect(() => {
    // Update the orderItems with the selected steps
    if (showStepPicker) {
      const previousValues = values as MutationsCreateOrderArgs;
      const newOrderItem = {
        ...previousValues.orderItems[0],
        stages: formatSelectedSteps(selectedSteps),
        quantity: numSelectedSteps(selectedSteps),
      };
      setValues({
        ...previousValues,
        orderItems: [newOrderItem],
      });
    }
  }, [selectedSteps]);

  return (
    <div>
      <h3>Patient Order</h3>
      <StyledSelect
        placeholder="Search for a patient..."
        options={patientOptions}
        isLoading={isFetchingSearchResults}
        inputValue={searchTerm}
        onInputChange={(newValue: string) => {
          setSearchTerm(newValue);

          if (newValue) {
            debouncedSearchCustomers({
              searchTerm: newValue,
              first: 12,
            });
          }
        }}
        onChange={onPatientChange}
      />
      {(isFetchingCases || isFetchingTpStagings) && (
        <>
          Fetching case and treatment plan data...
          <br />
          <Loading />
        </>
      )}
      {selectedPatientId &&
        !(isFetchingCases || isFetchingTpStagings) &&
        (approvedTpStaging ? (
          <>
            <PatientCaseInfo
              patient={selectedPatient}
              caseData={caseWithApprovedTPStaging as CaseType}
              tpStaging={approvedTpStaging}
            />
            <ProductSelect
              label="Product"
              brand={convertToBrand(patientBrand, CANDID_BRAND_NAME)}
              includeGenericProducts={false}
              formType={FormType.patient}
              onProductChange={onProductChange}
              key={`ProductSelect-${selectedPatientId}`} // Reset the product select when patient changes
            />
            {showStepPicker && (
              <StepPicker
                selectedSteps={selectedSteps}
                setSelectedSteps={setSelectedSteps}
                availableSteps={{
                  upper: approvedTpStaging?.data?.detailedSteps?.upper || 0,
                  lower: approvedTpStaging?.data?.detailedSteps?.lower || 0,
                }}
              />
            )}
            {showQuantityPicker && (
              <>
                <Label>Quantity</Label>
                <FormikSelectWrapper
                  name="orderItems[0].quantity"
                  showDefaultValue
                  type={'number'}
                  options={quantityOptionsForFormikSelect(4)}
                />
              </>
            )}
            <AddressSelectionContainer>
              <AddressSelection
                patientId={selectedPatientId}
                setResultSendPatientUpdate={setSendPatientUpdate}
                setResultAddress={(address) => {
                  setFieldValue(
                    'shippingAddress',
                    getAddressInputFromAddressForm(address.value)
                  );
                }}
                addressFormSubmitBtnRef={addressFormSubmitBtnRef}
                handleConfirmedAddress={handleConfirmedAddress}
                setAddressFormIsValid={setAddressFormIsValid}
                key={`AddressSelection-${selectedPatientId}`} // Reset the address selection when patient changes
              />
            </AddressSelectionContainer>
          </>
        ) : (
          <AlertCard displayIcon={false} type="warning">
            This patient does not have approved TP staging and is not eligible
            to place orders.
          </AlertCard>
        ))}
    </div>
  );
};

export default PatientOrderForm;
