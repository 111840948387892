import { ChangeEvent, ReactNode } from 'react';
import { ActionItemTask } from 'pages/ActionItems/actionItemsSlice';
import { Nullable } from 'utils/types';

export enum JourneyStatus {
  incompleteSubmission = 'incomplete_submission',
  tpReadyForReview = 'tp_ready_for_review',
  materialsRejected = 'materials_rejected',
  needsClarification = 'needs_clarification',
}

export type TaskTableProps = {
  journeyStatus: JourneyStatus;
  tasks: ActionItemTask[];
  isFetching: boolean;
};

export type ActionButtonProps = {
  name: string;
  path: string;
};

export type AccordionTaskTableProps = {
  expanded: boolean;
  onChange: (event: ChangeEvent<object>, expanded: boolean) => void;
  tasks: ActionItemTask[];
  showSkeleton: boolean;
  journeyStatus: JourneyStatus;
  title: string;
  chipColor: string;
  chipTextColor?: any | undefined;
};

type SourceDataType = {
  key: string | null | undefined;
  value: number | null | undefined;
  color: string;
};

export type OverviewModuleProps = {
  providerTaskCounts: Nullable<any[]>;
  isFetchingProviderTasks: boolean;
  showSkeleton: (isLoading: boolean, data: Nullable<any[]>) => boolean;
  onCreateNewPatientClick: () => void;
  useClinicalPreferencesTemplates: boolean;
  loadingUserClinicalPrefs: boolean;
  clinicalPreferencesAreComplete: boolean;
};

export type DonutChartDataType = {
  displayBorder: boolean;
  data: SourceDataType[];
};

type TaskObject = {
  color: string;
  definition: string;
  display: boolean;
};

export type DefinitionsMapType = {
  [key: string]: TaskObject;
};

export type DefinitionsPopoverProps = {
  definitionsMap: DefinitionsMapType;
};

type MaterialTypeDisplay = {
  title: string;
  icon: ReactNode;
};

export type MaterialTypeMap = {
  [key: string]: MaterialTypeDisplay;
};
