import styled from 'styled-components';
import { Divider } from 'core/components';
import { Overline } from 'styles/text.css';

export const ModalTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 1rem 1rem 0;
`;

export const ModalBody = styled.div`
  display: inline-block;
  margin: 1rem !important;
  gap: 2rem;
  text-align: center;
`;

export const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  gap: 1rem;
  width: 100%;
`;

export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  * {
    margin: 0;
  }
  padding: 1rem;
  margin-bottom: 1.5rem;
  border: 1px solid ${({ theme }) => theme.colors.black20};
  border-radius: 12px;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 10px;
  padding-right: 1rem;
  height: 22rem;
  width: 20rem;
  max-height: 24rem;
  justify-content: start;
  overflow-y: auto;
`;

export const StyledOverline = styled(Overline)`
  margin: 0 0 0.5rem;
  position: sticky;
  top: 0;
  background: ${({ theme }) => theme.colors.white};
`;

export const OrderItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 0 0 1rem 0;
  border: 1px solid ${({ theme }) => theme.colors.black20};
  border-radius: 5px;
`;

export const SkuQuantityContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  div:nth-child(2) {
    text-align: right;
    padding-right: 1rem;
  }
`;

export const InnerContainer = styled.div`
  width: 24rem;
  height: 22rem;
`;

export const StyledDivider = styled(Divider)`
  margin-bottom: 10px !important;
`;
