import React, { useEffect, useState } from 'react';

import { Formik } from 'formik';
import api from 'state/api';
import { DoctorPreferencesInput, useMeQuery } from 'generated/legacy/graphql';
import { UserInfoType } from 'pages/AccountPreferencesPage/types';
import { GetTemplateQueryVariables } from 'generated/core/graphql';
import ScanPreferences from 'pages/AccountPreferencesPage/Scan';
import TreatmentPreferences from 'pages/AccountPreferencesPage/Treatment';
import ProMonitoring from 'pages/AccountPreferencesPage/ProMonitoring';
import Notation from 'pages/AccountPreferencesPage/Notation';
import CBCTScanPreferences from 'pages/AccountPreferencesPage/CBCTScanPreferences';
import CaseTemplates from 'pages/AccountPreferencesPage/CaseTemplates';
import ScanIntervalDays from 'pages/AccountPreferencesPage/ScanIntervalDays';
import PreferencesTabbedMenu from 'pages/AccountPreferencesPage/PreferencesTabbedMenu';
import { getBrandFromDomain } from 'utils/brands';
import TemplateQuestions from 'components/TreatmentObjectives/TemplateQuestions';
import { Template as GeneratedTemplateType } from 'generated/core/graphql';
import { filterTabs, TAB_FILTERS } from 'pages/AccountPreferencesPage/utils';
import { Loading } from 'core/components';

import {
  Page,
  Container,
  Sidebar,
  Header,
  FormSections,
  SidebarLink,
  SidebarTitle,
} from 'pages/AccountPreferencesPage/AccountPreferencesView.css';
import DocumentTitle from 'components/DocumentTitle';
import { OLIV_BRAND_NAME, getBrandSupportedFeatures } from 'utils/brands';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AutoSaveForm from 'pages/AccountPreferencesPage/AutoSaveForm';
import { getClinicalTemplatePayload } from 'utils/treatmentObjective';

enum ANCHOR_TAGS {
  TREATMENT = 'treatment',
  SCAN = 'scan',
  SCAN_INTERVAL = 'scan-interval',
  PRO_MONITORING = 'pro-monitoring',
  DENTAL_NOTATION = 'dental-notation',
  CBCT_SCAN = 'cbct-scan',
  CASE_TEMPLATES = 'case-templates',
}

type PreferencesProps = {
  userId: string | undefined;
  userInfo: UserInfoType;
  supportPvsScan: boolean;
  initialValues: DoctorPreferencesInput;
};

const LegacyPreferencesSidebar = () => {
  const {
    'enable-dental-notation': enableDentalNotation,
    'enable-cbct-scan': enableCbctScanFlag,
  } = useFlags();

  return (
    <Sidebar>
      <Header>Preferences</Header>
      <SidebarTitle>CASE SUBMISSION</SidebarTitle>
      <SidebarLink href={`#${ANCHOR_TAGS.TREATMENT}`}>
        Treatment preferences
      </SidebarLink>
      <SidebarLink href={`#${ANCHOR_TAGS.SCAN}`}>
        Scan upload preferences
      </SidebarLink>
      <SidebarTitle>MONITORING</SidebarTitle>
      <SidebarLink href={`#${ANCHOR_TAGS.SCAN_INTERVAL}`}>
        Aligner wear schedule
      </SidebarLink>
      <SidebarLink href={`#${ANCHOR_TAGS.PRO_MONITORING}`}>
        ProMonitoring patient app activation
      </SidebarLink>
      {(enableDentalNotation || enableCbctScanFlag) && (
        <SidebarTitle>CLINICAL</SidebarTitle>
      )}
      {enableDentalNotation && (
        <SidebarLink href={`#${ANCHOR_TAGS.DENTAL_NOTATION}`}>
          Notation system
        </SidebarLink>
      )}
      {enableCbctScanFlag && (
        <SidebarLink href={`#${ANCHOR_TAGS.CBCT_SCAN}`}>
          CBCT scan preferences
        </SidebarLink>
      )}
    </Sidebar>
  );
};

const LegacyPreferences = ({
  userId,
  userInfo,
  supportPvsScan,
  initialValues,
}: PreferencesProps) => (
  <>
    <Header>Preferences</Header>
    <TreatmentPreferences id={ANCHOR_TAGS.TREATMENT} />
    <ScanPreferences
      isLoading={!userInfo}
      supportPvsScan={supportPvsScan}
      id={ANCHOR_TAGS.SCAN}
    />
    <ScanIntervalDays isLoading={!userInfo} id={ANCHOR_TAGS.SCAN_INTERVAL} />
    <ProMonitoring isLoading={!userInfo} id={ANCHOR_TAGS.PRO_MONITORING} />
    <Notation isLoading={!userInfo} id={ANCHOR_TAGS.DENTAL_NOTATION} />
    <CBCTScanPreferences isLoading={!userInfo} id={ANCHOR_TAGS.CBCT_SCAN} />
    <AutoSaveForm userId={Number(userId)} initialValues={initialValues} />
  </>
);

const AccountPreferencesView = () => {
  const { data: meData } = useMeQuery();
  const [setPreferences, { data: setPreferencesData }] =
    api.useSetPreferencesMutation();
  const [getOrCreateTemplate, { data: userClinicalTemplate }] =
    api.useLazyGetTemplateQuery();

  const userInfo = meData?.me;

  const brand = getBrandFromDomain();
  const { PvsScanFlow: supportPvsScan } = getBrandSupportedFeatures(brand);
  const [initialValues, setInitialValues] = useState({});
  const clinicalPreferencesIsLoading =
    (brand == OLIV_BRAND_NAME && !userClinicalTemplate) || !userInfo;

  useEffect(() => {
    const accountPeferences = userInfo?.accountPreferences?.doctor;
    if (setPreferencesData) {
      setInitialValues({
        ...JSON.parse(setPreferencesData.updatedPreferences),
      });
    } else if (accountPeferences) {
      const clinicalPreferences = JSON.parse(
        userClinicalTemplate?.userAnswers?.root || '{}'
      );
      setInitialValues({
        ...accountPeferences,
        ...clinicalPreferences,
      });
    }
  }, [userInfo, userClinicalTemplate, setPreferencesData]);

  const fetchOrCreateTemplate = async (payload: GetTemplateQueryVariables) => {
    try {
      await getOrCreateTemplate(payload);
    } catch (error) {
      console.error('Failed to fetch or create template:', error);
    }
  };

  useEffect(() => {
    if (userInfo && !userClinicalTemplate) {
      const clinicalPreferencesTemplatePayload = getClinicalTemplatePayload(
        userInfo,
        brand
      );

      if (clinicalPreferencesTemplatePayload) {
        fetchOrCreateTemplate(clinicalPreferencesTemplatePayload);
      }
    }
  }, [userInfo, userClinicalTemplate]);

  useEffect(() => {
    if (userClinicalTemplate && userInfo) {
      const userClinicalPreferenceTemplateisLinkedInAccountPreferences =
        userInfo?.accountPreferences?.doctor
          ?.alignerClinicalPreferencesTemplateId;

      if (
        userClinicalTemplate &&
        !userClinicalPreferenceTemplateisLinkedInAccountPreferences
      ) {
        const preferences = JSON.stringify({
          ...userInfo.accountPreferences?.doctor,
          alignerClinicalPreferencesTemplateId: userClinicalTemplate.id,
        });

        setPreferences({
          input: {
            userId: Number(userInfo.id),
            preferences,
          },
        });
      }
    }
  }, [userClinicalTemplate, userInfo]);

  const preferenceTabs = [
    {
      id: 'clinical',
      name: 'Clinical',
      Component: () => (
        <>
          {clinicalPreferencesIsLoading ? (
            <Loading isCentered />
          ) : (
            <>
              <Notation
                isLoading={!userInfo}
                id={ANCHOR_TAGS.DENTAL_NOTATION}
              />

              {userClinicalTemplate && (
                <TemplateQuestions
                  template={userClinicalTemplate as GeneratedTemplateType}
                />
              )}
            </>
          )}
        </>
      ),
    },

    {
      id: 'caseTemplates',
      name: 'Case templates',
      Component: () => (
        <CaseTemplates isLoading={!userInfo} id={ANCHOR_TAGS.CASE_TEMPLATES} />
      ),
    },
    {
      id: 'diagnosticMaterials',
      name: 'Diagnostic Materials',
      Component: () => (
        <>
          <ScanPreferences
            isLoading={!userInfo}
            supportPvsScan={supportPvsScan}
            id={ANCHOR_TAGS.SCAN}
          />
          <TreatmentPreferences id={ANCHOR_TAGS.TREATMENT} />
          <CBCTScanPreferences
            isLoading={!userInfo}
            id={ANCHOR_TAGS.CBCT_SCAN}
          />
        </>
      ),
    },
    {
      id: 'monitoring',
      name: 'Monitoring',
      Component: () => (
        <>
          <ProMonitoring
            isLoading={!userInfo}
            id={ANCHOR_TAGS.PRO_MONITORING}
          />
          <ScanIntervalDays
            isLoading={!userInfo}
            id={ANCHOR_TAGS.SCAN_INTERVAL}
          />
        </>
      ),
    },
  ];

  const tabs = filterTabs(preferenceTabs, TAB_FILTERS.BRAND);

  return (
    <DocumentTitle title="Preferences">
      <Page>
        {brand == OLIV_BRAND_NAME ? (
          <PreferencesTabbedMenu
            preferenceTabs={tabs}
            initialValues={initialValues}
            userId={userInfo?.id}
            preferencesData={setPreferencesData}
          />
        ) : (
          <Container>
            <LegacyPreferencesSidebar />
            <Formik
              enableReinitialize
              initialValues={initialValues}
              // onSubmit isRequired by Formik but since we are auto-saving we don't need it
              onSubmit={() => {}}
            >
              <FormSections>
                <LegacyPreferences
                  userId={userInfo?.id}
                  userInfo={userInfo}
                  supportPvsScan={supportPvsScan}
                  initialValues={initialValues}
                />
              </FormSections>
            </Formik>
          </Container>
        )}
      </Page>
    </DocumentTitle>
  );
};

export default AccountPreferencesView;
