import React, { useContext, useState } from 'react';
import { Button, Loading, NotificationContext, Grid } from 'core/components';
import { PageSection } from 'styles/layout.css';
import { Container } from 'components/FileUpload/FileUpload.css';
import FileUpload from 'components/FileUpload';
import { Overline } from 'components/PatientHeader/PatientHeader.css';
import {
  FileNameContainer,
  FileTypesText,
  FormContainerBorderless,
  InfoBlocks,
  SubHeading,
  UploadContainerSmall,
  ButtonContainer,
} from 'pages/TpCentralEnhanced/UploadForm/UploadForm.css';
import {
  CaseSoftwareChoice,
  TreatmentPlanStaging,
  TreatmentPlanTooling,
} from 'generated/core/graphql';
import { formatFilesNames } from 'utils/materials';
import { CaseData, ContextProps } from 'pages/TpCentralEnhanced/types';
import readFile from 'utils/readFile';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import CheckedCircle from 'assets/checked-circle.svg?react';
import RedX from 'assets/delete.svg?react';
import { REFACTOR_ANY } from '@Types/refactor';
import useSaveManufacturingFiles from 'hooks/useSaveManufacturingFiles';
import useProcessManufacturingRequest from 'hooks/useProcessManufacturingRequest';
import { ManufacturingRequestStates } from 'constants/manufacturing';

const ManufacturingRequestStatus = ({
  status,
}: {
  status?: ManufacturingRequestStates;
}) => {
  const { t } = useTranslation();
  if (!status) {
    return null;
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item>
        {t(`tp_central_enhanced.manufacturing_request_status_${status}`)}
      </Grid>
      <Grid item>
        {status === ManufacturingRequestStates.Pending && <Loading />}
      </Grid>
    </Grid>
  );
};

const UploadForm = ({
  caseData,
  patientId,
}: {
  caseData: CaseData;
  patientId: number;
}) => {
  const { showNotification } = useContext(NotificationContext);
  const [zipFile, setZipFile] = useState<REFACTOR_ANY>(null);
  const { t } = useTranslation();
  const { caseRef } = caseData;
  const {
    processManufacturingRequest,
    processManufacturingRequestState,
    latestManufacturingRequestStatus,
  } = useProcessManufacturingRequest({ caseRef });

  const isManufacturingRequestPending =
    latestManufacturingRequestStatus === ManufacturingRequestStates.Pending;
  const [saveManufacturingFiles, saveManufacturingFilesState] =
    useSaveManufacturingFiles();

  // Reset state function
  const resetState = () => {
    setZipFile(null);
  };

  const isLoading =
    processManufacturingRequestState.isLoading ||
    saveManufacturingFilesState.isLoading;

  // Handle form submission
  const handleArchformSubmit = async () => {
    try {
      await processManufacturingRequest({ caseRef });
      showNotification('Successfully uploaded manufacturing files.', 'success');
    } catch (e) {
      if (typeof e === 'object' && e && 'message' in e) {
        showNotification(
          `Error processing manufacturing request. Error: ${e?.message}`,
          'error'
        );
      } else {
        showNotification(`An error occurred, please contact support`, 'error');
      }
      resetState();
    }
  };

  const handleVisionSubmit = async () => {
    if (!zipFile || isLoading) {
      return;
    }

    try {
      await saveManufacturingFiles({
        caseRef,
        file: zipFile[0].file,
        patientId,
      });
      showNotification('Successfully uploaded manufacturing files.', 'success');
    } catch (e) {
      showNotification('Error uploading manufacturing files.', 'error');
      resetState();
    }
  };

  // Handle file selection
  const handleSelectedFile = async (files: any) => {
    if (!files) {
      return;
    }

    const fileArray = formatFilesNames(Array.from(files));
    const selected = await Promise.all(
      fileArray.map(async (file) => {
        const fileSrc = await new Promise<string>((resolve) =>
          readFile(file, resolve)
        );

        return {
          file,
          fileSrc,
        };
      })
    );

    setZipFile(selected);
  };

  // Render main content
  const renderMainContent = () => {
    switch (caseData.caseSoftware ?? CaseSoftwareChoice.Vision) {
      case CaseSoftwareChoice.Vision:
        return (
          <>
            <UploadContainerSmall>
              <FileUpload
                isHorizontal
                fileType="zip"
                allowMultipleFiles={false}
                onSelectFile={handleSelectedFile}
                limitFileSize={false}
              />
            </UploadContainerSmall>
            <FileTypesText>
              <b>{t('tp_central_enhanced.accepted_file_types')}:</b> .zip
            </FileTypesText>
            <FileNameContainer>
              {zipFile?.name ? `${t('common.selected')}: ${zipFile.name}` : ''}
            </FileNameContainer>
            <ButtonContainer>
              {zipFile && (
                <Button
                  buttonType="secondary"
                  isLoading={isLoading}
                  isShort
                  onClick={handleVisionSubmit}
                >
                  {t('tp_central_enhanced.upload_and_push_to_production')}
                </Button>
              )}
            </ButtonContainer>
          </>
        );
      case CaseSoftwareChoice.Archform:
        return (
          <Button
            buttonType="secondary"
            isLoading={isLoading}
            isShort
            onClick={handleArchformSubmit}
            disabled={isManufacturingRequestPending}
          >
            {t('tp_central_enhanced.generate_and_push_to_production')}
          </Button>
        );
      default:
        throw new Error('Invalid case software');
    }
  };

  // Render component
  return isLoading ? (
    <Container alignContent="center">
      <Loading />
      <SubHeading>{t('common.this_could_take_a_few_minutes')}</SubHeading>
    </Container>
  ) : (
    <PageSection isNarrow>
      <Grid container alignItems="center" justifyContent="center" spacing={2}>
        {caseData?.caseSoftware === CaseSoftwareChoice.Archform && (
          <ManufacturingRequestStatus
            status={latestManufacturingRequestStatus}
          />
        )}
        <Grid item>{renderMainContent()}</Grid>
      </Grid>
    </PageSection>
  );
};

export default UploadForm;

export const ManufacturingFileVersions = ({
  selectedTreatmentPlanStaging,
  parts,
}: {
  selectedTreatmentPlanStaging: TreatmentPlanStaging;
  parts: ContextProps['parts'];
}) => {
  const { 'tp-central-ui-enhancements': tpCentralEnhancedFlag } = useFlags();
  const { t } = useTranslation();

  const partProducibleMap: { [key: string]: boolean } = {};
  parts?.forEach((part) => {
    if (part?.treatmentPlanTooling?.id) {
      partProducibleMap[part.treatmentPlanTooling.id] = part?.producible;
    }
  });

  const toolings = selectedTreatmentPlanStaging?.treatmentPlanToolings;
  let sortedToolings: TreatmentPlanTooling[] = [];
  if (toolings?.length) {
    sortedToolings = [...toolings].sort((a, b) => {
      return new Date(a?.createdAt) > new Date(b?.createdAt) ? -1 : 1;
    });
  }

  return (
    <>
      <FormContainerBorderless>
        {sortedToolings?.map((tooling, idx) => (
          <InfoBlocks>
            <li>
              <Overline data-testid="overline-heading">
                {t('common.version')}
              </Overline>
              <h3>{idx + 1}</h3>
            </li>
            <li>
              <Overline data-testid="overline-heading">
                {t('common.last_updated')}
              </Overline>
              <h3>
                {tooling?.createdAt
                  ? new Date(tooling.createdAt).toLocaleDateString('en-CA') // This locale is yyyy-mm-dd
                  : 'N/A'}
              </h3>
            </li>
            {tpCentralEnhancedFlag && (
              <li>
                <Overline data-testid="overline-heading">
                  {t('tp_central_enhanced.gcode_status')}
                </Overline>
                <h3>
                  {partProducibleMap[tooling.id] ? (
                    <>
                      Producible <CheckedCircle />
                    </>
                  ) : (
                    <>
                      Not producible <RedX />
                    </>
                  )}
                </h3>
              </li>
            )}
          </InfoBlocks>
        ))}
      </FormContainerBorderless>
    </>
  );
};
