import React, { createContext, FC, useContext, useEffect } from 'react';
import { fetchCustomerData } from 'api/customers';
import { ContextProps, CustomerData } from 'pages/TpCentralEnhanced/types';
import { useImmer } from 'use-immer';
import { useGQLQuery } from 'hooks/useGQL';
import {
  GetPartsDocument,
  GetPartsQuery,
  GetPartsQueryVariables,
  GetTreatmentPlanStagingsByCaseRefDocument,
  GetTreatmentPlanStagingsByCaseRefQuery,
  GetTreatmentPlanStagingsByCaseRefQueryVariables,
} from 'generated/core/graphql';
import { Sort, sortByIteration } from 'utils/prism';
import { formatCustomerInfo } from 'utils/customer';
import { NotificationContext } from 'core/components';
import { NamedError } from 'utils/types';

import {
  GetCaseDocument as GetCoreCaseDocument,
  GetCaseQuery as GetCoreCaseQuery,
  GetCaseQueryVariables as GetCoreCaseQueryVariables,
} from 'generated/core/graphql';

export const TpCentralEnhancedContext = createContext({} as ContextProps);

const TpCentralEnhancedProvider: FC = ({ children }) => {
  const [state, setState] = useImmer<any>({
    isFetchingData: false,
  });
  const { showNotification } = useContext(NotificationContext);

  //set state from getTreatmentPlanStagingsByCaseRef. Biggest difference is bypassOrtho, state, and isApproved comes from the
  //workflow/case state
  const getTreatmentPlanStagingInfoFromQueryViaWorkflow = (
    queryResult: any
  ) => {
    const fakeTp = { id: 1, iteration: 0 };

    const stagingMaterials =
      queryResult.getTreatmentPlanStagingsByCaseRef ?? [];
    const treatmentPlanStagingMaterials = stagingMaterials?.sort(
      sortByIteration(Sort.Desc)
    );
    const latestTreatmentPlanStaging = treatmentPlanStagingMaterials[0];

    const selectedTreatmentPlan = latestTreatmentPlanStaging
      ? latestTreatmentPlanStaging
      : fakeTp;

    const treatmentPlanStagingData = {
      treatmentPlanStagingMaterials,
      latestTreatmentPlanStaging,
      selectedTreatmentPlan,
    };
    return treatmentPlanStagingData;
  };

  const [getTreatmentPlanStagingsByCaseRef] = useGQLQuery<
    GetTreatmentPlanStagingsByCaseRefQuery,
    GetTreatmentPlanStagingsByCaseRefQueryVariables
  >(GetTreatmentPlanStagingsByCaseRefDocument, true);

  const [getCoreCaseQuery] = useGQLQuery<
    GetCoreCaseQuery,
    GetCoreCaseQueryVariables
  >(GetCoreCaseDocument, true, true);

  const [getParts, { data }] = useGQLQuery<
    GetPartsQuery,
    GetPartsQueryVariables
  >(GetPartsDocument, true);

  useEffect(() => {
    if (state?.customerInfo?.id) {
      getParts({ patientId: parseInt(state?.customerInfo?.id) });
    }
  }, [state?.customerInfo?.id]);
  // Retrieve case, customer, and treatment plan staging data
  // Biggest difference is fetching the case from core, and setting bypass ortho from the workflow
  const getTreatmentPlanStagingDataViaWorkflow = async (
    caseRef: string,
    customerId: string
  ) => {
    try {
      setState((draft) => {
        draft.isFetchingData = true;
      });

      const [cases, customerData, treatmentPlanStagingData]: [
        GetCoreCaseQuery | undefined,
        CustomerData,
        GetTreatmentPlanStagingsByCaseRefQuery | undefined,
      ] = await Promise.all([
        getCoreCaseQuery({ caseRef: caseRef }),
        fetchCustomerData(customerId),
        getTreatmentPlanStagingsByCaseRef({ caseRef }),
      ]);
      const fetchedCase = cases?.getCase;
      if (!fetchedCase) {
        throw new NamedError(
          'CasesNotFoundError',
          'Unable to get cases for customer'
        );
      }

      const stagingData = getTreatmentPlanStagingInfoFromQueryViaWorkflow(
        treatmentPlanStagingData!
      );

      const tpSection = fetchedCase.workflow?.productionRequirements.find(
        (req) => req.name === 'treatment_plan_staging'
      );

      const requiresOrthoReview = tpSection?.evaluationRequirements.some(
        (ev) => ev?.evaluationType === 'orthodontic_evaluation'
      );

      const caseData = {
        caseRef: fetchedCase.caseRef,
        caseSoftware: fetchedCase.caseSoftware,
        customerRef: fetchedCase.patientId,
        caseType: fetchedCase.caseType,
        krakenCaseIdentifier: fetchedCase.manufacturerExternalRef,
        requireOrthoReview: requiresOrthoReview,
        caseState: fetchedCase.caseState,
      };
      setState((draft) => {
        draft.customerInfo = formatCustomerInfo(customerData);
        draft.treatmentPlanStagingData = stagingData;
        draft.caseData = caseData;
        draft.isFetchingData = false;
      });
    } catch (err) {
      if (!(err instanceof Error)) {
        throw err;
      }

      setState((draft) => {
        draft.isFetchingData = false;
      });

      showNotification(err.message, 'error');
    }
  };

  return (
    <TpCentralEnhancedContext.Provider
      value={{
        caseData: state.caseData,
        getTreatmentPlanStagingData: getTreatmentPlanStagingDataViaWorkflow,
        isFetchingData: state.isFetchingData,
        customerInfo: state.customerInfo,
        treatmentPlanStagingData: state.treatmentPlanStagingData,
        setProviderState: setState,
        parts: data?.getParts,
      }}
    >
      {children}
    </TpCentralEnhancedContext.Provider>
  );
};

export default TpCentralEnhancedProvider;
