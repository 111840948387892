import React from 'react';
import { Modal, Button, Divider } from 'core/components';
import {
  ModalTitle,
  ModalBody,
  ModalActions,
} from 'pages/OrdersAdmin/Modals.css';
import { OrderType } from 'generated/core/graphql';

type CancelOrderModalProps = {
  cancellingOrder: OrderType | null;
  setCancellingOrder: (order: OrderType | null) => void;
  pendingOrderCancellation: boolean;
  confirmCancelOrder: () => void;
};

const CancelOrderModal = ({
  cancellingOrder,
  setCancellingOrder,
  pendingOrderCancellation,
  confirmCancelOrder,
}: CancelOrderModalProps) => {
  return (
    <Modal
      isOpen={!!cancellingOrder}
      onClose={() => setCancellingOrder(null)}
      closeOnOverlayClick={!pendingOrderCancellation}
      hideCloseButton
    >
      <ModalTitle>
        <h3>Cancel order</h3>
      </ModalTitle>
      <Divider />
      <ModalBody>
        Are you sure you want to cancel order{' '}
        <b>{cancellingOrder?.shortOrderRef}</b>?
      </ModalBody>
      <ModalActions>
        <Button
          onClick={() => setCancellingOrder(null)}
          type="button"
          buttonType="tertiary"
          disabled={pendingOrderCancellation}
        >
          Nevermind
        </Button>
        <Button
          isLoading={pendingOrderCancellation}
          disabled={pendingOrderCancellation}
          onClick={async () => {
            confirmCancelOrder();
          }}
          type="button"
        >
          Cancel Order
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default CancelOrderModal;
