import { OrderShippingModalProps } from 'components/Modals/OrderShippingModal/OrderShippingModal';
import { OrderRetainersModalProps } from 'components/Modals/OrderRetainersModal/OrderRetainersModal';
import { PROVIDER_FACING_STATUSES } from 'constants/caseStatus';
import { Maybe, PersonType } from 'generated/legacy/graphql';
import { ReactNode } from 'react';
import { QualityTicketModalProps } from 'components/QualityTicketModal/QualityTicketModal';
import { GetCasesQuery } from 'generated/core/graphql';
import { SupportedBrand } from 'utils/brands';

export enum AlertTypeEnum {
  Critical = 'critical',
  Warning = 'warning',
}

export type PopoverActionProps = {
  providerFacingStatus: PROVIDER_FACING_STATUSES;
  selectedCase: any;
  isLoading: boolean;
};

export type InfoCardProps = {
  providerFacingStatus: PROVIDER_FACING_STATUSES;
  subsequentCase: any;
  selectedCase: any;
  isLatestCase: boolean;
  reopenCase: (caseRef: string) => void;
};

export interface InfoCardMapping {
  [key: string]: {
    Card: any;
    getProps: (arg1: any) => object;
  };
}

export interface ActionOnClickConfig {
  [key: string]: () => void;
}

export type AlertCardGetPropsType = {
  subsequentCase: any;
  selectedCase: any;
};

type ArchiveCaseModalProps = {
  isOpen: boolean;
  inPlanning: boolean;
  onClose: () => void;
  onConfirm: (archiveReason: string) => void;
};
type RefinementModalProps = {
  isOpen: boolean;
  onCancelClicked: () => void;
  onSubmitted: () => void;
  treatmentPlanSteps: any;
  caseId: string;
  activeCaseRef: string | undefined;
  activeCaseType: string | undefined;
  patientId: string;
  stateOfTreatment: string;
  candidMonitoringLink: string;
  patientName: string;
  brand: SupportedBrand;
  formLink: string;
  jumpToQualityTicketModal?: () => void;
  cases: GetCasesQuery['getCases'];
};

type CompleteCaseModalProps = {
  confirmButtonText: string;
  isLoading: boolean;
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  onClose: () => void;
  isConfirmDisabled: boolean;
  headerText: string;
  children: ReactNode;
};

export type ActionModalsProps = {
  archiveCaseModalProps: ArchiveCaseModalProps;
  refinementModalProps: RefinementModalProps;
  orderShippingModalProps: OrderShippingModalProps;
  isCompleteCaseModalProps: CompleteCaseModalProps;
  orderRetainersModalProps: OrderRetainersModalProps;
  qualityTicketModalProps: QualityTicketModalProps;
};

export type GuardianInfoType =
  | Maybe<
      {
        __typename?: 'PersonType' | undefined;
      } & Pick<
        PersonType,
        | 'firstName'
        | 'middleName'
        | 'lastName'
        | 'preferredName'
        | 'sex'
        | 'birthday'
        | 'phone'
      >
    >
  | undefined;

export type ProfileInfoType = {
  id: string;
  preferredName: string;
  fullName: string;
  phone: string;
  dateOfBirth: string;
  email: string;
  displayAddress: string;
  treatingProviderName: string;
  treatingProviderId: string;
  legalGuardian: GuardianInfoType;
  practiceName: string;
  practiceId: string;
};
