import styled from 'styled-components';

export const TableContainer = styled.div`
  width: 100%;
  overflow: visible;
  height: calc(100% - 4.25rem);
  * .actions-cell {
    overflow: visible !important;
    width: 50%;
    text-align: right;
    div[class^='Dropdown__Wrapper'] {
      width: 100%;
      content-align: right;
    }
    div[class^='layoutcss__Spacer'] {
      justify-content: right;
    }
  }
  * .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
  }
  .ReactVirtualized__Table__row {
    overflow: visible !important;
  }
  * .just-modified {
    animation: highlight-modified 5s ease-out;
  }

  @keyframes highlight-modified {
    0% {
      background-color: ${({ theme }) => theme.colors.blue10};
    }
    95% {
      background-color: ${({ theme }) => theme.colors.blue10};
    }
    100% {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const ErrorMessage = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Action = styled.p<{ $warning?: boolean; $disabled?: boolean }>(
  ({ theme, $warning, $disabled }) => ({
    color: $disabled
      ? theme.colors.black50
      : $warning
        ? theme.colors.red50
        : theme.colors.blue50,
    fontSize: theme.fontSizes[3],
    cursor: 'pointer',
    textAlign: 'right',
    height: '100%',
    margin: 0,
    width: '100%',
  })
);
