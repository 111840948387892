import React from 'react';
import { ActionSection } from 'pages/TpCentralEnhanced/Actions/components';
import { openVisionSoftware } from 'pages/Case/TreatmentPlan/Vision';
import { useTranslation } from 'react-i18next';
import {
  CaseSoftwareChoice,
  TreatmentPlanningSoftware,
  TreatmentPlanStaging,
} from 'generated/core/graphql';
import { Nullable } from 'utils/types';
import api, { ExtractVariablesType } from 'state/api';

export const OpenTPSoftware = ({
  caseRef,
  customerRef,
  isLoading = true,
  selectedTP,
  software,
}: {
  caseRef: string;
  customerRef: string;
  isLoading: boolean;
  selectedTP: TreatmentPlanStaging;
  software: Nullable<CaseSoftwareChoice>;
}) => {
  const [getPayload] =
    api.useLazyGetTreatmentPlanningSoftwareOpeningPayloadQuery();
  type params = ExtractVariablesType<
    typeof api.useLazyGetTreatmentPlanningSoftwareOpeningPayloadQuery
  >;
  const { t } = useTranslation();
  const l10nResource = `tp_central_enhanced.open_treatment_plan_in_${(software ?? CaseSoftwareChoice.Vision).toLowerCase()}`;
  return (
    <ActionSection
      title={t(l10nResource)}
      buttonTitle={t('common.open')}
      buttonAction={async () => {
        if (software === CaseSoftwareChoice.Archform) {
          const params: params = {
            caseRef,
            software: TreatmentPlanningSoftware.Archform,
          };
          if (selectedTP?.iteration) {
            params.treatmentPlanStagingId = selectedTP?.id;
          }
          const {
            archform: { payload },
          } = await getPayload(params).unwrap();

          window.open(payload);
        } else {
          openVisionSoftware(customerRef, selectedTP?.iteration || 0);
        }
      }}
      isDisabled={isLoading}
      disabledText={t('tp_central_enhanced.this_is_disabled')}
    />
  );
};
