import React, { useMemo } from 'react';
import moment from 'moment';
import { Template } from 'generated/core/graphql';

import {
  TemplateItemContainer,
  TemplateName,
  TemplateDescription,
  EditButton,
  MetaItem,
  TemplateMeta,
  TemplateHeader,
  PencilIconStyled,
} from 'pages/AccountPreferencesPage/CaseTemplates.css';
import { getTotalAndAnsweredQuestionsCount } from 'utils/treatmentObjective';
import { DATE_TIME_FORMAT } from 'constants/treatmentObjective';

type CaseTemplateProps = {
  template: Template;
  setCurrentTemplate: (template: Template) => void;
  setIsEditModalOpen: (isOpen: boolean) => void;
};

export const CaseTemplate = ({
  template,
  setCurrentTemplate,
  setIsEditModalOpen,
}: CaseTemplateProps) => {
  const templateCompleted = useMemo(() => {
    const { total, current } = getTotalAndAnsweredQuestionsCount({
      values: JSON.parse(template.userAnswers?.root || '{}'),
      systemTemplate: template,
    });
    return total === current;
  }, [template]);

  return (
    <TemplateItemContainer>
      <TemplateHeader>
        <TemplateName>{template.name}</TemplateName>
        <EditButton
          complete={templateCompleted}
          onClick={() => {
            setCurrentTemplate(template);
            setIsEditModalOpen(true);
          }}
        >
          {templateCompleted ? 'Edit' : 'Complete to use'}
          <PencilIconStyled complete={templateCompleted} />
        </EditButton>
      </TemplateHeader>
      <TemplateDescription>{template.description}</TemplateDescription>
      <TemplateMeta>
        <MetaItem>
          Created on{' '}
          {moment
            .parseZone(template.createdAt)
            .local()
            .format(DATE_TIME_FORMAT)}
        </MetaItem>
        <MetaItem>•</MetaItem>
        <MetaItem>
          Updated on{' '}
          {moment
            .parseZone(template.updatedAt)
            .local()
            .format(DATE_TIME_FORMAT)}
        </MetaItem>
      </TemplateMeta>
    </TemplateItemContainer>
  );
};
