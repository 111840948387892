import styled from 'styled-components';

import { colors, type } from 'core/components';
import { Form } from 'formik';
import { Link } from 'react-router-dom';

export const LoginForm = styled(Form)`
  display: flex;
  flex-direction: column;

  padding: 2rem 0;
  background: ${colors.white};
  margin: auto;
  width: 320px;
  gap: 32px;
`;

export const SuccessForm = styled(Form)`
  display: flex;
  flex-direction: column;

  padding: 2rem;
  background: ${colors.white};
  margin: auto;
  width: 520px;
  gap: 10px;
`;

export const LoginContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const Logo = styled.img<{ $oliv?: boolean }>`
  flex: none;
  align-self: center;
  max-height: ${({ $oliv }) => ($oliv ? '60px;' : '20px;')};
`;

export const Subheader = styled(type.H4)`
  text-align: center;
`;

export const SplitContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  align-items: stretch;
`;

export const InputFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledLink = styled(Link)`
  text-align: center;
`;

export const Box = styled.div`
  text-align: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  margin: 5px auto;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
  font-size: 14px;
  line-height: 1.5;
`;

export const SuccessText = styled.h3`
  text-align: center;
`;
