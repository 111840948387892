import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

// A custom hook that allow us to get access to the URL search parameters
// and momoize the result to avoid unnecessary re-renders.

const useURLSearchParams = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export default useURLSearchParams;
