import { useEffect, useState } from 'react';
import api from 'state/api';
import { ManufacturingRequestStates } from 'constants/manufacturing';

export default ({ caseRef }: { caseRef: string }) => {
  const [pollingInterval, setPollingInterval] = useState(2000);
  const [fn, mutationResult] = api.useProcessManufacturingRequestMutation();

  const [
    getLatestManufacturingRequestStatus,
    latestManufacturingRequestStatusResult,
  ] = api.useLazyGetLatestManufacturingRequestStatusQuery({ pollingInterval });

  useEffect(() => {
    getLatestManufacturingRequestStatus({ caseRef });
  }, [getLatestManufacturingRequestStatus]);

  useEffect(() => {
    if (mutationResult.isSuccess) {
      setPollingInterval(5000);
    }
  }, [mutationResult.data]);

  useEffect(() => {
    if (
      latestManufacturingRequestStatusResult.status === 'fulfilled' &&
      latestManufacturingRequestStatusResult.data !==
        ManufacturingRequestStates.Pending
    ) {
      // Setting polling interval to 0 will stop polling
      setPollingInterval(0);
    }
  }, [
    latestManufacturingRequestStatusResult.data,
    latestManufacturingRequestStatusResult.status,
  ]);

  const executor = async ({ caseRef }: { caseRef: string }) => {
    await fn({ caseRef, isTest: false }).unwrap();
  };

  return {
    processManufacturingRequest: executor,
    processManufacturingRequestState: mutationResult,
    latestManufacturingRequestStatus:
      latestManufacturingRequestStatusResult.data as ManufacturingRequestStates,
  } as const;
};
