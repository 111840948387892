import React, { useState, useEffect } from 'react';
import { Grid } from 'core/components';
import { Loading } from 'core/components';
import api from 'state/api';
import { ManufacturingRequestStates } from 'constants/manufacturing';
import RedX from 'assets/delete.svg?react';

export const TreatmentPlanLoader = () => (
  <Grid
    container
    alignItems="center"
    justifyContent="center"
    direction="column"
    style={{ height: '100%' }}
  >
    <Grid item>Treatment plan viewer is being generated...</Grid>
    <Grid item>
      <Loading />
    </Grid>
  </Grid>
);

export const TreatmentPlanLoaderFailed = () => (
  <Grid
    container
    alignItems="center"
    justifyContent="center"
    direction="column"
    style={{ height: '100%' }}
  >
    <Grid item>Treatment plan viewer has failed to generate</Grid>
    <Grid item>
      <RedX />
    </Grid>
  </Grid>
);

const TreatmentPlanViewer = ({
  IFrameComponent,
  tpStagingId,
}: {
  IFrameComponent: React.ComponentType<
    React.IframeHTMLAttributes<HTMLIFrameElement>
  >;
  tpStagingId: string;
}) => {
  const [tpViewerUrl, setTpViewerUrl] = useState<string | null>(null);
  const [pollingInterval, setPollingInterval] = useState(0);
  const [getLatestViewerStatus, latestViewerRequestStatus] =
    api.useLazyGetLatestViewerUpdateRequestStatusQuery({ pollingInterval });
  const [getTpStaging] = api.useLazyGetTreatmentPlanStagingByIdQuery();

  useEffect(() => {
    getTpStaging({ id: tpStagingId }).then((response) => {
      if (response?.data?.url) {
        setTpViewerUrl(response.data.url);
      } else {
        setPollingInterval(5000);
        getLatestViewerStatus({
          treatmentPlanStagingId: tpStagingId,
        });
      }
    });
  }, [tpStagingId]);
  useEffect(() => {
    if (
      latestViewerRequestStatus.status === 'fulfilled' &&
      latestViewerRequestStatus.data !== ManufacturingRequestStates.Pending
    ) {
      setPollingInterval(0);

      if (latestViewerRequestStatus.data === ManufacturingRequestStates.Done) {
        getTpStaging({ id: tpStagingId }).then((response) => {
          if (response?.data?.url) {
            setTpViewerUrl(response.data.url);
          }
        });
      }
    }
  }, [latestViewerRequestStatus.data, latestViewerRequestStatus.status]);

  return tpViewerUrl ? (
    <IFrameComponent scrolling="no" src={tpViewerUrl} />
  ) : latestViewerRequestStatus.data === ManufacturingRequestStates.Failed ? (
    <TreatmentPlanLoaderFailed />
  ) : (
    <TreatmentPlanLoader />
  );
};

export default TreatmentPlanViewer;
